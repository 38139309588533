@import '~org/styles/variables';
@import '../InstoreModal';

$instoreAddNewCardModalSectionTitleAlignItems: center !default;
$instoreAddNewCardModalSectionTitleMargin: 0 0 1rem 0 !default;

$instoreAddNewCardModalSectionTitleH2FontSize: 2.4rem !default;
$instoreAddNewCardModalSectionTitleH2LineHeight: 2.8rem !default;

$instoreAddNewCardModalInfoFontSize: 1.4rem !default;
$instoreAddNewCardModalInfoLineHeight: 2rem !default;
$instoreAddNewCardModalInfoTextAlign: center !default;
$instoreAddNewCardModalInfoMargin: 0 0 1rem 0 !default;

$instoreAddNewCardBasicButtonFontSize: 1.4rem !default;
$instoreAddNewCardBasicButtonLineHeight: 2rem !default;

$instoreAddNewCardModalCardIframeHeight: 40rem !default;

$instoreAddNewCardModalDisclaimerFontSize: 1.2rem !default;
$instoreAddNewCardModalDisclaimerLineHeight: 1.6rem !default;
$instoreAddNewCardModalDisclaimerFontWeight: 100 !default;
$instoreAddNewCardModalDisclaimerColor: #626b79 !default;

$instoreAddNewCardModalMinHeight: 56rem !default;

.mn_instoreModal.mn_addNewCardModal {
  .mn_sectionTitle {
    align-items: $instoreAddNewCardModalSectionTitleAlignItems;
    margin: $instoreAddNewCardModalSectionTitleMargin;

    h2 {
      font-size: $instoreAddNewCardModalSectionTitleH2FontSize;
      line-height: $instoreAddNewCardModalSectionTitleH2LineHeight;
    }
  }

  .mn_info {
    font-size: $instoreAddNewCardModalInfoFontSize;
    line-height: $instoreAddNewCardModalInfoLineHeight;
    text-align: $instoreAddNewCardModalInfoTextAlign;
    margin: $instoreAddNewCardModalInfoMargin;
  }

  .mn_basicButton {
    font-size: $instoreAddNewCardBasicButtonFontSize;
    line-height: $instoreAddNewCardBasicButtonLineHeight;
  }

  .mn_instoreEnrollCardIframe {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    height: $instoreAddNewCardModalCardIframeHeight;

    iframe {
      min-width: 100%;
      height: 100%;
      border: none;
    }
  }

  .mn_disclaimer {
    text-align: center;
    font-size: $instoreAddNewCardModalDisclaimerFontSize;
    line-height: $instoreAddNewCardModalDisclaimerLineHeight;
    font-weight: $instoreAddNewCardModalDisclaimerFontWeight;
    color: $instoreAddNewCardModalDisclaimerColor;
  }

  @media #{$medium-screen} {
    .mn_modal {
      min-height: $instoreAddNewCardModalMinHeight;
    }
  }
}
