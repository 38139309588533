@import '~org/styles/variables';

$instoreCardsListGap: 1rem !default;
$instoreCardsListPadding: 0 !default;
$instoreCardsListMargin: 0 !default;

$instoreCardMargin: 0 !default;
$instoreCardFontSize: 1.6rem !default;
$instoreCardLineHeight: 2.2rem !default;

$instoreCardNameMargin: 0 1rem 0 0 !default;

$instoreCardBeforeBorderRadius: 50% !default;
$instoreCardBeforeWidth: 2rem !default;
$instoreCardBeforeHeight: 2rem !default;
$instoreCardBeforeMargin: 0 1.2rem 0 0 !default;
$instoreCardBeforeTop: -2px !default;

$instoreCardLinkedBeforeContent: '\e801' !default;
$instoreCardLinkedBeforeFontFamily: 'fontello' !default;
$instoreCardLinkedBeforeColor: $brandWhite !default;
$instoreCardLinkedBeforeFontSize: .9rem !default;
$instoreCardLinkedBeforeBackground: #00cff0 !default;

$instoreCardNotLinkedBeforeContent: '!' !default;
$instoreCardNotLinkedBeforeBackground: #b82b47 !default;
$instoreCardNotLinkedBeforeColor: $brandWhite !default;
$instoreCardNotLinkedBeforeFontSize: 1.3rem !default;
$instoreCardNotLinkedBeforeLineHeight: 1 !default;
$instoreCardNotLinkedBeforeFontFamily: Arial, Helvetica, sans-serif !default;

.mn_instoreCards {
  .mn_cardsList {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: $instoreCardsListGap;
    padding: $instoreCardsListPadding;
    margin: $instoreCardsListMargin;
  }

  .mn_instoreCard {
    display: flex;
    align-items: center;
    margin: $instoreCardMargin;
    font-size: $instoreCardFontSize;
    line-height: $instoreCardLineHeight;

    .mn_instoreCardName {
      margin: $instoreCardNameMargin;
    }

    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: $instoreCardBeforeBorderRadius;
      width: $instoreCardBeforeWidth;
      height: $instoreCardBeforeHeight;
      margin: $instoreCardBeforeMargin;
      top: $instoreCardBeforeTop;
    }

    &.mn_linkedCard {
      &:before {
        content: $instoreCardLinkedBeforeContent;
        font-family: $instoreCardLinkedBeforeFontFamily;
        color: $instoreCardLinkedBeforeColor;
        font-size: $instoreCardLinkedBeforeFontSize;
        background: $instoreCardLinkedBeforeBackground;
      }
    }

    &.mn_notLinkedCard {
      &:before {
        content: $instoreCardNotLinkedBeforeContent;
        background: $instoreCardNotLinkedBeforeBackground;
        color: $instoreCardNotLinkedBeforeColor;
        font-size: $instoreCardNotLinkedBeforeFontSize;
        line-height: $instoreCardNotLinkedBeforeLineHeight;
        font-family: $instoreCardNotLinkedBeforeFontFamily;
      }
    }
  }
}
