@import '~org/styles/variables';

.mn_pagination {
  margin: 2rem 0;
  color: #000;

  .mn_paginationPageSizeListLabel {
    font-size: 1.6rem;
  }

  .mn_paginationPageSizeList {
    min-width: 100%;
    min-height: 4rem;
    border-radius: 4px;
    border: 1px solid #666;
    display: block;
    margin-top: .5rem;
    -webkit-appearance: none;
    background: #fff url('../../assets/images/chevron_up.svg') no-repeat 95% 55%;
    background-size: 1.4rem;
    font-size: 1.6rem;
    padding: 8px 25px 8px 4px;
    line-height: 2rem;
    color: #000;
  }

  .mn_paginationDots {
    cursor: default;
    text-align: center;
    margin-bottom: 2rem;

    .mn_pageDot {
      background: #ccc;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0;
      margin: 0 .5rem;
      border: 0;

      &.mn_activePageDot {
        background: #6244bb;
      }
    }

    .mn_prev,
    .mn_next {
      font-size: 0;
      background: none;
      border: none;
      padding: 0 1rem;

      &:after {
        font-family: 'UnitedIcons', serif;
        font-size: 2rem;
        position: relative;
        top: .4rem;
      }
    }

    .mn_prev:after {
      content: '\e900';
    }

    .mn_next:after {
      content: '\e90a';
    }
  }

  .slick-track {
    display: flex;
    justify-content: center;
  }

  .slick-slide {
    width: 3rem !important;
  }

  @media #{$medium-screen} {
    display: flex;
    justify-content: flex-end;

    .mn_paginationPageSizeListWrapper {
      width: 16rem;
    }
  }
}
