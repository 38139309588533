@import '~org/styles/variables';
@import '~org/styles/mixins.scss';

$emailSubscribeModalPositionTop: () !default;
$emailSubscribeModalMinHeight: () !default;
$emailSubscribeModalWidth: () !default;
$emailSubscribeModalMaxWidth: () !default;
$emailSubscribeModalPadding: () !default;
$emailSubscribeModalBackground: () !default;
$emailSubscribeModalBackgroundSize: () !default;
$emailSubscribeModalBoxShadow: (small: none) !default;
$emailSubscribeModalOutline: () !default;
$emailSubscribeModalBorderRadius: (small: 0, medium: ()) !default;
$emailSubscribeModalBorderBottom: () !default;
$emailSubscribePanelBackground: $brandSecondaryColor !default;
$emailSubscribePanelPadding: 5rem 4rem 9rem !default;
$emailSubscribePanelTextAlign: () !default;
$emailSubscribeCloseWidth: (small: 5rem, medium: 5rem) !default;
$emailSubscribeCloseHeight: (small: 5rem, medium: 5rem) !default;
$emailSubscribeCloseColor: () !default;
$emailSubscribeCloseFontWeight: () !default;
$emailSubscribeCloseFontSize: () !default;
$emailSubscribeCloseOpacity: () !default;
$emailSubscribeCloseBackground: () !default;
$emailSubscribeCloseBorderRadius: () !default;
$emailSubscribeCloseAfterColor: () !default;
$emailSubscribeCloseAfterMargin: () !default;
$emailSubscribeCloseAfterLineHeight: () !default;
$emailSubscribeCloseAfterLetterSpacing: () !default;
$emailSubscribeContentFont: (small: normal 2.2rem/1.4 $brandFontFamily, medium: ()) !default;
$emailSubscribeContentColor: () !default;
$emailSubscribeContentDisplay: () !default;
$emailSubscribeContentFlexDirection: () !default;
$emailSubscribeContentAlignItems: () !default;
$emailSubscribeContentPadding: () !default;
$emailSubscribeHeadingFont: (small: bold 3.5rem/1.2 $brandH1FontFamily, medium: bold 3.5rem/1.2 $brandH1FontFamily) !default;
$emailSubscribeHeadingColor: (small: $brandQuaternaryColor, medium: ()) !default;
$emailSubscribeHeadingDisplay: () !default;
$emailSubscribeHeadingMargin: (small: 0 0 1rem, medium: 0 0 1rem) !default;
$emailSubscribeHeadingTextAlign: () !default;
$emailSubscribeHeadingLetterSpacing: () !default;
$emailSubscribeTextWidth: () !default;
$emailSubscribeTextMargin: () !default;
$emailSubscribeTextFontSize: () !default;
$emailSubscribeTextFontWeight: () !default;
$emailSubscribeTextLineHeight: () !default;

$emailSubscribeOptinMargin: () !default;
$emailSubscribeOptInImageBackground: () !default;
$emailSubscribeOptInImageHeight: () !default;
$emailSubscribeOptInImageWidth: () !default;
$emailSubscribeOptInImageMargin: () !default;
$emailSubscribeOptInH2Margin: (medium: 8rem 0 0 0) !default;

$emailSubscribeControlsDisplay: () !default;
$emailSubscribeControlsFlexDirection: () !default;
$emailSubscribeControlsMargin: (small: 3rem 0 2rem, medium: ()) !default;

$emailSubscribeCancelLinkFont: (small: normal 1.5rem/1.4 $brandFontFamily, medium: ()) !default;
$emailSubscribeCancelLinkIconFontSize: () !default;
$emailSubscribeCancelLinkColor: () !default;
$emailSubscribeCancelLinkDisplay: (small: initial, medium: none) !default;

$emailSubscribeSubmitMargin: () !default;
$emailSubscribeSubmitWidth: () !default;
$emailSubscribeSubmitHeight: () !default;
$emailSubscribeSubmitFontSize: (small: 2rem, medium: ()) !default;
$emailSubscribeSubmitPadding: (small: 1.8rem 2rem, medium: ()) !default;
$emailSubscribeSubmitLineHeight: () !default;
$emailSubscribeSubmitBorder: () !default;
$emailSubscribeSubmitFontWeight: () !default;
$emailSubscribeSubmitColor: () !default;
$emailSubscribeSubmitBackground: () !default;

$emailSubscribeButtonLabelColor: () !default;
$emailSubscribeButtonLabelFontSize: () !default;
$emailSubscribeButtonLabelPaddingRight: 1.8rem !default;
$emailSubscribeCancelLinkLabelIconDisplay: inline-block !default;
$emailSubscribeCancelLinkLabelAfterDisplay: none !default;
$emailSubscribeCancelLinkLabelAfterContent: () !default;
$emailSubscribeCancelLinkLabelAfterFontFamily: () !default;
$emailSubscribeCancelLinkLabeDotDisplay: inline !default;
$emailSubscribeCancelLinkBorder: none !default;
$emailSubscribeCancelLinkBackground: none !default;
$emailSubscribeCancelLinkPadding: 0 !default;
$emailSubscribeCancelLinkBorderRadius: () !default;
$emailSubscribeCancelLinkLabelHoverTextDecoration: underline !default;
$emailSubscribeCancelLinkTextTransform: () !default;
$emailSubscribeCancelMargin: () !default;

$emailSubscribeCheckIconBackground: () !default;
$emailSubscribeCheckIconDisplay: () !default;
$emailSubscribeCheckIconBackgroundSize: () !default;
$emailSubscribeCheckIconWidth: () !default;
$emailSubscribeCheckIconHeight: () !default;
$emailSubscribeCheckIconMarginRight: () !default;

$emailSubscribeSeparateHeadingFontSize: () !default;
$emailSubscribeSeparateHeadingLineHeight: () !default;
$emailSubscribeSeparateHeadingColor: () !default;
$emailSubscribeSeparateHeadingFontWeight: () !default;
$emailSubscribeSeparateHeadingMargin: () !default;

$emailSubscribeComingSoonBackground: () !default;
$emailSubscribeComingSoonColor: () !default;
$emailSubscribeComingSoonTextTransform: () !default;
$emailSubscribeComingSoonMargin: () !default;
$emailSubscribeComingSoonPadding: () !default;
$emailSubscribeComingSoonFontSize: () !default;
$emailSubscribeComingSoonLineHeight: () !default;
$emailSubscribeComingSoonFontWeight: () !default;
$emailSubscribeComingSoonMaxWidth: () !default;

.mn_emailOptInSubscribeModal {
  .mn_modal {
    top: $emailSubscribeModalPositionTop;
    min-height: $emailSubscribeModalMinHeight;
    width: map_get($emailSubscribeModalWidth, medium);
    max-width: map-get($emailSubscribeModalMaxWidth, medium);
    padding: $emailSubscribeModalPadding;
    background: $emailSubscribeModalBackground;
    text-align: $emailSubscribePanelTextAlign;
    background-size: $emailSubscribeModalBackgroundSize;
    box-shadow: map-get($emailSubscribeModalBoxShadow, medium);
    outline: $emailSubscribeModalOutline;
    border-radius: map-get($emailSubscribeModalBorderRadius, medium);
    border-bottom: $emailSubscribeModalBorderBottom;
  }

  .mn_closeModal {
    color: map-get($emailSubscribeCloseColor, medium);
    width: map-get($emailSubscribeCloseWidth, medium);
    height: map-get($emailSubscribeCloseHeight, medium);
    font-weight: map-get($emailSubscribeCloseFontWeight, medium);
    background: $emailSubscribeCloseBackground;
    border-radius: $emailSubscribeCloseBorderRadius;
    font-size: $emailSubscribeCloseFontSize;

    &:after {
      color: $emailSubscribeCloseAfterColor;
      margin: $emailSubscribeCloseAfterMargin;
      line-height: $emailSubscribeCloseAfterLineHeight;
      letter-spacing: $emailSubscribeCloseAfterLetterSpacing;
    }
  }

  .mn_sectionTitle {
    margin: 0;

    h2 {
      display: map_get($emailSubscribeHeadingDisplay, medium);
      font: map_get($emailSubscribeHeadingFont, medium);
      color: map_get($emailSubscribeHeadingColor, medium);
      margin: map_get($emailSubscribeHeadingMargin, medium);
      text-align: $emailSubscribeHeadingTextAlign;
      letter-spacing: $emailSubscribeHeadingLetterSpacing;
    }
  }

  .mn_optInImageBeforeHeader {
    background: $emailSubscribeOptInImageBackground;
    height: $emailSubscribeOptInImageHeight;
    width: $emailSubscribeOptInImageWidth;
    margin: map-get($emailSubscribeOptInImageMargin, medium);
  }

  .mn_modalContent {
    color: map_get($emailSubscribeContentColor, medium);
    font: map_get($emailSubscribeContentFont, medium);
    display: $emailSubscribeContentDisplay;
    flex-direction: $emailSubscribeContentFlexDirection;
    align-items: $emailSubscribeContentAlignItems;
    padding: $emailSubscribeContentPadding;

    h2 {
      font-size: map_get($emailSubscribeSeparateHeadingFontSize, medium);
      line-height: map_get($emailSubscribeSeparateHeadingLineHeight, medium);
      color: $emailSubscribeSeparateHeadingColor;
      font-weight: $emailSubscribeSeparateHeadingFontWeight;
      margin: $emailSubscribeSeparateHeadingMargin;

      .mn_checkIcon {
        display: $emailSubscribeCheckIconDisplay;
        background: $emailSubscribeCheckIconBackground;
        background-size: map_get($emailSubscribeCheckIconBackgroundSize, medium);
        width: map_get($emailSubscribeCheckIconWidth, medium);
        height: map_get($emailSubscribeCheckIconHeight, medium);
        margin-right: map_get($emailSubscribeCheckIconMarginRight, medium);
      }
    }

    .mn_comingSoon {
      background: $emailSubscribeComingSoonBackground;
      color: $emailSubscribeComingSoonColor;
      text-transform: $emailSubscribeComingSoonTextTransform;
      margin: $emailSubscribeComingSoonMargin;
      padding: $emailSubscribeComingSoonPadding;
      font-size: $emailSubscribeComingSoonFontSize;
      line-height: $emailSubscribeComingSoonLineHeight;
      font-weight: $emailSubscribeComingSoonFontWeight;
      max-width: $emailSubscribeComingSoonMaxWidth;
    }

    .mn_onboardingOptin {
      margin: map-get($emailSubscribeOptinMargin, large);

      .mn_optInImage {
        background: $emailSubscribeOptInImageBackground;
        height: $emailSubscribeOptInImageHeight;
        width: $emailSubscribeOptInImageWidth;
        margin: map-get($emailSubscribeOptInImageMargin, medium);
      }

      h2 {
        margin: map-get($emailSubscribeOptInH2Margin, large);
      }

      p {
        width: $emailSubscribeTextWidth;
        margin: $emailSubscribeTextMargin;
        font-size: $emailSubscribeTextFontSize;
        font-weight: map-get($emailSubscribeTextFontWeight, large);
        line-height: $emailSubscribeTextLineHeight;
      }
    }

    .mn_controls {
      display: $emailSubscribeControlsDisplay;
      flex-direction: $emailSubscribeControlsFlexDirection;
      margin: map_get($emailSubscribeControlsMargin, medium);

      .mn_emailOptInSubmit {
        font-size: map_get($emailSubscribeSubmitFontSize, medium);
        margin: map_get($emailSubscribeSubmitMargin, large);
        width: map_get($emailSubscribeSubmitWidth, medium);
        height: map_get($emailSubscribeSubmitHeight, medium);
        padding: map_get($emailSubscribeSubmitPadding, medium);
        line-height: $emailSubscribeSubmitLineHeight;
        border: $emailSubscribeSubmitBorder;
        font-weight: $emailSubscribeSubmitFontWeight;
        color: $emailSubscribeSubmitColor;
        background: $emailSubscribeSubmitBackground;
      }

      .mn_emailOptInCancel {
        display: map_get($emailSubscribeCancelLinkDisplay, medium);
        color: map_get($emailSubscribeCancelLinkColor, medium);
        font: map_get($emailSubscribeCancelLinkFont, medium);
        border: $emailSubscribeCancelLinkBorder;
        background: $emailSubscribeCancelLinkBackground;
        padding: $emailSubscribeCancelLinkPadding;
        border-radius: $emailSubscribeCancelLinkBorderRadius;
        text-transform: $emailSubscribeCancelLinkTextTransform;
        margin: map-get($emailSubscribeCancelMargin, medium);

        .mn_buttonLabel {
          color: $emailSubscribeButtonLabelColor;

          .mn_buttonLabelIcon {
            display: $emailSubscribeCancelLinkLabelIconDisplay;
          }

          .mn_dot {
            display: $emailSubscribeCancelLinkLabeDotDisplay;
          }

          &:after {
            display: $emailSubscribeCancelLinkLabelAfterDisplay;
            content: $emailSubscribeCancelLinkLabelAfterContent;
            font-family: $emailSubscribeCancelLinkLabelAfterFontFamily;
            font-size: 1.2rem;
            margin-left: 1rem;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
          }
        }

        &:hover {
          text-decoration: $emailSubscribeCancelLinkLabelHoverTextDecoration;
          cursor: pointer;
        }
      }
    }
  }

  @media #{$less-than-medium-screen} {
    .mn_modal {
      top: auto;
      bottom: 0;
      padding: $emailSubscribePanelPadding;
      background: $emailSubscribePanelBackground;
      box-shadow: map-get($emailSubscribeModalBoxShadow, small);
      border-radius: map-get($emailSubscribeModalBorderRadius, small);
      width: map_get($emailSubscribeModalWidth, small);
      max-width: map-get($emailSubscribeModalMaxWidth, small);
    }

    .mn_closeModal {
      color: map-get($emailSubscribeCloseColor, small);
      width: map-get($emailSubscribeCloseWidth, small);
      height: map-get($emailSubscribeCloseHeight, small);
      font-weight: map-get($emailSubscribeCloseFontWeight, small);
      opacity: $emailSubscribeCloseOpacity;
    }

    .mn_sectionTitle h2 {
      font: map_get($emailSubscribeHeadingFont, small);
      color: map_get($emailSubscribeHeadingColor, small);
      margin: map_get($emailSubscribeHeadingMargin, small);
      display: map_get($emailSubscribeHeadingDisplay, small);
    }

    .mn_modalContent {
      color: map_get($emailSubscribeContentColor, small);
      font: map_get($emailSubscribeContentFont, small);

      h2 {
        font-size: map_get($emailSubscribeSeparateHeadingFontSize, small);
        line-height: map_get($emailSubscribeSeparateHeadingLineHeight, small);

        .mn_checkIcon {
          background-size: map_get($emailSubscribeCheckIconBackgroundSize, small);
          width: map_get($emailSubscribeCheckIconWidth, small);
          height: map_get($emailSubscribeCheckIconHeight, small);
          margin-right: map_get($emailSubscribeCheckIconMarginRight, small);
        }
      }

      .mn_controls {
        margin: map_get($emailSubscribeControlsMargin, small);

        .mn_submitWrapper {
          margin: 0 0 1rem;
        }

        .mn_emailOptInSubmit {
          font-size: map_get($emailSubscribeSubmitFontSize, small);
          padding: map_get($emailSubscribeSubmitPadding, small);
          margin: map_get($emailSubscribeSubmitMargin, small);
          width: map_get($emailSubscribeSubmitWidth, small);
          height: map_get($emailSubscribeSubmitHeight, small);
        }

        .mn_emailOptInCancel {
          display: map_get($emailSubscribeCancelLinkDisplay, small);
          text-align: left;
          font: map_get($emailSubscribeCancelLinkFont, small);
          color: map_get($emailSubscribeCancelLinkColor, small);

          // keep the right arrow icon on the same line as the last label character
          .mn_buttonLabel {
            padding-right: $emailSubscribeButtonLabelPaddingRight;
            position: relative;
            color: $emailSubscribeButtonLabelColor;
            font-size: $emailSubscribeButtonLabelFontSize;

            .mn_buttonLabelIcon {
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
  }

  @media #{$less-than-large-screen} {
    .mn_emailOptInSubscribeModal {
      .mn_modalContent {
        .mn_onboardingOptin {
          margin: map-get($emailSubscribeOptinMargin, medium);

          h2 {
            margin: map-get($emailSubscribeOptInH2Margin, medium);
          }

          p {
            font-weight: map-get($emailSubscribeTextFontWeight, medium);
          }
        }

        .mn_controls {
          .mn_emailOptInSubmit {
            margin: map_get($emailSubscribeSubmitMargin, medium);
          }
        }
      }
    }
  }
}
