.mn_loaderWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .mn_loader {
    height: 1rem;
    max-width: 100%;
  }
}

// `mn_loaderContent` wrapper is used to attach `ref` so the size of the content could be calculated properly.
// `contents` display property ensures this element doesn't produce a specific box by themselves
.mn_loaderContent {
  display: contents;
}
