* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: scroll; // Please leave to avoid scroll hide jump
  font-size: 10px;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  &:after {
    visibility: hidden;
    font-size: 0;
    display: block;
    content: 'XS';

    @media #{$small-screen} { content: 'S'; }

    @media #{$medium-screen} { content: 'M'; }

    @media #{$large-screen} { content: 'L'; }

    @media #{$xlarge-screen} { content: 'XL'; }

    @media #{$xxlarge-screen} { content: 'XXL'; }
  }
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/** Typography **/
body,
input,
button,
select,
textarea {
  font-size: map-get($brandTextFontSize, small);
  line-height: map-get($brandLineHeight, small);
  font-family: $brandFontFamily;
  color: $brandTextColor;
  letter-spacing: map-get($brandTextLetterSpacing, small);
}

// Prevent auto zoom on iphone if input font-size is less than 16px
// https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
input,
select:focus,
textarea {
  font-size: 16px;
}

a {
  color: $brandLinkColor;
  text-decoration: none;

  &:hover {
    text-decoration: $brandLinkHoverDecoration;
    color: $brandLinkColorHover;
  }
}

h1 {
  font-size: map-get($brandH1FontSize, small);
  line-height: map-get($brandH1LineHeight, small);
  color: $brandH1Color;
  font-weight: $brandH1FontWeight;
  font-family: $brandH1FontFamily;
  margin: map-get($brandH1Margin, small);
  text-transform: $brandH1Transform;
  letter-spacing: $brandH1LetterSpacing;
}

h2 {
  font-size: map-get($brandH2FontSize, small);
  line-height: map-get($brandH2LineHeight, small);
  color: $brandH2Color;
  font-weight: $brandH2FontWeight;
  font-family: $brandH2FontFamily;
  margin: map-get($brandH2Margin, small);
  text-transform: $brandH2Transform;
  letter-spacing: $brandH2LetterSpacing;
}

h3 {
  font-size: map-get($brandH3FontSize, small);
  line-height: map-get($brandH3LineHeight, small);
  color: $brandH3Color;
  font-weight: $brandH3FontWeight;
  font-family: $brandH3FontFamily;
  margin: map-get($brandH3Margin, small);
  text-transform: $brandH3Transform;
  letter-spacing: $brandH3LetterSpacing;
}

p { margin: map-get($brandParagraphMargin, small); }

ul {
  margin: map-get($brandUlMargin, small);
  padding: map-get($brandUlPadding, small);

  li {
    margin: map-get($brandUlLiMargin, small);
  }

  ul {
    margin: map-get($brandUlLvl2Margin, small);
  }

  ol {
    margin: map-get($brandOlLvl2Margin, small);
  }
}

ol { /* stylelint-disable-line */
  margin: map-get($brandOlMargin, small);
  padding: map-get($brandOlPadding, small);

  li {
    margin: map-get($brandOlLiMargin, small);
  }

  ul {
    margin: map-get($brandUlLvl2Margin, small);
  }

  ol {
    margin: map-get($brandOlLvl2Margin, small);
  }
}

img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  border: none;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;

  legend {
    margin: 0;
    padding: 0;
  }
}

.mn_favoriteItemWrap {
  .mn_addToFavorite {
    &:focus {
      .mn_favoriteIcon {
        opacity: 1;
      }
    }
  }

  .mn_favoriteIcon {
    opacity: $brandUnselectedFavoriteOpacity;

    &[data-is-favorite='true'] {
      opacity: 1;
    }
  }

  &:hover {
    &:focus {
      outline: none;
    }

    .mn_favoriteIcon {
      opacity: 1;

      &:focus {
        opacity: 1;
        outline: none;
      }
    }
  }
}

.mn_noTouch .mn_favoriteItemWrap .mn_favoriteIcon {
  &:focus {
    opacity: 1;
  }
}

.mn_touch .mn_favoriteItemWrap .mn_favoriteIcon {
  opacity: 1;
}

/** Utilities **/
.mn_clearfix { @include clearfix; }

.mn_clear {
  clear: both;
  line-height: 0;
}

.mn_noWrap {
  white-space: nowrap;
}

.mn_sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.mn_invisible {
  visibility: hidden !important;
  display: block !important;
  height: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

.mn_hidden { display: none !important; }
.mn_bold { font-weight: 500; }
.mn_underline { text-decoration: underline; }
.mn_italic { font-style: italic; }

// Fix image bluring on resize.
// Usage example: img.mn_merchantLogo { @extend %sharperImage; }
%sharperImage {
  image-rendering: -moz-crisp-edges;         /* Firefox */
  image-rendering: -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}

/* Button */
.mn_button {
  display: inline-block;
  padding: map-get($brandButtonPadding, small);
  border: $brandButtonBorder;

  @if ($brandButtonBorderRadius != 0) {
    border-radius: $brandButtonBorderRadius;
  }

  @if ($brandButtonShadow != 'none') {
    box-shadow: $brandButtonShadow;
  }

  text-align: center;
  position: $brandButtonPosition;
  letter-spacing: $brandButtonLetterSpacing;
  text-transform: $brandButtonFontTransform;
  text-decoration: none;
  font-family: $brandButtonFontFamily;
  font-size: $brandButtonFontSize;
  line-height: $brandButtonLineHeight;
  font-weight: $brandButtonFontWeight;
  color: $brandButtonColor;
  background: $brandButtonBackgroundColor;

  @if ($brandButtonBackgroundGradient != 'none') {
    @include vert-gradient($brandButtonBackgroundGradient);
  }

  cursor: pointer;

  &:hover {
    color: $brandButtonColorHover;

    @if ($brandButtonBorder != 'none') {
      border-color: $brandButtonBorderColorHover;
    }

    background: $brandButtonBackgroundColorHover;

    @if ($brandButtonBackgroundGradientHover != 'none') {
      @include vert-gradient($brandButtonBackgroundGradientHover);
    }

    text-decoration: none;
  }

  &:active,
  &:focus {
    color: $brandButtonColorActive;

    @if ($brandButtonBorder != 'none') {
      border-color: $brandButtonBorderColorActive;
    }

    background: $brandButtonBackgroundColorActive;

    @if ($brandButtonBackgroundGradientActive != 'none') {
      @include vert-gradient($brandButtonBackgroundGradientActive);
    }
  }

  &.mn_buttonInverted {
    background: $brandButtonInvertedBackground;
    color: $brandButtonInvertedColor;
    border: $brandButtonInvertedBorderColor;
  }

  &.mn_secondaryButton {
    background: $brandButtonSecondaryBackground;
    color: $brandButtonSecondaryColor;
    border: $brandButtonSecondaryBorderColor;
  }
}

.mn_basicButton {
  cursor: pointer;
  background: unset;
  border: none;
  padding: 0;
  color: $brandLinkColor;

  &:hover {
    text-decoration: $brandLinkHoverDecoration;
    color: $brandLinkColorHover;
  }
}

/** Fade In Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.mn_addFadeInAnimation:after {
  animation: 1s fadeIn !important;
}

@media #{$medium-screen} {
  body,
  input,
  button,
  select,
  textarea {
    font-size: map-get($brandTextFontSize, medium);
    line-height: map-get($brandLineHeight, medium);
    letter-spacing: map-get($brandTextLetterSpacing, medium);
  }

  h1 {
    font-size: map-get($brandH1FontSize, medium);
    line-height: map-get($brandH1LineHeight, medium);
    margin: map-get($brandH1Margin, medium);
  }

  h2 {
    font-size: map-get($brandH2FontSize, medium);
    line-height: map-get($brandH2LineHeight, medium);
    margin: map-get($brandH2Margin, medium);
  }

  h3 {
    font-size: map-get($brandH3FontSize, medium);
    line-height: map-get($brandH3LineHeight, medium);
    margin: map-get($brandH3Margin, medium);
  }

  .mn_button {
    padding: map-get($brandButtonPadding, medium);
  }
}

.mn_contentWrap {
  width: $xxlarge;
  max-width: 100%;
  padding: map-get($contentWrapPadding, small);
  margin: 0 auto;

  @media #{$medium-screen} {
    padding: map-get($contentWrapPadding, medium);
  }

  @media #{$large-screen} {
    padding: map-get($contentWrapPadding, large);
  }
}
