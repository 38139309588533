@import '~org/styles/variables';

$instoreWarningGap: 1rem !default;

$instoreWarningIconWidth: 3rem !default;
$instoreWarningIconHeight: 3rem !default;
$instoreWarningIconBackground: url('~core/assets/images/icons/warning_triangle_red.svg') !default;
$instoreWarningIconBackgroundPosition: center !default;
$instoreWarningIconBackgroundRepeat: no-repeat !default;
$instoreWarningIconBackgroundSize: contain !default;

$instoreWarningTextColor: #b83302 !default;
$instoreWarningTextFontSize: 1.1rem !default;
$instoreWarningTextLineHeight: 2rem !default;

.mn_instoreWarning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $instoreWarningGap;

  .mn_icon {
    width: $instoreWarningIconWidth;
    height: $instoreWarningIconHeight;
    background: $instoreWarningIconBackground;
    background-position: $instoreWarningIconBackgroundPosition;
    background-repeat: $instoreWarningIconBackgroundRepeat;
    background-size: $instoreWarningIconBackgroundSize;
  }

  .mn_text {
    color: $instoreWarningTextColor;
    font-size: $instoreWarningTextFontSize;
    line-height: $instoreWarningTextLineHeight;
  }
}
