@import '~org/styles/variables';

$instoreUnlinkConfirmationMargin: 1rem 0 0 0 !default;
$instoreUnlinkConfirmationPadding: 1rem .5rem .5rem !default;
$instoreUnlinkConfirmationFontSize: 1.4rem !default;
$instoreUnlinkConfirmationLineHeight: 2rem !default;
$instoreUnlinkConfirmationBackground: #f7f7f7 !default;

$instoreUnlinkConfirmationMessageMargin: 0 1rem 0 0;

$instoreUnlinkConfirmationMessageDividerPadding: 0 1rem !default;

.mn_instoreUnlinkConfirmation {
  display: block;
  min-width: 100%;
  text-align: center;
  font-size: $instoreUnlinkConfirmationFontSize;
  line-height: $instoreUnlinkConfirmationLineHeight;
  padding: $instoreUnlinkConfirmationPadding;
  margin: $instoreUnlinkConfirmationMargin;
  background: $instoreUnlinkConfirmationBackground;

  .mn_confirmMessage {
    display: inline;
    margin: $instoreUnlinkConfirmationMessageMargin;
  }

  .mn_confirmButtons {
    display: inline;

    .mn_divider {
      padding: $instoreUnlinkConfirmationMessageDividerPadding;
    }
  }
}
