@import '~org/styles/variables';
@import '~org/styles/mixins';

$merchantTileWidth: 16.4rem !default;
$merchantTileHeight: 19.4rem !default;
$merchantTileBorder: () !default;
$merchantTileBorderRadius: .4rem !default;
$merchantTileBoxShadow: (small: .5px .5px 2px 0 rgba(0, 0, 0, .2), medium: 1px 1px 4px 0 rgba(0, 0, 0, .2)) !default;
$merchantTileItemBorder: () !default;
$merchantTileItemPositionTop: (small: .3rem, medium: ()) !default;
$merchantTileItemPositionBottom: (small: .3rem, medium: ()) !default;
$merchantTileItemPositionLeft: (small: .3rem, medium: ()) !default;
$merchantTileItemPositionRight: (small: .3rem, medium: ()) !default;
$merchantTileImageMargin: (small: 25px auto 0, medium: ()) !default;
$merchantTilePillWidth: (small: 100%, medium: 100%) !default;
$merchantTilePillBottom: 6rem !default;
$merchantTileRebateHeight: (small: 6.7rem, medium: 6.7rem) !default;
$merchantTileRebatePadding: () !default;
$merchantTileRebateFontSize: 1.8rem !default;
$merchantTileRebateAlignItems: center !default;
$merchantTileRebateBackground: (small: #f7f7f7, medium: #f7f7f7) !default;
$merchantTileRebateHoverTextDecoration: () !default;
$merchantTileRebateBorderRadius: () !default;
$merchantTileFavoriteIconPositionTop: (small: 0, medium: 2px) !default;
$merchantTileFavoriteIconPositionRight: (small: 0, medium: 2px) !default;
$merchantTileFavoriteIconWidth: (small: 1.9rem, medium: 2.1rem) !default;
$merchantTileFavoriteIconHeight: (small: 1.9rem, medium: 2.1rem) !default;

.mn_merchantTile {
  position: relative;
  text-align: center;
  width: $merchantTileWidth;
  height: $merchantTileHeight;
  border: map-get($merchantTileBorder, small);
  border-radius: $merchantTileBorderRadius;
  box-shadow: map-get($merchantTileBoxShadow, small);
  background: #fff;

  .mn_tile {
    border: map-get($merchantTileItemBorder, small);
    border-radius: 1px;
    position: absolute;
    top: map-get($merchantTileItemPositionTop, small);
    bottom: map-get($merchantTileItemPositionBottom, small);
    left: map-get($merchantTileItemPositionLeft, small);
    right: map-get($merchantTileItemPositionRight, small);
  }

  img {
    @include broken-image($icon: '');

    margin: map-get($merchantTileImageMargin, small);
    pointer-events: none;
  }

  .mn_extraRewardsPill {
    width: map-get($merchantTilePillWidth, small);
    bottom: $merchantTilePillBottom;
  }

  .mn_merchantRebateV2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: map-get($merchantTileRebateHeight, small);
    padding: $merchantTileRebatePadding;
    font-size: $merchantTileRebateFontSize;
    background: map-get($merchantTileRebateBackground, small);
    display: flex;
    align-items: $merchantTileRebateAlignItems;
    justify-content: center;
    border-radius: $merchantTileRebateBorderRadius;
  }

  &:hover .mn_merchantRebateV2 {
    text-decoration: $merchantTileRebateHoverTextDecoration;
  }

  .mn_favoriteIcon {
    position: absolute;
    top: map-get($merchantTileFavoriteIconPositionTop, small);
    right: map-get($merchantTileFavoriteIconPositionRight, small);
    width: map-get($merchantTileFavoriteIconWidth, small);
    height: map-get($merchantTileFavoriteIconHeight, small);
  }

  .slick-slide & {
    margin-bottom: 1rem;
  }

  @media #{$medium-screen} {
    box-shadow: map-get($merchantTileBoxShadow, medium);
    border: map-get($merchantTileBorder, medium);

    .mn_tile {
      border: map-get($merchantTileItemBorder, medium);
      top: map-get($merchantTileItemPositionTop, medium);
      bottom: map-get($merchantTileItemPositionBottom, medium);
      left: map-get($merchantTileItemPositionLeft, medium);
      right: map-get($merchantTileItemPositionRight, medium);
    }

    .mn_favoriteIcon {
      top: map-get($merchantTileFavoriteIconPositionTop, medium);
      right: map-get($merchantTileFavoriteIconPositionRight, medium);
      width: map-get($merchantTileFavoriteIconWidth, medium);
      height: map-get($merchantTileFavoriteIconHeight, medium);
    }

    .mn_merchantRebateV2 {
      height: map-get($merchantTileRebateHeight, medium);
      background: map-get($merchantTileRebateBackground, medium);
    }

    img {
      margin: map-get($merchantTileImageMargin, medium);
    }

    .mn_extraRewardsPill {
      width: map-get($merchantTilePillWidth, medium);
    }
  }
}
