@import '~org/styles/variables';

$instoreOfferDisclaimerFontWeight: 100 !default;
$instoreOfferDisclaimerColor: #626b79 !default;
$instoreOfferDisclaimerFontSize: 1.2rem !default;
$instoreOfferDisclaimerLineHeight: 1.6rem !default;
$instoreOfferDisclaimerMargin: 3rem 0 0 0 !default;

$instoreOfferDisclaimerTitleFontWeight: bold !default;

.mn_instoreOfferDisclaimer {
  text-align: left;
  font-weight: $instoreOfferDisclaimerFontWeight;
  color: $instoreOfferDisclaimerColor;
  font-size: $instoreOfferDisclaimerFontSize;
  line-height: $instoreOfferDisclaimerLineHeight;
  margin: $instoreOfferDisclaimerMargin;

  .mn_title {
    font-weight: $instoreOfferDisclaimerTitleFontWeight;
  }
}
