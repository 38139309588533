@import '~org/styles/variables';

$instoreUnlinkSuccessMessageBackground: #f7f7f7 !default;
$instoreUnlinkSuccessMessageFontSize: 1.4rem !default;
$instoreUnlinkSuccessMessageLineHeight: 2rem !default;
$instoreUnlinkSuccessMessageMinHeight: 3.5rem !default;
$instoreUnlinkSuccessMessagePadding: .5rem 1rem !default;

.mn_instoreUnlinkSuccessMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  background: $instoreUnlinkSuccessMessageBackground;
  font-size: $instoreUnlinkSuccessMessageFontSize;
  line-height: $instoreUnlinkSuccessMessageLineHeight;
  min-height: $instoreUnlinkSuccessMessageMinHeight;
  padding: $instoreUnlinkSuccessMessagePadding;
}
