@import '~core/styles/variables';

// Screen sizes //
$xxlarge: 1420px;

// Colors //
$brandPrimaryColor: #376a04;
$brandSecondaryColor: #0b273d;
$brandTertiaryColor: #0f375b;
$brandQuaternaryColor: #627a88;

$brandPrimaryRed: #c30019;
$brandPrimaryGrey: #36495a;
$brandPrimaryBlue: #2172ba;
$brandDarkBlue: #00467d;
$brandGray: #d0dae0;
$brandLightGray: #e8e8e8;
$brandDarkGray: #dfdedc;
$brandWhite: #fff;
$brandLightColor: #f2f3f3;
$brandGrey: #828282;

$brandLightBlue: #486397;
$brandPrimaryBlue2: #086b94;
$brandBluishGray: #9fb1bc;
$brandLightBlue2: #3591ac;
$brandDarkGray2: #626b78;
$brandLightBlue2: #6fc1d9;
$brandDarkGray3: #585c62;
$brandDarkBlue3: #12627b;
$brandDarkBlue2: #0f385b;
$brandLightGray2: #e9e9e9;
$brandBoxShadow: 2px 2px 1px rgba(0, 0, 0, .08);
$brandDarkBlue4: #12395b;
$brandDarkBlue5: #0b2237;
$brandHeaderColor: #0c385a;

$brandNeutralDarkColor2: #ccc;
$brandBlack: #000;
$brandGray6: #778194;
$brandGray7: #616779;
$brandLightGray3: #f5f7fa;
$brandLightGray5: #f7f7f7;
$brandLightGray7: #f1f2f2;
$brandLightGray9: #d4d4d0;
$brandLightBlue3: #627a88;
$brandDarkerBlue: #11172b;
$brandBlue2: #006c97;
$brandBlue4: #4470c3;
$brandBlue5: #3d4558;
$brandBlue6: #242a48;
$brandBlue7: #2774ae;
$brandLightBlue4: #017fc4;

// Typography //
// Text
$brandTextColor: #333940;
$brandTextColorInverse: #fff;
$brandTextFontSize: (small: 1.6rem, medium: 1.5rem);
$brandFontFamily: Arial, Helvetica, sans-serif;
$brandFontFamilyHelvetica: 'Helvetica';
// Links
$brandLinkColor: #01587a;
$brandLinkColorHover: ();
$noRebateLinkLabelColor: #585c62;
$noRebateLinkLabelTextTransform: capitalize;
// Border
$brandBorderColor: $brandGray;
$brandBorder: $brandBorderColor solid 1px;
// Ul
$brandUlLiMargin: (small: 0);

// Section //
$brandH1Transform: capitalize;
$brandH2Transform: capitalize;

// H2
$brandH2Color: $brandHeaderColor;

// Button //
$brandButtonFontSize: 1.6rem;
$brandButtonFontWeight: normal;
$brandButtonFontTransform: capitalize;
$brandButtonFontFamily: $brandFontFamily;
$brandButtonColor: $brandWhite;
$brandButtonBackgroundColor: $brandPrimaryColor;
$brandButtonBorder: none;
$brandButtonBorderRadius: 0;
$brandButtonShadow: none;
$brandButtonPadding: (small: .8rem 2rem);
$brandButtonBackgroundGradient: none;
// hover
$brandButtonColorHover: $brandWhite;
$brandButtonBackgroundColorHover: #488618;
$brandButtonBackgroundGradientHover: none;
// active
$brandButtonColorActive: $brandWhite;
$brandButtonBackgroundColorActive: #488618;
$brandButtonBackgroundGradientActive: none;

// Pill
$pillHeight: 1.8rem;
$pillBackground: $brandLinkColor;
$pillLabelColor: $brandWhite;
$pillLabelLineHeight: 1.8rem;
$pillLabelAfterBackground: url('~org/assets/icons/Icon_Reward.svg') no-repeat no-repeat;
$pillLabelAfterWidth: 1.3rem;
$pillLabelAfterHeight: 1.3rem;
$pillLabelAfterPositionLeft: .2rem;
$pillLabelAfterPositionTop: .2rem;

// Rebates //
// Rebate Bar
$rebateBarBackground: $brandLightColor;
$rebateBarColor: $brandPrimaryBlue2;
$rebateBarFontSize: 1.3rem;
$rebateBarOldValueColor: $brandDarkGray3;
$rebateBarTierNameValueTextTransform: capitalize;

/* Rebates */
// Rebate
$rebateColor: $brandBlue2;
$rebateValueColor: $brandBlue4;
$rebateValueWithCurrencyFontWeight: 600;
$rebateCurrencyColor: $brandBlue4;
$rebateCurrencyFontWeight: 600;

// Rebate V2
$rebateV2OldValueFontSize: 1.2rem;
$rebateV2OldValueColor: $brandDarkGray3;
$rebateV2OldValueLineHeight: 1.8rem;
$rebateV2NewValueFontColor: $brandPrimaryBlue2;
$rebateV2NewValueFontSize: 1.2rem;
$rebateV2NewValueFontWeight: 700;
$rebateV2NewValueLineHeight: 1.4;
$rebateV2SuffixFontWeight: 700;

// Rebate Elevation
$rebateElevationColor: $brandTextColor;
$rebateElevationStrikethroughPrimary: 1px solid $brandWhite;

// Premier Rebate
$premierRebateColor: $brandPrimaryBlue2;
$premierRebateFontSize: 1.4rem;
$premierRebateFontWeight: 700;
$premierRebateValueColor: $brandPrimaryBlue2;
$premierRebateValueFontSize: 1.4rem;
$premierRebateValueWithCurrencyFontSize: 1.4rem;
$premierRebateValueWithCurrencyFontWeight: 700;
$premierRebateValueWithCurrencyOldValueFontWeight: 400;
$premierRebateValueWithCurrencyOldValueDisplay: inline-block;
$premierRebateValueTierNameTextTransform: none;
$premierRebateNewValueFontSize: 1.4rem;
$premierRebateOldValueAfterContent: '';
$premierRebateElevationColor: $brandDarkGray3;

/* Merchant Rebate */
$rebateElevationColor: $brandSecondaryColor;
$rebateElevationTextFontWeight: 400;
$rebateElevationStrikethroughPrimary: 2px solid $brandSecondaryColor;

// Favorite Icon
$favoriteIconStrokeWidth: 2;
$favoriteIconFillColor: $brandWhite;
$favoriteIconStrokeColor: #3591ac;
$favoriteIconFavoritedFillColor: #3591ac;
$favoriteIconDisabledFillColor: $brandWhite;
$favoriteIconDisabledStrokeWidth: 2;

/* Balloon tooltip */
$balloon-bg: $brandWhite;
$balloon-color: #333940;
$balloon-border-radius: 0;
$balloonSVGFillColor: '%23fff';
$balloonSVGStrokeColor: '%23e9e9e9';

/* Favorites */
$brandUnselectedFavoriteOpacity: 0;

/* Lightbox */
$lightboxCloseIconContent: '\2715';
$lightboxCloseIconFontFamily: 'fontello';

/* Modal */
$modalCloseButtonFontSize: (small: 2.2rem);
$modalCloseButtonColor: #9eafb9;
$modalBoxShadow: (small: none);

/* Section Title */
$sectionTitleMargin: (small: 0 0 2rem 0);
$sectionTitleH2FontSize: (small: 3rem, medium: 3rem);
$sectionTitleH2FontFamily: $brandFontFamilyHelvetica;
$sectionTitleH2FontWeight: (small: bold);
$sectionTitleH2LineHeight: (small: 3.6rem, medium: 3.6rem);
$sectionTitleH2LetterSpacing: (small: .7px, medium: 1px);
$sectionTitleH2Color: (small: $brandDarkBlue4, medium: $brandDarkBlue2);
$sectionTitleH2TextAlign: (small: center, medium: center, xxlarge: left);
$sectionTitleAlignItems: (medium: flex-start, xxlarge: flex-start);
$sectionTitleH2MinorTextTransform: (small: lowercase, medium: capitalize);
$sectionTitleLinkFontSize: 1.4rem;
$sectionTitleLinkTextTransform: capitalize;
$sectionTitleLinkAfterContent: '\f105';
$sectionTitleLinkAfterFontFamily: 'fontello';
$sectionTitleLinkAfterFontSize: 1.4rem;
$sectionTitleLinkAfterMargin: 0 0 0 .5rem;
$sectionTitleLinkAfterFontWeight: 400;

/* Header navigation */
$navigationListItemFontSize: 1.4rem;
$navigationListItemLinkColor: $brandHeaderColor;
$navigationListItemLinkPadding: 1.5rem 0;
$navigationListItemHoverTextDecoration: underline;
$navigationListDropdownItemTriggerColor: $brandHeaderColor;
$navigationListDropdownItemTriggerPadding: 1.5rem 0;
$navigationListDropdownItemTriggerBeforeContent: '\f107';
$navigationListDropdownItemTriggerBeforeFontFamily: 'fontello', serif;
$navigationListDropdownItemTriggerBeforeFontWeight: bold;
$navigationListDropdownItemTriggerBeforeColor: $brandHeaderColor;
$navigationListDropdownItemTriggerBeforeFontSize: 3.27rem;
$navigationListDropdownItemTriggerBeforePositionLeft: ();
$navigationListDropdownItemTriggerBeforePositionRight: 0;
$navigationListDropdownItemTriggerBeforePositionTop: 50%;
$navigationListDropdownItemTriggerFocusedBeforeTransform: rotate(180deg);
$navigationListDropdownItemMenuMargin: 0;
$navigationListDropdownItemMenuPadding: 0;
$navigationListDropdownItemMenuLinkPadding: 1rem;
$navigationListDropdownItemMenuLinkFontSize: 1.4rem;
$navigationListDropdownItemMenuLinkColor: $brandHeaderColor;
$navigationListDropdownItemMenuLinkHoverTextDecoration: underline;
$navigationListDropdownItemMenuLinkTextTransform: capitalize;
$navPopularStoresViewAllLinkFontSize: 1.4rem;
$navigationListItemLineHeight: 1.8rem;

/* Nav Seasonal Flyout */
$navSeasonalFlyoutMargin: 3rem 0;
$navSeasonalFlyoutTileWidth: 13rem;
$navSeasonalFlyoutTileBorderRadius: 0;
$navSeasonalFlyoutTileWrapperMargin: 0 .5rem 1rem;
$navSeasonalFlyoutTileTop: .3rem;
$navSeasonalFlyoutTileBottom: .2rem;
$navSeasonalFlyoutTileRight: .2rem;
$navSeasonalFlyoutTileLeft: .2rem;
$navSeasonalFlyoutTilePillWidth: 8.8rem;
$navSeasonalFlyoutMerchantTileListJustifyContent: flex-start;
$navSeasonalFlyoutMerchantTileListMargin: 0 -.5rem;
$navSeasonalFlyoutCTAColor: $brandLinkColor;
$navSeasonalFlyoutCTAFontSize: 1.4rem;
$navSeasonalFlyoutCTATextTransform: capitalize;
$navSeasonalFlyoutCTAWrapMargin: 1rem .5rem;
$navSeasonalFlyoutContainerJustifyContent: left;

/* Merchant Tile */
$merchantTileRebateHeight: (small: 7rem);
$merchantTileRebateBackground: (small: $rebateBarBackground);
$merchantTileRebateFontSize: 1.2rem;
$merchantTileImageMargin: (small: 2.5rem auto 0, medium: 2rem auto 0);
$merchantTileFavoriteIconWidth: (small: 1.5rem);
$merchantTileFavoriteIconHeight: (small: 1.3rem, medium: 1.5rem);
$merchantTileFavoriteIconPositionRight: (small: 0, medium: 5px);
$merchantTileItemPositionTop: (small: .3rem, medium: 0);
$merchantTileItemPositionBottom: (small: .3rem, medium: 0);
$merchantTileItemPositionLeft: (small: .3rem, medium: 0);
$merchantTileItemPositionRight: (small: .3rem, medium: 0);
$merchantTilePillWidth: (small: 10rem);
$merchantTileBorderRadius: 0;

/* Merchant With Offer Count Tile */
$merchantWithOfferCountWidth: 24.5rem;
$merchantWithOfferCountHeight: 22.3rem;
$merchantWithOfferCountBorder: (small: 1px solid $brandLightGray9);
$merchantWithOfferCountFavoriteIconWidth: 1.5rem;
$merchantWithOfferCountFavoriteIconHeight: 1.3rem;
$merchantWithOfferCountImageMargin: 2.8rem auto 0;
$merchantWithOfferCountH3Color: $brandTextColor;
$merchantWithOfferCountH3FontSize: 1.2rem;
$merchantWithOfferCountH3LineHeight: 1.5rem;
$merchantWithOfferCountH3FontWeight: normal;
$merchantWithOfferCountH3FontStyle: oblique;
$merchantWithOfferCountH3Margin: 1.4rem 0 0;
$merchantWithOfferCountRebateBackground: (small: $brandLightColor);
$merchantWithOfferCountRebatePositionTop: 146px;
$merchantWithOfferCountRebateHeight: 69px;
$merchantWithOfferCountRebateFontSize: 1.2rem;
$merchantWithOfferCountPillWidth: 44.3%;

/* Navigation banner */
$navBannerButtonBannerBackground: $brandWhite url('~org/assets/header/navBannerFavorites.png') no-repeat -4px -8px;
$navBannerButtonBannerImageBorder: $brandWhite solid 2px;
$navBannerButtonBannerImagePositionTop: -1.2rem;
$navBannerContentBackground: $brandBlue7;
$navBannerContentPadding: 1rem 2rem 1rem 8rem;
$navBannerContentFontSize: 1.4rem;
$navBannerContentColor: $brandWhite;
$navBannerContentLetterSpacing: .4px;
$navBannerTitleFontSize: 1.5rem;
$navBannerTitleMargin: .5rem 0 1rem;
$navBannerTitleColor: $brandWhite;
$navBannerTitleLetterSpacing: .4px;
$navBannerLinkColor: $brandWhite;
$navBannerLinkFontWeight: 600;
$navBannerLinkTextDecoration: underline;

/* Navigation TOF */
$navCategoriesTOFDropdownMenuWidth: 37rem;
$navCategoriesTOFDropdownMenuPositionLeft: 25.9rem;
$navCategoriesTOFDropdownMenuPadding: 2rem;
$navCategoriesTOFDropdownMenuBackground: $brandLightColor;
$navCategoriesTOFDropdownMenuTop: 1rem;
$navCategoriesTOFDropdownMenuMinHeight: calc(100% - 3rem);
$navCategoriesTOFFeaturedSectionTitleMargin: 0 0 1.5rem;
$navCategoryFeaturedItemWidth: 25rem;
$navCategoryFeaturedItemTitleFontSize: 1.2rem;
$navCategoryFeaturedItemTitleFontWeight: bold;
$navCategoryFeaturedItemTitleColor: $brandDarkGray2;
$navCategoryFeaturedItemHoverColorBackground: none;
$navCategoryFeaturedItemRebateFontSize: 1.4rem;
$navCategoryFeaturedItemRebateFontWeight: 700;
$navCategoryFeaturedItemRebateDisplay: inline;
$navCategoryFeaturedItemInlineRebateDisplay: inline;
$navCategoryFeaturedItemInlineRebateFontSize: 1.4rem;
$navCategoryFeaturedItemRebateOldValueFontSize: 1.4rem;
$navCategoryFeaturedItemRebateOldValueColor: $brandDarkGray3;
$navCategoryFeaturedItemRebateColor: $brandPrimaryBlue2;
$navCategoryFeaturedItemRebatePrefixColor: $brandPrimaryBlue2;
$navCategoryFeaturedFooterLinkFontSize: 1.4rem;
$navCategoryFeaturedFooterLinkMargin: 1rem 0 0;
$navCategoryFeaturedFooterLinkColor: $brandLinkColor;
$navCategoryFeaturedFooterLinkFontWeight: 400;
$navCategoryFeaturedFooterLinkCategoryTextTransform: capitalize;
$navCategoryFeaturedItemBorderBottom: 1px $brandLightGray2 solid;

/* Category Flyout */
$categoryFlyoutTitleMargin: 0 0 1rem 0;
$categoryFlyoutTitleRebateMargin: ();
$categoryFlyoutTitleRebatePadding: ();
$categoryFlyoutTitleRebateBorder: ();
$categoryFlyoutTitleRebateBackground: ();
$categoryFlyoutTitleRebateFontSize: 1.4rem;
$categoryFlyoutTitleRebateTextAlign: ();
$categoryFlyoutMerchantTitleFontSize: 1.4rem;
$categoryFlyoutMerchantTitleMargin: .6rem 0;
$categoryFlyoutTitleRebateNewValueFontSize: 1.3rem;
$categoryFlyoutMerchantTitleDisplay: inline;
$categoryFlyoutMerchantTitleColor: $brandGray6;
$categoryFlyoutMerchantTitleFontWeight: 700;
$categoryFlyoutInfoHoverColor: $brandWhite;
$categoryFlyoutInfoHoverColorTextDecoration: underline;
$categoryFlyoutTitleRebateDisplay: inline;
$categoryFlyoutTitleInlineRebateDisplay: inline;
$categoryFlyoutTitleRebateNewValueColor: $brandGray6;
$categoryFlyoutTitleRebateOldValueDisplay: inline;
$categoryFlyoutTitleRebateOldValueColor: $brandGray6;

/* Search Bar */
$searchBarDesktopWidth: 26rem;

/* Search Form */
$searchFormFocusBorderColor: none;
$searchFormInputPadding: .4rem 3.5rem .4rem .8rem;
$searchFormDesktopInputPadding: .4rem 3.5rem .4rem .8rem;
$searchFieldPlaceholderColor: $brandHeaderColor;
$searchFieldPlaceholderFontSize: 1.6rem;
$searchFieldPlaceholderFontSize: 700;
$searchFormSubmitButtonWidth: 4rem;

/* Recent Stores */
$recentStoresTitleMargin: 0;
$recentStoresTitleFontWeight: 700;
$recentStoresTitleFontSize: 1.6rem;
$recentStoresTitleHeadingMargin: 0;
$recentStoresTitleHeadingColor: $brandTextColor;
$recentStoresTitleHeadingTextTransform: capitalize;
$recentStoresPadding: 0;
$recentStoresFlipTileMargin: 0;
$recentStoresFlipTileBackBackground: ();
$recentStoresViewAllLinkBottomFontWeight: 300;
$recentStoresViewAllLinkBottomMargin: 1rem 0 .5rem;
$recentStoresViewAllLinkBottomFontSize: 1.4rem;
$recentStoresViewAllLinkBottomColor: $brandBlue7;
$recentStoresViewAllLinkPadding: 0 1rem 0 0;
$recentStoresViewAllLinkTextDecorationHover: none;
$recentStoresInSearchTitleMargin: 2.4rem 0;

/* Recent Stores Flyout */
$recentStoresFlyoutHeadBgColor: $brandBlue7;
$recentStoresFlyoutH2Color: $brandWhite;
$recentStoresFlyoutH2FontSize: 1.88rem;

/* Similar Stores */
$similarStoreDisplay: block;
$similarStoreBorder: 1px solid $brandLightGray2;
$similarStoreBorderRadius: 0;
$similarStoreBorderWidth: 0 0 1px;
$similarStorePadding: 0;
$similarStoreMargin: 0;
$similarStoresPadding: 0;
$similarStoresTitleColor: $brandTextColor;
$similarStoresTitleFontWeight: 700;
$similarStoresTitleFontSize: 1.6rem;
$similarStoresTitleJustifyContent: flex-start;
$similarStoresTitleHeadingMargin: 2rem 0 0;
$similarStoresTitleHeadingTextTransform: capitalize;
$similarStoreItemHeight: 5rem;
$similarStoreBackground: none;
$similarStoreLogoMargin: 0 auto 0 0;
$similarStoreRebatePadding: 0 1rem 0 0;
$similarStoresRebateFontSize: 1.3rem;
$similarStoresRebateValueFontSize: 1.3rem;
$similarStoresRebateOldValueFontSize: 1.3rem;
$similarStoresRebateOldValueColor: $brandGray6;
$similarStoresRebateOldValueAfterContent: ',';
$similarStoresRebateNewValueFontSize: 1.3rem;

/* Flip Tile */
$flipTileItemPadding: 0;
$flipTileLogoWrapMargin: 0 auto 0 0;
$flipTileLogoWrapPadding: 0;
$flipTileBorder: solid #e8ecf2;
$flipTileBorderWidth: 0 0 1px;
$flipTileJustifyContent: flex-start;
$flipTilePremierRebatePadding: 0;
$flipTilePremierRebateTextAlign: left;
$flipTilePremierRebateColor: $brandPrimaryBlue2;
$flipTilePremierRebateFontWeight: 700;
$flipTilePremierRebateFontSize: 1.3rem;
$flipTilePremierRebateValueWithCurrencyTextTransform: none;
$flipTilePremierRebateValueWithCurrencyColor: $brandDarkGray3;
$flipTilePremierRebateElevationOldValueFontWeight: 400;
$flipTilePremierRebateValueColor: $brandPrimaryBlue2;
$flipTilePremierRebateElevationOldValueAfterContent: ',';
$flipTilePremierRebateInlineElevationNewValueFontWeight: 700;
$flipTilePremierRebateInlineElevationNewValueWhiteSpace: normal;
$flipTileButtonHeight: 3rem;
$flipTileButtonWidth: 15rem;
$flipTileButtonLineHeight: 3.3rem;
$flipTileButtonFontSize: 1.4rem;
$flipTileButtonBackground: $brandPrimaryColor;
$flipTileButtonMargin: 0;
$flipTileButtonHoverBackground: $brandButtonBackgroundColorHover;
$flipTileFavoriteIconDisplay: flex;
$flipTileFavoriteIconHeight: (small: 1.2rem, medium: 2rem);
$flipTileFavoriteIconWidth: (small: 1.2rem, medium: 2rem);
$flipTileFavoriteIconMargin: 0 1.6rem 0 0;

/* Quick Search */
$quickSearchFooterProductLinkWrapMargin: 0;
$quickSearchFooterProductLinkWrapFontSize: 1.4rem;
$quickSearchFooterProductLinkWrapFontWeight: 400;
$quickSearchFooterProductLinkWrapPadding: 1rem 2rem;
$quickSearchFooterProductLinkColor: $brandBlue7;
$quickSearchFooterProductLinkWrapBorderTop: 1px solid #9ea6a8;
$quickSearchFooterPadding: .5rem 4rem .5rem 2rem;
$quickSearchFooterLineHeight: 1.8rem;
$quickSearchFooterBackground: $brandWhite;
$quickSearchFooterColor: $brandTextColor;
$quickSearchFooterBorderRadius: 0;

$quickSearchItemsWrapperPadding: 1rem 2rem 0;
$quickSearchResultsOverflow: auto;
$quickSearchResultsMaxHeight: (small: none, medium: 28rem);
$quickSearchFavoriteIconHeight: (small: 1.2rem, medium: 2.3rem);
$quickSearchFavoriteIconWidth: (small: 1.2rem, medium: 2.1rem);

$quickSearchMatchColor: $brandDarkGray2;
$quickSearchMatchFontSize: 1.2rem;
$quickSearchMatchPadding: (small: .5rem 5rem .5rem 2rem, medium: 1rem 3rem 1rem 0);
$quickSearchMatchBorderBottom: 1px solid $brandLightGray2;
$quickSearchMatchHoverBackground: #e7ecef;
$quickSearchMatchLineHeight: 1.8rem;
$quickSearchMatchTextFontWeight: 700;
$quickSearchMatchHighlightColor: $brandDarkGray2;

$quickSearchPremierRebatePrefixTieredFirstLetterTextTransform: capitalize;
$quickSearchMerchantRebateFontSize: 1.4rem;
$quickSearchMerchantRebatePrefixFontWeight: 700;
$quickSearchMerchantRebateColor: $brandPrimaryBlue2;
$quickSearchMerchantRebateFontWeight: 600;
$quickSearchMerchantRebateLineHeight: 1.7rem;
$quickSearchMerchantRebateValueColor: $brandPrimaryBlue2;
$quickSearchMerchantRebateElevationOldValueFontWeight: 400;
$quickSearchMerchantRebateElevationOldValueColor: $brandDarkGray3;

$quickSearchMerchantFavoriteIconPositionTop: (small: .8rem, medium: 1.8rem);
$quickSearchMerchantFavoriteIconPositionLeft: (small: (), medium: ());
$quickSearchMerchantFavoriteIconPositionRight: (small: 2rem, medium: 0);
$quickSearchMatchDashDisplay: none;

$quickSearchFooterProductLinkWrapBackground: $brandWhite;
$quickSearchResultsItemDisplay: block;
$quickSearchContentFlexDirection: column;
$quickSearchContentJustifyContent: (small: space-between, medium: flex-start);

$quickSearchDirectMerchantNamePadding: 0;
$quickSearchDirectMerchantNameColor: (small: $brandWhite, medium: $brandLinkColor);
$quickSearchDirectMerchantNameFontSize: (small: 1.2rem, medium: 1.4rem);
$quickSearchDirectMerchantNameFontWeight: 700;
$quickSearchDirectMerchantNameLineHeight: (small: 1.8rem, medium: 1.7rem);

/* Quick Links Tray */
$quickLinksTrayFilter: drop-shadow(0 1px 6px rgba(0, 0, 0, .75));
$quickLinksTrayBackgroundColor: url('~org/assets/trayIcons/quick_tray_back_swoosh.png');
$quickLinksTrayLinkSeparatorBorder: 1px solid rgba(186, 197, 210, .25);
$quickLinksTrayLinkFontSize: 1.2rem;
$quickLinksTrayLinkLineHeight: 1.8rem;
$quickLinksTrayLinkTextTransform: capitalize;
$quickLinksTrayLinkFontWeight: bold;
$quickLinksTrayIconMargin: 0 auto .2rem;
$quickLinksTrayIconWidth: 2.5rem;
$quickLinksTrayIconExtraRewardsUrl: url('~org/assets/trayIcons/rewards.svg');
$quickLinksTrayIconExtraRewardsHeight: 2.9rem;
$quickLinksTrayIconAccountUrl: url('~org/assets/trayIcons/account.svg');
$quickLinksTrayIconAccountHeight: 2.9rem;
$quickLinksTrayIconAllStoresUrl: url('~org/assets/trayIcons/stores.svg');
$quickLinksTrayIconAllStoresHeight: 2.9rem;
$quickLinksTrayToggleFontSize: 1.2rem;
$quickLinksTrayToggleLetterSpacing: .6px;
$quickLinksTrayToggleLineHeight: 1.3rem;
$quickLinksTrayToggleTextTransform: capitalize;
$quickLinksTrayToggleAfterFontFamily: 'fontello', serif;
$quickLinksTrayToggleAfterContent: '\f105';
$quickLinksTrayToggleAfterFontSize: 1.8rem;
$quickLinksTrayToggleAfterVerticalAlign: middle;
$quickLinksTrayHeight: 8.5rem;
$quickLinksSearchIconBackground: url('~org/assets/trayIcons/search.svg');
$quickLinksSearchIconBoxShadow: 0 1px 6px 0 rgba(0, 0, 0, .75);

/* Sticky CTA Bar VHP */
$stickyCtaBarVhpBackgroundColor: (small: $brandSecondaryColor);
$stickyCtaBarVhpColor: $brandWhite;
$stickyCtaBarVhpPadding: 2.4rem 0;
$stickyCtaBarVhpContentFlexDirection: (small: column, medium: row);
$stickyCtaBarVhpContentFlexWrap: (small: wrap);
$stickyCtaBarVhpHeaderFontSize: 3rem;
$stickyCtaBarVhpHeaderLineHeight: 3.6rem;
$stickyCtaBarVhpHeaderFontWeight: 400;
$stickyCtaBarVhpHeaderMargin: (small: 1rem 0 2rem, medium: 1rem 0);
$stickyCtaBarVhpHeaderColor: $brandWhite;
$stickyCtaBarVhpHeaderTextAlign: center;
$stickyCtaBarVhpButtonPadding: (small: 1.55rem 4rem);
$stickyCtaBarVhpButtonMargin: (small: 0 0 1rem, medium: 0 0 0 2rem);
$stickyCtaBarVhpButtonFontSize: 1.3rem;
$stickyCtaBarVhpButtonWidth: (small: 42.5rem, medium: 24rem);
$stickyCtaBarVhpButtonFontWeight: 700;
$stickyCtaBarVhpButtonMaxWidth: 95%;

/* How It Works */
$howItWorksInvertedSectionBackground: (small: $brandLightGray7);
$howItWorkSectionTitleH2TextAlign: (small: center);
$howItWorksInvertedSectionTitleH2Color: (small: $brandDarkBlue4);
$howItWorksItemWidth: 20rem;
$howItWorksItemMargin: 0 0 3rem;
$howItWorksItemTitleColor: (small: $brandHeaderColor);
$howItWorksInvertedItemTitleColor: $brandHeaderColor;
$howItWorksItemTitleFontSize: (small: 2.4rem);
$howItWorksItemTitleLineHeight: (small: 3.4rem);
$howItWorksItemTitleFontWeight: (small: bold);
$howItWorksItemTitleMargin: (small: 0 0 1.2rem);
$howItWorksItemDescriptionColor: (small: $brandPrimaryGrey);
$howItWorksInvertedItemDescriptionColor: $brandPrimaryGrey;
$howItWorksItemDescriptionMaxWidth: 31.4rem;
$howItWorksItemDescriptionPadding: 0;
$howItWorksItemDescriptionLineHeight: (small: 2.6rem);
$howItWorksInvertedDotsListMargin: 0 .7rem;
$howItWorksInvertedDotsButtonWidth: 1rem;
$howItWorksInvertedDotsButtonHeight: 1rem;
$howItWorksItemMarginBottom: (small: 3rem);
$howItWorksVideoLinkColor: $brandLinkColor;
$howItWorksVideoLinkFontSize: 1.4rem;
$howItWorksVideoLinkFontWeight: 400;
$howItWorksNewMemberSectionPadding: (small: 3.5rem 0 1.5rem);
$howItWorksNewMemberSectionTitleMargin: (small: 0 0 4rem 0);
$howItWorksNewMemberSectionTitleH2FontSize: (small: 4.4rem);
$howItWorksNewMemberSectionTitleH2FontWeight: (small: normal);

/* Trending Stores */
$trendingStoresNewMemberBackground: $brandBlue2;
$trendingStoresNewMemberPadding: 3rem 0 6.3rem;
$trendingStoresDividerBackground: rgba(0, 0, 0, .1);
$trendingStoresMerchantLinkTilePillTop: (medium: 55%);
$trendingStoresMerchantLinkTilePillWidth: 70%;
$trendingStoresMerchantLinkTileRebateHeight: (medium: 7rem, large: 7rem);
$trendingStoresMerchantLinkTileRebatePositionTop: (small: (), medium: 10.8rem);

/* Favorite Stores */
$favoriteStoresMerchantTileHeight: (small: 19rem, medium: 18.5rem);
$favoriteStoresMerchantTileBorder: (small: 1px solid $brandLightGray9);
$favoriteStoresMerchantTileBoxShadow: (small: none, medium: 0 2px 4px 0 rgba(255, 255, 255, .5));
$favoriteStoresMerchantTileFavoriteIconHeight: 2rem;
$favoriteStoresMerchantTileFavoriteIconWidth: 2rem;
$favoriteStoresMerchantTilePillPositionBottom: (small: 5.7rem, medium: 6.2rem);
$favoriteStoresMerchantTileRebateHeight: (small: (), medium: 7.2rem);
$favoriteStoresMerchantTileRebateNewValueFontSize: (small: (), medium: 1.8rem);
$favoriteStoresMerchantTileRebateNewValueLineHeight: 2.4rem;
$favoriteStoresManageFavoritesTileWrapPadding: (small: .2rem, medium: 0);
$favoriteStoresManageFavoritesTileWrapHeight: (small: 19rem, medium: 18.5rem);
$favoriteStoresManageFavoritesTileWrapWidth: (small: 19.3rem, medium: 24.3rem);
$favoriteStoresManageFavoritesTileHeight: (small: 100%, medium: 18.5rem);
$favoriteStoresManageFavoritesTilePadding: (small: .3rem, medium: 2rem);
$favoriteStoresManageFavoritesTileFontSize: (small: 1.8rem, medium: 2.8rem);
$favoriteStoresManageFavoritesTileBackground: (small: $brandDarkBlue4, medium: #3192aa);
$favoriteStoresManageFavoritesTileTextAlign: (small: center, medium: left);
$favoriteStoresManageFavoritesTileLineHeight: (small: 2.1rem, medium: 3.4rem);
$favoriteStoresManageFavoritesTileTextTransform: capitalize;
$favoriteStoresManageFavoritesTileLetterSpacing: (small: 0, medium: 0);
$favoriteStoresManageFavoritesTileBorderRadius: (small: 0, medium: 3px);
$favoriteStoresManageFavoritesTileFontWeight: (small: bold, medium: ());
$favoriteStoresManageFavoritesTileArrowFontFamily: (small: (), medium: 'fontello');
$favoriteStoresManageFavoritesTileArrowContent: (small: (), medium: '\f105');
$favoriteStoresManageFavoritesTileArrowPadding: (small: (), medium: 0 0 0 .8rem);
$favoriteStoresManageFavoritesTileArrowFontSize: (small: (), medium: 3.5rem);

/* Letterboxes */
$heroCarouselDotWidth: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.2rem,
largeMobileAndSmallDesktop: 1.2rem, largeTabletAndXLargeDesktop: 1.2rem);
$heroCarouselDotHeight: (smallMobile: 1.2rem, mediumMobile: 1.2rem, smallTablet: 1.2rem,
largeMobileAndSmallDesktop: 1.2rem, largeTabletAndXLargeDesktop: 1.2rem);
$heroCarouselNavigationPostionBottom: (smallMobile: (), mediumMobile: (), smallTablet: 2rem,
largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselMargin: (smallMobile: 0 0 5.9rem, mediumMobile: 0 0 5.9rem, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselContainerMargin: (smallMobile: 0, mediumMobile: 0, smallTablet: 0 0 5rem 0,
largeMobileAndSmallDesktop: 0 0 3rem 0, largeTabletAndXLargeDesktop: 0 0 3rem 0);
$heroCarouselBreakPointHeights: (smallMobile: auto, mediumMobile: auto, smallTablet: 261px,
largeMobileAndSmallDesktop: 234px, largeTabletAndXLargeDesktop: 325px);
$heroCarouselTileWithExtraPillPadding: 0;
$heroCarouselTileWithExtraPillImagePaddingTop: calc(255 / 268 * 100%);
$heroCarouselItemPadding: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 1.5rem 1.5rem 1.75rem);
$heroCarouselBorderRadius: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselBorder: (smallMobile: .9px solid #e6eaf2, mediumMobile: .9px solid #e6eaf2,
smallTablet: none, largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselImageAfterContent: none;
$heroCarouselBreakPointLeftImageWidths: (smallMobile: 420px, mediumMobile: 420px, smallTablet: 373px,
largeMobileAndSmallDesktop: 333px, largeTabletAndXLargeDesktop: 420px);
$heroCarouselOfferDetailsMinHeight: 10.5rem;
$heroCarouselOfferDetailsBeforeDisplay: none;
$heroCarouselOfferDetailsExclusionMibilePadding: 0 0 1rem .5rem;
$heroCarouselOfferDetailsTopPadding: (smallMobile: 1.5rem 0 0 .75rem, mediumMobile: 1.5rem 0 0 .75rem, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselBreakPointOfferDetailsPadding: (smallMobile: 13.6px 5px 13px 102px, mediumMobile: 10px 10px 10px 102px, smallTablet: .5rem 3rem 0 1.7rem,
largeMobileAndSmallDesktop: .5rem 3rem 0 1.9rem, largeTabletAndXLargeDesktop: .2rem 3rem 0 1.5rem);
$heroCarouselBreakPointOfferBottomDetailsContentMinHeights: (smallTablet: 9rem, largeMobileAndSmallDesktop: 7rem, largeTabletAndXLargeDesktop: 10.5rem);
$heroCarouselBreakPointOfferDetailsContentMinHeights: (smallTablet: 103px, largeMobileAndSmallDesktop: 86px, largeTabletAndXLargeDesktop: 112px);
$heroCarouselBreakPointOfferDetailsWidths: (smallMobile: (), mediumMobile: (), smallTablet: 374.85px,
largeMobileAndSmallDesktop: 331.86px, largeTabletAndXLargeDesktop: 470px);
$heroCarouselBreakPointOfferDetailsJustifyContent: (smallMobile: (), mediumMobile: (), smallTablet: space-between,
largeMobileAndSmallDesktop: space-between, largeTabletAndXLargeDesktop: space-between);
$heroCarouselBreakPointOfferDetailsContentPaddingBottom: (smallTablet: .5rem, largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselBreakPointRebateFontSize: (smallMobile: 1.19rem, mediumMobile: 1.19rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselBreakPointRebateColor: (smallMobile: $brandDarkGray3, mediumMobile: $brandDarkGray3, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselOldRebateElevationFontSize: (smallMobile: 1.19rem, mediumMobile: 1.19rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselOldRebateElevationColor: $brandDarkGray3;
$heroCarouselBreakPointRebateNewValueFontSize: (smallMobile: 1.19rem, mediumMobile: 1.19rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.5rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselBreakPointRebateNewValueFontWeight: (smallMobile: bold, mediumMobile: bold, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselBreakPointRebateNewValueLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselBreakPointRebateLineHeight: (smallMobile: 1.45rem, mediumMobile: 1.45rem, smallTablet: 2rem,
largeMobileAndSmallDesktop: 2.2rem, largeTabletAndXLargeDesktop: 2.7rem);
$heroCarouselBreakPointRebatePrefixColor: (smallMobile: (), mediumMobile: (), smallTablet: $rebateColor,
largeMobileAndSmallDesktop: $rebateColor, largeTabletAndXLargeDesktop: $rebateColor);
$heroCarouselBreakPointRebateFontWeight: (smallMobile: (), mediumMobile: (), smallTablet: 700,
largeMobileAndSmallDesktop: 700, largeTabletAndXLargeDesktop: 700);
$heroCarouselBreakPointRebateMarginBottom: (smallMobile: .4rem, mediumMobile: .4rem, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselCopyColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandPrimaryGrey,
largeMobileAndSmallDesktop: $brandPrimaryGrey, largeTabletAndXLargeDesktop: $brandPrimaryGrey);
$heroCarouselBreakPointLongCopyMarginTop: (smallMobile: 0, mediumMobile: 0, smallTablet: .6rem,
largeMobileAndSmallDesktop: .1rem, largeTabletAndXLargeDesktop: .6rem);
$heroCarouselBreakPointLongCopyFontSize: (smallMobile: 1.02rem, mediumMobile: 1.02rem, smallTablet: 1.7rem,
largeMobileAndSmallDesktop: 1.7rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselBreakPointLongCopyLineHeight: (smallMobile: 1.53rem, mediumMobile: 1.53rem, smallTablet: 2.7rem,
largeMobileAndSmallDesktop: 2.7rem, largeTabletAndXLargeDesktop: 2.4rem);
$heroCarouselBreakPointLongCopyMarginBottom: (smallMobile: .4rem, mediumMobile: .4rem, smallTablet: .5rem,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselBreakPointCouponCodeLineHeight: (smallMobile: 1.28rem, mediumMobile: 1.28rem, smallTablet: 1.6rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselBreakPointCouponCodeMargin: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: .8rem 0 0);
$heroCarouselCouponCodeLabelColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandBlack,
largeMobileAndSmallDesktop: $brandBlack, largeTabletAndXLargeDesktop: $brandBlack);
$heroCarouselCouponCodeTextLineHeight: 1.28rem;
$heroCarouselCouponCodeTextLetterSpacing: (smallMobile: .26px, mediumMobile: .26px, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselCouponCodeTextFontSize: (smallMobile: 1.02rem, mediumMobile: 1.02rem, smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.3rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselCouponCodeColor: (smallMobile: $brandBlue2, mediumMobile: $brandBlue2, smallTablet: $brandBlue2,
largeMobileAndSmallDesktop: $brandBlue2, largeTabletAndXLargeDesktop: $brandBlue2);
$heroCarouselExpireFontStyle: (smallMobile: normal, mediumMobile: normal, smallTablet: normal,
largeMobileAndSmallDesktop: normal, largeTabletAndXLargeDesktop: normal);
$heroCarouselDisclaimerFontSize: (smallMobile: 1.08rem, mediumMobile: 1.08rem, smallTablet: .8rem,
largeMobileAndSmallDesktop: .8rem, largeTabletAndXLargeDesktop: .8rem);
$heroCarouselDisclaimerLineHeight: 1.35rem;
$heroCarouselDisclaimerColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: #555,
largeMobileAndSmallDesktop: #555, largeTabletAndXLargeDesktop: #555);
$heroCarouselDisclaimerPadding: (smallMobile: 0 10rem 0 0, mediumMobile: 0 10rem 0 0, smallTablet: 0 10rem 0 0,
largeMobileAndSmallDesktop: 0 10rem 0 0, largeTabletAndXLargeDesktop: 0 11rem 0 0);
$heroCarouselDisclaimerBottomPosition: (smallMobile: 0, mediumMobile: 0, smallTablet: .7rem,
largeMobileAndSmallDesktop: .7rem, largeTabletAndXLargeDesktop: .7rem);
$heroCarouselButtonWidth: (smallMobile: 21rem, mediumMobile: 21rem, smallTablet: 20rem,
largeMobileAndSmallDesktop: 15rem, largeTabletAndXLargeDesktop: 20rem);
$heroCarouselButtonColor: (smallMobile: $brandWhite, mediumMobile: $brandWhite, smallTablet: $brandWhite,
largeMobileAndSmallDesktop: $brandWhite, largeTabletAndXLargeDesktop: $brandWhite);
$heroCarouselButtonFontWeight: 700;
$heroCarouselButtonFontSize: (smallMobile: (), mediumMobile: (), smallTablet: 1.5rem,
largeMobileAndSmallDesktop: 1.2rem, largeTabletAndXLargeDesktop: 1.6rem);
$heroCarouselButtonMarginTop: (smallMobile: 0, mediumMobile: 0, smallTablet: 1rem,
largeMobileAndSmallDesktop: .3rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselButtonTextTransform: capitalize;
$heroCarouselExtraRewardsPillWidth: 9.35rem;
$heroCarouselExtraRewardsPillPositionLeft: (smallMobile: -10.2rem, mediumMobile: -10.2rem);
$heroCarouselExtraRewardsPillPositionTop: (smallMobile: 3.49rem, mediumMobile: 3.49rem);
$heroCarouselExtraRewardsPillLabelFontSize: (smallMobile: .85rem, mediumMobile: .85rem, smallTablet: (),
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingPadding: (smallMobile: (), mediumMobile: (), smallTablet: 0,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingMargin: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselInfobarMarketingHeaderBorderRadius: (smallMobile: 0, mediumMobile: 0, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselInfobarMarketingHeaderFontSize: (smallMobile: 2.4rem, mediumMobile: 2.4rem, smallTablet: 2.2rem,
largeMobileAndSmallDesktop: 2.8rem, largeTabletAndXLargeDesktop: 3.5rem);
$heroCarouselInfobarMarketingHeaderLineHeight: (smallMobile: 3.2rem, mediumMobile: 3.2rem, smallTablet: 3.2rem,
largeMobileAndSmallDesktop: 3.2rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselInfobarMarketingHeaderColor: (smallMobile: $brandDarkBlue4, mediumMobile: $brandDarkBlue4, smallTablet: $brandPrimaryGrey,
largeMobileAndSmallDesktop: $brandPrimaryGrey, largeTabletAndXLargeDesktop: $brandPrimaryGrey);
$heroCarouselInfobarMarketingHeaderMargin: (smallMobile: 5.7rem 0 1.2rem, mediumMobile: 5.7rem 0 1.2rem, smallTablet: 1rem 0,
largeMobileAndSmallDesktop: 1rem 0, largeTabletAndXLargeDesktop: 1rem 0);
$heroCarouselInfobarMarketingHeaderLetterSpacing: (smallMobile: 0, mediumMobile: 0, smallTablet: -.5px,
largeMobileAndSmallDesktop: -.5px, largeTabletAndXLargeDesktop: -.5px);
$heroCarouselInfobarMarketingHeaderFontWeight: normal;
$heroCarouselInfobarMarketingHeaderPadding: (smallMobile: 1rem .5rem, mediumMobile: 1rem .5rem, smallTablet: 1.4rem .5rem,
largeMobileAndSmallDesktop: 1.4rem .5rem, largeTabletAndXLargeDesktop: 1.4rem .5rem);
$heroCarouselInfobarMarketingCountdownBackgrond: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandQuaternaryColor,
largeMobileAndSmallDesktop: $brandQuaternaryColor, largeTabletAndXLargeDesktop: $brandQuaternaryColor);
$heroCarouselInfobarMarketingCountdownLetterSpacing: (smallMobile: .81px, mediumMobile: .81px, smallTablet: 0,
largeMobileAndSmallDesktop: 0, largeTabletAndXLargeDesktop: 0);
$heroCarouselInfobarMarketingCountdownFontWeight: (smallMobile: bold, mediumMobile: bold, smallTablet: 500,
largeMobileAndSmallDesktop: 500, largeTabletAndXLargeDesktop: 500);
$heroCarouselInfobarMarketingCountdownPrefixTextPadding: (smallMobile: 0 1.5rem 0 0, mediumMobile: 0 1.5rem 0 0, smallTablet: 0 .7rem 0 0,
largeMobileAndSmallDesktop: 0 .7rem 0 0, largeTabletAndXLargeDesktop: 0 .7rem 0 0);
$heroCarouselInfobarMarketingCountdownPrefixTextFontSize: (smallMobile: .9rem, mediumMobile: .9rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.7rem, largeTabletAndXLargeDesktop: 1.7rem);
$heroCarouselInfobarMarketingCountdownPrefixTextFontWeight: (smallMobile: bold, mediumMobile: bold, smallTablet: bold,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingCountdownCounterFontSize: (smallMobile: 1.3rem, mediumMobile: 1.3rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.8rem, largeTabletAndXLargeDesktop: 1.8rem);
$heroCarouselInfobarMarketingCountdownCounterFontWeight: (smallMobile: bold, mediumMobile: bold, smallTablet: 700,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingCountdownCounterLetterSpacing: (smallMobile: .52px, mediumMobile: .52px, smallTablet: .72px,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingCountdownCounterAfterFontSize: (smallMobile: 1rem, mediumMobile: 1rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.7rem, largeTabletAndXLargeDesktop: 1.7rem);
$heroCarouselInfobarMarketingCountdownCounterAfterFontWeight: (smallMobile: bold, mediumMobile: bold, smallTablet: 700,
largeMobileAndSmallDesktop: (), largeTabletAndXLargeDesktop: ());
$heroCarouselInfobarMarketingCountdownCounterAfterPadding: (smallMobile: 0 0 0 .2rem, mediumMobile: 0 0 0 .2rem,
smallTablet: 0 0 0 .3rem, largeMobileAndSmallDesktop: 0 0 0 .3rem, largeTabletAndXLargeDesktop: 0 0 0 .3rem);
$heroCarouselInfobarMarketingCountdownCounterBorderRight: 1px solid #9da6ab;
$heroCarouselInfobarMarketingCountdownCounterBeforeMargin: 0 .5rem 0 1rem;
$heroCarouselInfobarMarketingTextJustifyContent: (smallMobile: flex-start, mediumMobile: flex-start, smallTablet: center,
largeMobileAndSmallDesktop: center, largeTabletAndXLargeDesktop: center);
$heroCarouselInfobarMarketingTextFontSize: (smallMobile: 1.3rem, mediumMobile: 1.3rem, smallTablet: 1.8rem,
largeMobileAndSmallDesktop: 1.8rem, largeTabletAndXLargeDesktop: 2rem);
$heroCarouselInfobarMarketingTextLineHeight: (smallMobile: 2.3rem, mediumMobile: 2.3rem, smallTablet: 2.8rem,
largeMobileAndSmallDesktop: 2.6rem, largeTabletAndXLargeDesktop: 3.2rem);
$heroCarouselInfobarMarketingTextColor: (smallMobile: $brandTextColor, mediumMobile: $brandTextColor, smallTablet: $brandDarkGray2,
largeMobileAndSmallDesktop: $brandDarkGray2, largeTabletAndXLargeDesktop: $brandDarkGray2);
$heroCarouselInfobarMarketingTextPadding: (smallMobile: 0 2.9rem 0 2.5rem, mediumMobile: 0 2.9rem 0 2.5rem, smallTablet: 0 1.5rem,
largeMobileAndSmallDesktop: 0 1.5rem, largeTabletAndXLargeDesktop: 0 1.5rem);
$heroCarouselInfobarMarketingCTAWidth: (smallMobile: 21rem, mediumMobile: 21rem, smallTablet: 26rem,
largeMobileAndSmallDesktop: 26rem, largeTabletAndXLargeDesktop: 26rem);
$heroCarouselInfobarMarketingCTAMargin: (smallMobile: 0 0 6.2rem, mediumMobile: 0 0 6.2rem, smallTablet: 0 0 2rem,
largeMobileAndSmallDesktop: 0 0 2rem, largeTabletAndXLargeDesktop: 0 0 2rem);
$heroCarouselInfobarMarketingCTAFontSize: (smallMobile: 1.3rem, mediumMobile: 1.3rem, smallTablet: 1.4rem,
largeMobileAndSmallDesktop: 1.4rem, largeTabletAndXLargeDesktop: 1.4rem);
$heroCarouselInfobarMarketingCTALineHeight: 1.9rem;
$heroCarouselInfobarMarketingCTABorder: none;
$heroCarouselInfobarMarketingCTAColor: $brandWhite;
$heroCarouselInfobarMarketingCTAPadding: (smallMobile: 1.65rem 0, mediumMobile: 1.65rem 0, smallTablet: 1.2rem 0,
largeMobileAndSmallDesktop: 1.2rem 0, largeTabletAndXLargeDesktop: 1.2rem 0);
$heroCarouselInfobarMarketingCTAFontWeight: bold;
$heroCarouselSliderCounterWrapperPosition: (smallMobile: (), mediumMobile: (), smallTablet: absolute,
largeMobileAndSmallDesktop: absolute, largeTabletAndXLargeDesktop: absolute);
$heroCarouselSliderCounterWrapperPositionBottom: 2rem;
$heroCarouselSliderCounterWrapperPositionRight: 1.5rem;
$heroCarouselDotsPositionBottom: (smallTablet: 2rem, largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 3.5rem);
$heroCarouselDotsPositionRight: (smallTablet: 1.5rem, largeMobileAndSmallDesktop: 2rem, largeTabletAndXLargeDesktop: 2.5rem);
$heroCarouselDotMargin: (smallTablet: 0 0 .5rem .5rem, largeMobileAndSmallDesktop: 0 .5rem .5rem, largeTabletAndXLargeDesktop: 0 0 .5rem .9rem);
$heroCarouselBreakPointDotWidth: (smallTablet: 1rem, largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: 1.4rem);
$heroCarouselBreakPointDotHeight: (smallTablet: 1rem, largeMobileAndSmallDesktop: 1rem, largeTabletAndXLargeDesktop: 1.4rem);
$heroCarouselBreakPointDotBackground: (smallTablet: #9a9ea7, largeMobileAndSmallDesktop: #9a9ea7, largeTabletAndXLargeDesktop: #9a9ea7);
$heroCarouselBreakPointActiveDotBackground: (smallTablet: $brandPrimaryGrey, largeMobileAndSmallDesktop: $brandPrimaryGrey, largeTabletAndXLargeDesktop: $brandPrimaryGrey);
$heroCarouselBreakPointHoverDotBackground: (smallTablet: $brandPrimaryGrey, largeMobileAndSmallDesktop: $brandPrimaryGrey, largeTabletAndXLargeDesktop: $brandPrimaryGrey);
$heroCarouselArrowColor: #818a98;
$heroCarouselArrowFontSize: 3.5rem;

/* Info Bar Marketing */
$infoBarMarketingContentTextAlign: (small: center, xlarge: left);
$infoBarMarketingContentPadding: (small: 0);
$infoBarMarketingTileHeight: (small: 100%, large: 23.4rem, xlarge: 32.5rem);
$infoBarMarketingCountdownWrapBackground: $brandQuaternaryColor;
$infoBarMarketingCountdownWrapWidth: (small: 100%);
$infoBarMarketingCountdownWrapPadding: (small: .8rem .5rem, xlarge: 1.4rem .2rem);
$infoBarMarketingCountdownClockPrefixLetterSpacing: 0;
$infoBarMarketingCountdownClockPrefixFontWeight: 700;
$infoBarMarketingCountdownDigitBeforeDisplay: inline-block;
$infoBarMarketingCountdownDigitBeforeBorderRight: 1px solid #9da6ab;
$infoBarMarketingCountdownDigitBeforeHeight: 2.3rem;
$infoBarMarketingCountdownDigitBeforePadding: 0 0 0 .7rem;
$infoBarMarketingCountdownDigitBeforeMargin: 0 .5rem -.5rem 0;
$infoBarMarketingHeaderMargin: (small: 1rem .5rem, xlarge: 1.5rem 0);
$infoBarMarketingHeaderLetterSpacing: -.56px;
$infoBarMarketingHeaderColor: $brandPrimaryGrey;
$infoBarMarketingHeaderFontWeight: 400;
$infoBarMarketingHeaderFontSize: (small: 2rem, xlarge: 3rem);
$infoBarMarketingHeaderLineHeight: (small: 2.4rem, xlarge: 3.2rem);
$infoBarMarketingTextPadding: 0 2rem;
$infoBarMarketingTextColor: $brandDarkGray2;
$infoBarMarketingTextFontSize: (small: 1.4rem, xlarge: 1.5rem);
$infoBarMarketingTextLineHeight: (small: 1.8rem, xlarge: 2.2rem);
$infoBarMarketingButtonWidth: 26rem;
$infoBarMarketingButtonMargin: (medium: 0 auto 1rem);
$infoBarMarketingButtonPadding: 1.2rem;
$infoBarMarketingButtonFontSize: 1.4rem;
$infoBarMarketingButtonFontWeight: 700;

/* Info Bar Accordion */
$infoBarAccordionHeight: (small: 11rem, large: 9.2rem);
$infoBarAccordionDotwLabelHeight: 2.2rem;
$infoBarAccordionDotwLabelWidth: 10.1rem;
$infoBarAccordionDotwLabelTop: -2.2rem;
$infoBarAccordionDotwLabelFontSize: 1.1rem;
$infoBarAccordionDotwLabelLineHeight: 2.2rem;
$infoBarAccordionDotwLabelBackground: $brandLinkColor;
$infoBarAccordionDotwLabelBorderRadius: 0;
$infoBarAccordionDotwLabelTextTransform: none;
$infoBarAccordionDotwLabelLetterSpacing: 0;
$infoBarAccordionOfferBoxShadow: 2px 2px 0 2px $brandDarkBlue2;
$infoBarAccordionColor: $brandDarkGray2;
$infoBarAccordionOfferLogoPadding: (medium: .5rem .5rem .5rem 0);
$infoBarAccordionOfferLogoMarginRight: 0;
$infoBarAccordionOfferTextFontSize: (medium: 1.8rem);
$infoBarAccordionOfferTextLineHeight: (medium: 1.8rem);
$infoBarAccordionOfferTextMargin: (medium: 0 0 1rem);
$infoBarAccordionOfferCouponCodeAndRebateColor: $brandDarkGray2;
$infoBarAccordionOfferCouponCodeAndRebateCodeColor: $brandPrimaryBlue2;
$infoBarAccordionOfferCouponCodeAndRebateDividerMargin: 0 1rem -.3rem;
$infoBarAccordionOfferCouponCodeAndRebateDividerHeight: 1.4rem;
$infoBarAccordionOfferCouponCodeAndRebateDividerBorderRight: 1px solid $brandDarkGray2;
$infoBarAccordionOfferRebateColor: $brandPrimaryBlue2;
$infoBarAccordionOfferRebateLineHeight: 1.8rem;
$infoBarAccordionOfferShortSummaryColor: $brandDarkGray2;
$infoBarAccordionOfferShortSummaryFontSize: (small: 1.2rem, medium: 1.2rem, large: 1.2rem, xxlarge: 1.2rem);
$infoBarAccordionOfferShortSummaryMargin: 0;
$infoBarAccordionCollapsedOfferRebateDisplay: inline;
$infoBarAccordionCollapsedOfferRebateOldValueFontSize: (small: 1.4rem);
$infoBarAccordionCollapsedOfferRebateNewValueFontSize: (small: 1.4rem);
$infoBarAccordionCollapsedOfferRebateNewValueWhiteSpace: normal;
$infoBarAccordionCollapsedOfferRebateFontSize: (small: 1.4rem);
$infoBarAccordionCollapsedOfferRebateValueFontSize: (small: 1.4rem);
$infoBarAccordionExpandIconBeforeContent: '+';
$infoBarAccordionExpandIconBeforeColor: $brandLightGray2;
$infoBarAccordionExpandIconBeforeBackground: #a4a7ae;
$infoBarAccordionExpandIconBeforeWidth: 1.7rem;
$infoBarAccordionExpandIconBeforeHeight: 1.7rem;
$infoBarAccordionExpandIconBeforeDisplay: block;
$infoBarAccordionExpandIconBeforeTextAlign: center;
$infoBarAccordionExpandIconBeforePadding: ();
$infoBarAccordionExpandIconBeforeFontSize: 1.6rem;
$infoBarAccordionExpandIconBeforeLineHeight: 1.7rem;
$infoBarAccordionSecondOfferPlusSignDisplay: inline;
$infoBarAccordionSecondOfferPlusSignFontSize: 1.5rem;
$infoBarAccordionSecondOfferPlusSignFontWeight: 700;
$infoBarAccordionSecondOfferPlusSignPadding: 0 .5rem 0 0;
$infoBarAccordionSecondOfferPlusSignColor: #0078d2;
$infoBarAccordionSecondOfferBorderLeft: 4px solid $brandDarkBlue2;
$infoBarAccordionOfferCTAHoverTextDecoration: underline;
$infoBarAccordionOfferCTABackground: $brandLightColor;
$infoBarAccordionSecondOfferCTABackground: $brandLightColor;
$infoBarAccordionSecondOfferCTABoxShadow: none;
$infoBarAccordionOfferCTAWidth: 18rem;
$infoBarAccordionOfferCTAColor: $brandHeaderColor;
$infoBarAccordionOfferCTATextFontSize: 1.4rem;
$infoBarAccordionOfferCTATextFontWeight: 700;
$infoBarAccordionOfferCTALetterSpacing: 0;
$infoBarAccordionOfferCTATextAfterContent: '\f105';
$infoBarAccordionOfferCTATextAfterFontFamily: 'fontello';
$infoBarAccordionOfferCTATextAfterFontSize: 1.4rem;
$infoBarAccordionOfferRebateValueTieredPrefixTextTransform: lowercase;

/* Top Stores */
$topStoresMerchantTileListSlickSlideMargin: 0 .9rem .4rem 0;
$topStoresMerchantTileHeight: 15.5rem;
$topStoresMerchantTileWidth: 12rem;
$topStoresMerchantTileBoxShadow: 2px 2px 1px rgba(0, 0, 0, .08);
$topStoresMerchantTileBorder: 1px solid #f5f7f7;
$topStoresMerchantTileRebateHeight: 7.2rem;
$topStoresMerchantTileRebatePadding: 1rem 0 0;
$topStoresMerchantTileRebateElevationOldFontSize: 1.1rem;
$topStoresMerchantTileRebateElevationOldLineHeight: 1.3rem;
$topStoresCarouselDotsButtonActiveBackground: $brandWhite;
$topStoresCarouselDotsButtonBackground: rgba(233, 233, 233, .2);
$topStoresCarouselDotsButtonOpacity: 1;
$topStoresCarouselArrowPositionTop: 40%;
$topStoresCarouselArrowBeforeColor: #9a9ea7;
$topStoresCarouselArrowBeforeFontSize: 3.5rem;
$topStoresCarouselArrowPrevPositionLeft: -3rem;
$topStoresCarouselArrowNextPositionRight: -3.5rem;

/* Info Bar Slider */
$infoBarSliderOfferBackground: $brandBlue5;
$infoBarSliderOfferDOTWBackground: $brandDarkBlue4;
$infoBarSliderOfferPadding: 0 1rem;
$infoBarSliderOfferBorderRadius: 4px 4px 0 0;
$infoBarSliderOfferLabelFontSize: 1.4rem;
$infoBarSliderOfferLabelLineHeight: 2rem;
$infoBarSliderOfferLabelTextAlign: center;
$infoBarSliderOfferLabelLetterSpacing: 1px;
$infoBarSliderOfferLabelPadding: 1.2rem 0 .2rem;
$infoBarSliderOfferWrapBorder: 1px solid $brandLightGray9;
$infoBarSliderOfferWrapMargin: 1rem 1rem 2.1rem;
$infoBarSliderOverlayLinkPadding: 1.8rem;
$infoBarSliderOfferPillBottom: 6.7rem;
$infoBarSliderOfferPillWidth: 11rem;
$infoBarSliderMerchantLogoMargin: 1rem auto 1.4rem auto;
$infoBarSliderFavoriteIconTop: .7rem;
$infoBarSliderFavoriteIconWidth: 1.5rem;
$infoBarSliderFavoriteIconHeight: 1.3rem;
$infoBarSliderOfferRolloverCopyLineHeight: 1.8rem;
$infoBarSliderOfferRolloverCopyMargin: 0 0 1.1rem 0;
$infoBarSliderOfferItemCopyFontSize: 1.2rem;
$infoBarSliderOfferItemCopyTextAlign: center;
$infoBarSliderOfferCouponCodeWrapAlignItems: center;
$infoBarSliderOfferCouponCodeLabelFontSize: 1.2rem;
$infoBarSliderOfferCouponCodeLabelLineHeight: 1.5rem;
$infoBarSliderOfferCouponCodeLabelColor: $brandTextColor;
$infoBarSliderOfferCouponCodeFontSize: 1.2rem;
$infoBarSliderOfferCouponCodeLetterSpacing: .3px;
$infoBarSliderOfferCouponCodeColor: $brandBlue2;
$infoBarSliderOfferItemExpireTop: 64%;
$infoBarSliderOfferItemExpireColor: $brandTextColor;
$infoBarSliderOfferItemExpireTextAlign: center;
$infoBarSliderOfferItemExpireLeft: 0;
$infoBarSliderOfferItemExpireWidth: 100%;
$infoBarSliderOfferRebateHeight: 7.4rem;
$infoBarSliderOfferRebateBottom: .3rem;
$infoBarSliderOfferRebateBackground: $brandLightColor;
$infoBarSliderOfferRebateBlockPadding: 1.3rem;

/* Marketing Merchants Home */
$marketingMerchantsHomeTileWidth: (small: 22.7rem, medium: 12rem);
$marketingMerchantsHomeTileHeight: (small: 22.3rem, medium: 15.5rem);
$marketingMerchantsHomeTileBoxShadow: 2px 2px 1px rgba(0, 0, 0, .08);
$marketingMerchantsHomeTileBorder: 1px solid #f5f7f7;
$marketingMerchantsHomeMerchantTileRebateElevationOldFontSize: 1.1rem;
$marketingMerchantsHomeMerchantTileRebateElevationOldLineHeight: 1.3rem;
$marketingMerchantsHomeMerchantTileRebateElevationNewFontSize: 1.2rem;
$marketingMerchantsHomeMerchantTileRebateElevationNewLineHeight: 1.4rem;
$marketingMerchantsHomeMerchantTileRebateHeight: 7.2rem;
$marketingMerchantsHomePillPositionTop: (small: 62%, medium: 49%);
$marketingMerchantsHomeViewAllLinkInsideSliderBackground: $brandDarkBlue4;
$marketingMerchantsHomeViewAllLinkInsideSliderBorder: 1px solid #e6eaf2;
$marketingMerchantsHomeViewAllLinkInsideSliderColor: $brandWhite;
$marketingMerchantsHomeViewAllLinkInsideSliderFontSize: 1.8rem;
$marketingMerchantsHomeViewAllLinkInsideSliderFontWeight: bold;
$marketingMerchantsHomeViewAllLinkInsideSliderLineHeight: 2.1rem;
$marketingMerchantsHomeViewAllLinkInsideSliderTextTransform: capitalize;
$marketingMerchantsHomeViewAllLinkHoverTextDecoration: none;

/* Top Stores Expandable */
$topStoresExpandableBackground: $brandDarkBlue4;
$topStoresExpandableTextColor: $brandWhite;
$topStoresExpandableMerchantTileListJustifyContent: space-evenly;
$topStoresExpandableMerchantTileBorder: 2px solid #e6eaf2;
$topStoresExpandableMerchantTileMargin: 0 0 6.4rem;
$topStoresExpandableMerchantTilePositionBottom: 0;
$topStoresExpandableMerchantTilePositionLeft: -.3rem;
$topStoresExpandableMerchantTilePositionRight: -.3rem;
$topStoresExpandableMerchantFavoriteIconWidth: 1.7rem;
$topStoresExpandableMerchantFavoriteIconHeight: 1.5rem;
$topStoresExpandableMerchantFavoriteIconPositionTop: 2rem;
$topStoresExpandableMerchantTileImgPositionTop: 48%;
$topStoresExpandableMerchantTileMerchantRebateV2Background: none;
$topStoresExpandableMerchantPillWidth: 100%;
$topStoresExpandableButtonContainerWidth: 86.5%;
$topStoresExpandableButtonContainerMargin: .9rem auto 0;
$topStoresExpandableButtonPadding: 1.5rem 2rem 1.1rem;
$topStoresExpandableButtonLineHeight: 2.4rem;
$topStoresExpandableButtonFontWeight: bold;

/* Personalized Top Offers */
$personalizedTopOffersButtonColor: $brandWhite;
$personalizedTopOffersButtonPadding: 1.5rem 0 1.1rem;
$personalizedTopOffersButtonLineHeight: 2.4rem;
$personalizedTopOffersButtonWidth: 95%;

/* Featured Deals Mobile Rounded */
$featuredDealMobileRoundedLinkPadding: 2rem 0 5rem;
$featuredDealMobileRoundedMerchantLogoWrapMinWidth: 8.1rem;
$featuredDealMobileRoundedMerchantLogoWrapHeight: 8.1rem;
$featuredDealMobileRoundedContentMargin: 1rem 0 0 1.5rem;
$featuredDealMobileRoundedContentFontSize: 1.2rem;
$featuredDealMobileRoundedContentLineHeight: 1.8rem;
$featuredDealMobileRoundedMerchantNameMargin: 0 0 1rem;
$featuredDealMobileRoundedMerchantNameFontSize: 1.8rem;
$featuredDealMobileRoundedMerchantNameLineHeight: 2.1rem;
$featuredDealMobileRoundedMerchantNameColor: $brandDarkBlue4;
$featuredDealMobileRoundedRebateFontSize: 1.2rem;
$featuredDealMobileRoundedRebateLineHeight: 1.5rem;
$featuredDealMobileRoundedRebateValueFontSize: 1.2rem;
$featuredDealMobileRoundedRebatePrefixFontSize: 1.2rem;
$featuredDealMobileRoundedRebateNewValueFontSize: 1.2rem;
$featuredDealMobileRoundedCouponCodeWrapMargin: .8rem 0 0;
$featuredDealMobileRoundedCouponCodeWrapLineHeight: 1.5rem;
$featuredDealMobileRoundedCouponCodeLabelFontSize: 1.2rem;
$featuredDealMobileRoundedCouponCodeFontSize: 1.2rem;
$featuredDealMobileRoundedCouponCodeLetterSpacing: .3px;
$featuredDealMobileRoundedCouponCodeColor: $brandBlue2;

/* Featured Deals Desktop */
$featuredDealDesktopWidth: 24rem;
$featuredDealDesktopBorder: 1px solid $brandLightGray2;
$featuredDealDesktopBoxShadow: 0 2px 4px 0 rgba(255, 255, 255, .5);
$featuredDealDesktopOverlayLinkPadding: 2.5rem 1.5rem 7rem 1.5rem;
$featuredDealDesktopFavoriteIconWidth: 2rem;
$featuredDealDesktopFavoriteIconHeight: 2rem;
$featuredDealDesktopFavoriteIconWrapPositionTop: .5rem;
$featuredDealDesktopMerchantLogoMargin: 0 auto 2rem auto;
$featuredDealDesktopOfferDetailsFontSize: 1.2rem;
$featuredDealDesktopCouponCodeLabelFontSize: 1.2rem;
$featuredDealDesktopCouponCodeLineHeight: 1.8rem;
$featuredDealDesktopCouponCodeLetterSpacing: 0;
$featuredDealDesktopCouponCodeColor: $brandPrimaryBlue2;
$featuredDealDesktopExpireFontSize: 1.2rem;
$featuredDealDesktopPillWidth: 12rem;
$featuredDealDesktopPillBottom: 6.5rem;
$featuredDealDesktopPillHeight: 2.4rem;
$featuredDealDesktopPillLabelFontSize: 1.2rem;
$featuredDealDesktopPillLabelLineHeight: 2.4rem;
$featuredDealDesktopRebateHeight: 7.8rem;
$featuredDealDesktopRebateBackground: $brandLightColor;
$featuredDealDesktopRebateMargin: 0 .3rem .3rem .3rem;
$featuredDealDesktopRebateOldValueFontSize: 1.2rem;

/* Explore Categories */
$exploreCategoriesSectionPadding: (small: 4rem 0 6rem);
$exploreCategoriesSectionBackground: (small: $brandDarkBlue5);
$exploreCategoriesTitleDotDisplay: none;
$exploreCategoriesListPadding: 0 3.7rem;
$exploreCategoriesListGap: 3rem;
$exploreCategoriesItemLinkBackground: #0092ae;
$exploreCategoriesItemLinkPadding: 0 6rem 0 0;
$exploreCategoriesItemLinkFontSize: 1.4rem;
$exploreCategoriesItemLinkLineHeight: 2rem;
$exploreCategoriesIconMargin: 0 1.6rem 0 2.2rem;
$exploreCategoriesIconBeforeFontFamily: 'usaa_categories';
$exploreCategoriesIconBeforeColor: #486a7b;
$exploreCategoriesIconBeforeDepartmentStoresPositionTop: 29%;
$exploreCategoriesIconBeforeDepartmentStoresPositionLeft: 16%;
$exploreCategoriesIconBeforePositionTop: 29%;
$exploreCategoriesIconBeforeDepartmentStoresContent: '\e815';
$exploreCategoriesIconBeforeComputersElectronicContent: '\e804';
$exploreCategoriesIconBeforeWomensClothingContent: '\e81b';
$exploreCategoriesIconBeforeMensClothingContent: '\e812';
$exploreCategoriesIconBeforeBeautyScincareContent: '\e802';
$exploreCategoriesIconBeforeShoesContent: '\e814';
$exploreCategoriesIconBeforeSportsOutdoorsContent: '\e817';
$exploreCategoriesIconBeforeFlowersGiftsContent: '\e807';
$exploreCategoriesIconBeforeBabiesKidsToysContent: '\e801';
$exploreCategoriesIconBeforeHomeKitchenContent: '\e80e';
$exploreCategoriesIconBeforeHealthWellnessContent: '\e80d';
$exploreCategoriesIconBeforeToolsAutoPetsContent: '\e818';

/* Featured Deals */
$featuredDealsButtonMargin: 5rem 1rem 2rem;
$featuredDealsButtonPadding: 1.5rem 0 1.1rem;
$featuredDealsButtonLineHeight: 2.4rem;
$featuredDealsButtonWidth: 95%;

/* Popular Offers */
$popularOffersBackground: (small: $brandBlue2, medium: $brandWhite);
$popularOffersSectionTitleH2Color: (small: $brandWhite, medium: $brandDarkBlue2);
$popularOfferBorder: (small: 1px solid $brandLightGray9, medium: 1px solid $brandLightGray2);
$popularOfferImageBorder: (small: 1px solid $brandWhite);
$popularOfferImageBorderRadius: 0;
$popularOfferImageBorderWidth: 3px 3px 0 3px;
$popularOfferRebateBorder: (small: 1px solid $brandWhite);
$popularOfferRebateBorderRadius: 0;
$popularOfferRebateBorderWidth: 0 3px 3px 3px;
$popularOfferRebateBackground: (small: $brandLightGray7);
$popularOfferRebateColor: $brandPrimaryBlue2;
$popularOfferRebateTextTransform: lowercase;
$popularOfferRebateFontSize: (small: 1.5rem, medium: 1.5rem);
$popularOfferRebateFontWeight: (small: bold);
$popularOfferRebateValueAndPrefixColor: $brandPrimaryBlue2;
$popularOfferRebateValueAndPrefixFontWeight: (small: bold);
$popularOfferRebateOldValueColor: $brandSecondaryColor;
$popularOfferRebateOldValueBorderTop: 2px solid $brandSecondaryColor;
$popularOfferRebateOldValueBeforeTransform: rotate(-30deg);
$popularOfferRebateOldValueBorderBoxShadow: 0 0 0 1px $brandWhite;
$popularOfferMerchantRebateTextTransform: capitalize;

/* Bonus Offers */
$bonusMerchantOfferBorder: 1px solid #dfdddb;
$bonusMerchantOfferBorderRadius: 3px;
$bonusMerchantOfferBonusTagWrapBackground: #bad34e;
$bonusMerchantOfferBonusTagWrapColor: $brandDarkBlue4;
$bonusMerchantOfferBonusTagWrapFontWeight: normal;
$bonusMerchantOfferBonusTagWrapTextTransform: capitalize;
$bonusMerchantOfferBottomWrapBackground: $brandDarkBlue4;
$bonusMerchantOfferBottomWrapPadding: 1.3rem;
$bonusMerchantOfferHeadingFontSize: 2.4rem;
$bonusMerchantOfferHeadingFontWeight: normal;
$bonusMerchantOfferInfoFontSize: 1.6rem;
$bonusMerchantOfferInfoLineHeight: 2.4rem;
$bonusMerchantOfferSeeDetailsFontSize: 1.2rem;
$bonusMerchantOfferSeeDetailsTextTransform: capitalize;
$bonusMerchantOffers300x250Margin: 0 0 4rem;
$bonusMerchantOffers300x250TitleH2Margin: 0;

/* Testimonials */
$testimonialsSectionBackground: (small: $brandDarkBlue4);
$testimonialsIconHeight: 5.5rem;
$testimonialsIconWidth: 5.5rem;
$testimonialsIconBeforeBackground: no-repeat center/100% url('../assets/icons/account_icon.svg');
$testimonialsIconBeforeBackgroundSize: contain;
$testimonialsIconBeforeWidth: 100%;
$testimonialsIconBeforeHeight: 100%;
$testimonialsTextColor: $brandWhite;
$testimonialsStaticItemBorderBottom: 1px solid rgba(186, 197, 210, .25);
$testimonialsStaticSectionH2FontSize: (small: 2.4rem);
$testimonialsStaticSectionH2LineHeight: (small: 3.4rem);
$testimonialsStaticItemsPadding: 0;
$testimonialsCarouselDotWidth: 1rem;
$testimonialsCarouselDotHeight: 1rem;
$testimonialsCarouselDotBackground: $brandLightGray;
$testimonialsCarouselDotActiveBackground: $brandLightBlue2;
$testimonialsCarouselSectionTitleH2FontSize: (small: 3rem);
$testimonialsCarouselSectionTitleH2LineHeight: (small: 4rem);
$testimonialsCarouselDotsColumnGap: 1.5rem;
$testimonialsCarouselIconBeforeBackground: url('../assets/icons/testimonials_icon_light.png') 50% 50% / 2.8rem 3.6rem no-repeat;
$testimonialsCarouselItemsPadding: 0;
$testimonialsCarouselItemsMargin: (small: 0 auto);
$testimonialsCarouselItemsMaxWidth: (small: 90rem);

/* Trending and Seasonal Offers */
$trendingAndSeasonalOffersBackground: (small: $brandLightColor, medium: $brandWhite);

/* Pick Favorites */
$pickFavoritesBackground: #e6f1fa;
$pickFavoritesMerchantListGap: 3rem;
$pickFavoritesMerchantLogoTileBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .4);
$pickFavoritesMerchantLogoTileFavoriteIconWidth: 1.2rem;
$pickFavoritesMerchantLogoTileFavoriteIconHeight: 1.1rem;
$pickFavoritesButtonPadding: 1.5rem 1.1rem;
$pickFavoritesButtonLineHeight: 2.4rem;
$pickFavoritesButtonFontWeight: bold;

/* Email Opt In */
$emailOptInWrapperSectionBackground: (small: #e6f1fa);
$emailOptInWrapperOptedInBackground: #e6f1fa;
$emailOptInWrapperSectionPadding: (small: 6rem 3rem 8.4rem);
$emailOptInWrapperHeight: 55rem;
$emailOptInImageBackground: url('~org/assets/onboarding/email_optin.png') no-repeat 50% 0/contain;
$emailOptInImageWidth: 15.5rem;
$emailOptInImageHeight: 15.5rem;
$emailOptInTextLineHeight: 2.8rem;
$emailOptInHeadingFontSize: 2.5rem;
$emailOptInHeadingLineHeight: 3.2rem;
$emailOptInHeadingColor: $brandDarkBlue4;
$emailOptInHeadingDotDisplay: none;
$emailOptInHeadingSpanTextTransform: lowercase;
$emailOptInSwitcherWrapperPositionBottom: 8.4rem;
$emailOptInSwitcherWrapperWidth: 25.9rem;
$emailOptInSwitchWrapperLabelColumnGap: 1.5rem;
$emailOptInSwitcherLabelTextLineHeight: 2.8rem;

/* Earnings Snapshot */
$earningsSnapshotSectionBackground: (small: $brandDarkBlue5);
$earningsSnapshotSummaryTextAlign: center;
$earningsSnapshotSummaryFontWeight: normal;
$earningsSnapshotSummaryLineHeight: 2.8rem;
$earningsSnapshotSummaryMargin: 0 0 2rem;
$earningsSnapshotTransactionColor: $brandTextColor;
$earningsSnapshotTransactionsMargin: 0 0 1rem;
$earningsSnapshotTransactionBorder: 1px solid #bac5d2;
$earningsSnapshotTransactionPadding: 1.1rem 2.8rem .9rem;
$earningsSnapshotTransactionMerchantColor: $brandBlue2;
$earningsSnapshotTransactionMerchantFontSize: 1.4rem;
$earningsSnapshotTransactionMerchantFontWeight: bold;
$earningsSnapshotTransactionMerchantLineHeight: 2rem;
$earningsSnapshotTransactionInfoFontSize: 1.4rem;
$earningsSnapshotTransactionInfoTopFontWeight: normal;
$earningsSnapshotTransactionInfoTopLineHeight: 2rem;
$earningsSnapshotTransactionInfoBottomFontWeight: bold;
$earningsSnapshotTransactionInfoBottomLineHeight: 2rem;
$earningsSnapshotButtonBorder: 1px solid $brandWhite;
$earningsSnapshotButtonPadding: 1.5rem 0 1.1rem;
$earningsSnapshotButtonFontWeight: bold;
$earningsSnapshotButtonTextTransform: none;

/* For You Because You Shopped */
$becauseYouShoppedH2DotDisplay: inline;

/* Sticky Footer Mobile */
$stickyFooterMobileBackground: $brandDarkBlue5;
$stickyFooterMobileContentBorderTop: none;
$stickyFooterMobileContentPadding: 2.1rem 0 6.9rem;
$stickyFooterMobileImageWrapPadding: .2rem;
$stickyFooterMobileImageWrapBorder: 2px solid #e8ecf2;
$stickyFooterMobileImageWrapBorderRadius: 1px;
$stickyFooterMobileImageWrapBoxShadow: 1px 1px 2px 0 rgba(0, 0, 0, .2);
$stickyFooterMobileImageBorderRadius: 0;
$stickyFooterMobileMerchantInfoPadding: 0 0 0 2.2rem;
$stickyFooterMobileMerchantNameFontSize: 1.8rem;
$stickyFooterMobileMerchantNameLineHeight: 2.1rem;
$stickyFooterMobileMerchantNameMargin: .7rem 0 1rem;
$stickyFooterMobileMerchantRebateMargin: 0 0 .8rem 0;
$stickyFooterMobileMerchantRebateFontSize: 1.2rem;
$stickyFooterMobileMerchantRebateLineHeight: 1.5rem;
$stickyFooterMobileMerchantRebateNewValueFontSize: 1.2rem;
$stickyFooterMobileRolloverCopyFontSize: 1.2rem;
$stickyFooterMobileRolloverCopyLineHeight: 1.8rem;
$stickyFooterMobileRolloverCopyMargin: 0 0 .8rem 0;
$stickyFooterMobileUseCodeFontSize: 1.2rem;
$stickyFooterMobileUseCodeLineHeight: 1.5rem;
$stickyFooterMobileCodeFontSize: 1.2rem;
$stickyFooterMobileCodeLetterSpacing: .3px;
$stickyFooterMobileShopNowMargin: 3.2rem 1.75rem 0 0;
$stickyFooterMobileShopNowTextAlign: right;
$stickyFooterMobileShopNowFontSize: 1.4rem;
$stickyFooterMobileShopNowLineHeight: 2rem;
$stickyFooterMobileShopNowTextTransform: capitalize;
$stickyFooterMobileShopNowAfterContent: '\f105';
$stickyFooterMobileShopNowAfterFontFamily: 'fontello', serif;
$stickyFooterMobileShopNowAfterFontSize: 2rem;
$stickyFooterMobileShopNowAfterFontWeight: 400;
$stickyFooterMobileShopNowAfterMargin: 0 0 .3rem .7rem;
$stickyFooterMobileContentBorderBottom: 1px solid rgba(186, 197, 210, .25);

/* Sticky Footer Desktop */
$stickyFooterDesktopWrapBackground: $brandTertiaryColor;
$stickyFooterDesktopContentJustifyContent: space-between;
$stickyFooterDesktopControlPositionTop: -2.6rem;
$stickyFooterDesktopControlWidth: 3.2rem;
$stickyFooterDesktopControlBoxShadow: 0 -3px 3px rgba(0, 0, 0, .15);
$stickyFooterDesktopControlColor: $brandBluishGray;
$stickyFooterDesktopControlBeforeContent: '\2715';
$stickyFooterDesktopControlBeforeFontFamily: 'fontello';
$stickyFooterDesktopControlBeforeFontWeight: 100;
$stickyFooterDesktopControlBeforeFontSize: 2.2rem;
$stickyFooterDesktopControlBeforeLineHeight: 2rem;
$stickyFooterDesktopControlBeforeLetterSpacing: -.4rem;
$stickyFooterDesktopControlBeforePadding: .6rem 1rem .6rem .6rem;
$stickyFooterDesktopControlBeforeCollapsedContent: '\f106';
$stickyFooterDesktopControlCollapsedBackground: $brandPrimaryColor;
$stickyFooterDesktopControlCollapsedColor: $brandWhite;
$stickyFooterDesktopControlBeforeCollapsedFontSize: 2.8rem;
$stickyFooterDesktopControlBeforeCollapsedFontWeight: normal;
$stickyFooterDesktopControlBeforeCollapsedLineHeight: 2rem;
$stickyFooterDesktopImageBorder: none;
$stickyFooterDesktopImagePositionBottom: .5rem;
$stickyFooterDesktopOfferWrapWidth: 70%;
$stickyFooterDesktopOfferWrapMaxWidth: 65rem;
$stickyFooterDesktopOfferWrapMargin: 0 0 0 1rem;
$stickyFooterDesktopOfferWrapPadding: 0 1rem;
$stickyFooterDesktopRebateWrapMargin: 0 0 0 .7rem;
$stickyFooterDesktopMerchantRebateValueCurrencyFontWeight: normal;
$stickyFooterDesktopMerchantInfoMargin: 0 3%;
$stickyFooterDesktopMerchantLogoWrapPadding: 0;
$stickyFooterDesktopMerchantLogoWrapMargin: (medium: 0);
$stickyFooterDesktopRolloverCopyAndDateColor: $brandTextColor;
$stickyFooterDesktopExpirationDotDisplay: none;
$stickyFooterDesktopUseCodeColor: $brandDarkBlue2;
$stickyFooterDesktopCodeFontWeight: normal;
$stickyFooterDesktopCodeTextTransform: uppercase;
$stickyFooterDesktopDisclaimerColor: $brandDarkGray2;
$stickyFooterDesktopDisclaimerFontSize: .9rem;
$stickyFooterDesktopMerchantRebateFontWeight: normal;
$stickyFooterDesktopMerchantRebatePrefixFontWeight: normal;
$stickyFooterDesktopButtonFontSize: (medium: 1.4rem);
$stickyFooterDesktopButtonPadding: 1rem 2rem;

/* Gift Guide */
$giftGuideBackground: (small: $brandLightGray7, medium: unset);
$giftGuideInnerBackground: (small: unset, medium: $brandLightGray7);
$giftGuideInnerPadding: (small: 0, medium: 3rem 1rem);
$giftGuideButtonFontSize: 1.6rem;
$giftGuideButtonFontWeight: bold;
$giftGuideButtonLineHeight: 2.4rem;
$giftGuideButtonPadding: 1.2rem .8rem;
$giftGuideItemWrapBorder: $brandBorder;
$giftGuideItemMerchantWrapHeight: 19.4rem;
$giftGuideItemMerchantWrapBorder: $brandBorder;
$giftGuideItemMerchantWrapBorderWidth: 1px 0 0 0;
$giftGuideItemMerchantLogoAfterBorder: $brandBorder;
$giftGuideItemMerchantLogoAfterBorderRadius: 0;
$giftGuideItemRebateWrapBackground: $rebateBarBackground;
$giftGuideItemOfferDetailsColor: $brandTextColor;
$giftGuideItemFavoriteIconTop: 20.5rem;
$giftGuideItemFavoriteIconRight: 1.2rem;
$giftGuideItemFavoriteIconWidth: 2rem;
$giftGuideItemFavoriteIconHeight: 1.4rem;
$giftGuideItemTieredPrefixDisplay: none;
$giftGuideItemRebateWrapHeight: 4.3rem;
$giftGuideItemRebateWrapFontSize: 1.2rem;
$giftGuideItemInlineRebateFontSize: 1.2rem;
$giftGuideItemInlineRebateLineHeight: 1.5rem;
$giftGuideItemRebatePrefixFontWeight: 700;
$giftGuideItemRebateValueFontSize: 1.2rem;
$giftGuideItemElevationOldValueFontSize: 1.2rem;
$giftGuideItemElevationNewValueFontSize: 1.2rem;
$giftGuideItemPromoCodeWrapColor: #333;
$giftGuideItemPromoCodeColor: $brandPrimaryBlue2;
$giftGuideItemPromoCodeFontSize: 1.2rem;
$giftGuideItemPromoCodeFontWeight: bold;
$giftGuideItemPromoCodeLineHeight: 1.5rem;
$giftGuideItemPromoCodeMargin: 0 0 .75rem 0;
$giftGuideItemExpirationDateColor: #333;
$giftGuideItemExpirationDateFontSize: 1rem;
$giftGuideItemExpirationDateLineHeight: 1.5rem;
$giftGuideItemExpirationDateLetterSpacing: .4px;
$giftGuideItemExpirationDateMargin: -1.2rem 0;

/* Adblock Warning */
$adBlockWarningPadding: 0;
$adBlockWarningBackground: $brandSecondaryColor;
$adBlockWarningMessageJustifyContent: center;
$adBlockWarningMessageFontSize: 1.4rem;
$adBlockWarningMessageFontWeight: normal;
$adBlockWarningMessageHeight: 5.8rem;
$adBlockWarningMessageColor: $brandWhite;
$adBlockWarningMessageBackground: $brandSecondaryColor;
$adBlockWarningMessagePadding: 0;
$adBlockWarningMessageInnerPadding: 0 3rem;
$adBlockWarningMessageInnerMaxWidth: 85rem;
$adBlockWarningIconContent: '\f071';
$adBlockWarningIconFontFamily: 'fontello';
$adBlockWarningIconFontSize: 3rem;
$adBlockWarningIconPosition: relative;
$adBlockWarningIconPositionTop: 7px;

/* First Favorite Modal */
$addToFavoriteFirstTimePopUpTitleColor: (small: $brandDarkBlue2);
$addToFavoriteFirstTimePopUpTitleFontSize: 3rem;
$addToFavoriteFirstTimePopUpTitleLineHeight: 3.7rem;
$addToFavoriteFirstTimePopUpTextColor: (small: $brandPrimaryGrey);
$addToFavoriteFirstTimePopUpTextFontSize: 1.6rem;
$addToFavoriteFirstTimePopUpTextLineHeight: 2.4rem;
$addToFavoriteFirstTimePopUpTextMargin: (small: 2rem 0 4rem);
$addToFavoriteFirstTimePopUpLinkColor: (small: $brandLinkColor);
$addToFavoriteFirstTimePopUpButtonPadding: 1.3rem 3.8rem;
$addToFavoriteFirstTimePopUpButtonWidth: auto;
$addToFavoriteFirstTimePopUpButtonFontSize: 1.37rem;
$addToFavoriteFirstTimePopUpButtonFontWeight: 700;

/* Merchant Offer Overlay */
$merchantOverlayModalCloseButtonColor: (small: #fff, medium: #9eafb9);

$merchantOverlayContentPadding: (small: 0, medium: 0 2rem 1rem);

$merchantOverlayColor: (small: #999, medium: #999);
$merchantOverlayFontSize: (small: 1.6rem, medium: 1.6rem);

$merchantOverlayHeadLogoPadding: (small: 1.8rem 2rem 1.2rem, medium: 2.6rem 2.4rem 0);
$merchantOverlayHeadLogoOffsetTop: (small: 2.7rem, medium: -4.8rem);
$merchantOverlayHeadLogoBorderRadius: (small: 0, medium: 0);

$merchantOverlayFavoriteIconPositionRight: (small: .5rem, medium: .7rem);
$merchantOverlayFavoriteIconPositionTop: (small: .5rem, medium: .7rem);
$merchantOverlayFavoriteIconWidth: (small: 1.3rem, medium: 1.7rem);
$merchantOverlayFavoriteIconHeight: (small: 1.3rem, medium: 1.7rem);

$merchantOverlayOfferTitleLetterSpacing: (small: 0, medium: 0);
$merchantOverlayOfferTitleFont: (small: 700 2.5rem/3.2rem $brandFontFamily, medium: 700 2.2rem/2.8rem $brandFontFamily);
$merchantOverlayOfferTitleMargin: (small: 0, medium: 0);
$merchantOverlayOfferTitleColor: (small: $brandDarkBlue4, medium: $brandDarkBlue2);
$merchantOverlayOfferTitleOrder: (small: 2, medium: ());

$merchantOverlayDescriptionMargin: (small: 0, medium: 5.4rem 0 0);

$merchantOverlayHeadOfferExpiresOrder: (small: 4, medium: ());
$merchantOverlayHeadOfferExpiresColor: (small: $brandTextColor, medium: $brandTextColor);
$merchantOverlayHeadOfferExpiresMargin: (small: 1rem 0 2rem, medium: 1rem 0 0);
$merchantOverlayHeadOfferExpiresFontSize: (small: 1.4rem, medium: 1.4rem);
$merchantOverlayHeadOfferExpiresLineHeight: (small: 2rem, medium: 2.1rem);

$merchantOverlayHeadOfferSeparatorDisplay: (small: none, medium: block);
$merchantOverlayHeadOfferSeparatorBorderTop: (small: none, medium: 1px solid $brandLightGray);
$merchantOverlayHeadOfferSeparatorMargin: (small: (), medium: 1rem 0 0);

$merchantOverlayPremierMerchantRebateOrder: (small: 1, medium: ());
$merchantOverlayPremierMerchantRebateMargin: (small: (), medium: 2rem 0);

$merchantOverlayHeadRebateMargin: (small: 2.5rem 0 2rem, medium: 2rem 0);
$merchantOverlayHeadRebatePadding: (small: 0 8rem 1.45rem, medium: 0);
$merchantOverlayHeadRebateBorderBottom: (small: 1px solid rgba(0, 0, 0, .1), medium: none);

$merchantOverlayMerchantRebateLineHeight: (small: 1.5rem, medium: 2.4rem);
$merchantOverlayRebateOldValueFontSize: (small: 1.2rem, medium: 1.8rem);
$merchantOverlayRebateOldValueAfterContent: ', ';
$merchantOverlayRebateNewValueFontSize: (small: 1.2rem, medium: 1.8rem);
$merchantOverlayRebateNewValueWhiteSpace: wrap;
$merchantOverlayRebateValueWithCurrencyFontSize: (small: 1.2rem, medium: 1.8rem);
$merchantOverlayRebatePrefixFontSize: (small: 1.2rem, medium: 1.8rem);
$merchantOverlayRebatePrefixColor: (small: $brandPrimaryBlue2, medium: $brandPrimaryBlue2);
$merchantOverlayRebatePrefixFontWeight: (small: bold, medium: bold);

$merchantOverlayCouponControlsJustifyContent: center;
$merchantOverlayCouponMargin: 1rem 0;

$merchantOverlayCouponCodeInputPadding: (small: .2rem 2.6rem .2rem 1rem, medium: .2rem 2rem .2rem 2rem);
$merchantOverlayCouponCodeInputMinWidht: (small: 22rem, medium: 0);
$merchantOverlayCouponCodeInputMaxWidht: (small: 22rem, medium: 16.3rem);
$merchantOverlayCouponCodeInputHeight: (small: 4.8rem, medium: 3rem);
$merchantOverlayCouponCodeInputBorder: (small: none, medium: none);
$merchantOverlayCouponCodeInputBorderRadius: (small: 0, medium: 3px 0 0 3px);
$merchantOverlayCouponCodeInputBackgroundColor: (small: #f5f5f5, medium: $brandLightGray);
$merchantOverlayCouponCodeInputLetterSpacing: 0;
$merchantOverlayCouponCodeInputColor: (small: $brandBlue2, medium: $brandDarkGray3);
$merchantOverlayCouponCodeInputFont: (small: 700 1.2rem/2rem $brandFontFamily, medium: normal 1.2rem/1.8rem $brandFontFamily);
$merchantOverlayCouponCodeInputTextAlign: (small: left, medium: center);

$merchantOverlayCouponCodeCopyWidth: (small: 8rem, medium: 12rem);
$merchantOverlayCouponCodeCopyPadding: 0 2rem;
$merchantOverlayCouponCodeCopyBorderRadius: (small: 0, medium: 0 3px 3px 0);
$merchantOverlayCouponCodeCopyBorder: (small: 1px solid #f5f5f5, medium: 1px solid $brandLightGray);
$merchantOverlayCouponCodeCopyColor: (small: $brandBlue2, medium: $brandDarkGray3);
$merchantOverlayCouponCodeCopyFont: (small: 700 1.2rem/2rem $brandFontFamily, medium: normal 1.2rem/1.8rem $brandFontFamily);
$merchantOverlayCouponCodeCopyTextTransform: (small: none, medium: uppercase);
$merchantOverlayCouponCodeCopyHoverBackground: rgba(105, 153, 175, .25);
$merchantOverlayCouponCodeCopyHoverColor: $brandDarkGray3;

$merchantOverlayCouponCodeIsCopiedBackground: rgba(105, 153, 175, .25);
$merchantOverlayCouponCodeIsCopiedColor: $brandDarkGray3;

$merchantOverlayCouponIntroFontSize: (small: 1.2rem, medium: 1.2rem);
$merchantOverlayCouponIntroMargin: (small: 1rem 0 1.5rem, medium: 1rem 0);
$merchantOverlayCouponIntroColor: (small: $brandDarkGray3, medium: $brandDarkGray3);

$merchantOverlayShopNowMargin: (small: 1.5rem 0, medium: 2rem 0);
$merchantOverlayShopNowFont: (small: 700 1.6rem/2.4rem $brandFontFamily, medium: 700 1.4rem/1.7rem $brandFontFamily);
$merchantOverlayShopNowMinWidth: (small: 30rem, medium: 20rem);
$merchantOverlayShopNowBackgroundColor: $brandButtonBackgroundColor;
$merchantOverlayShopNowBorder: none;
$merchantOverlayShopNowPadding: (small: 1.5rem 0 1.1rem, medium: 1rem 1rem);
$merchantOverlayShopNowBorderRadius: 0;
$merchantOverlayShopNowLetterSpacing: (small: 0, medium: 0);
$merchantOverlayShopNowTextTransform: capitalize;

$merchantOverlayFooterMerchantTermsColor: (small: $brandDarkGray3, medium: #999);
$merchantOverlayFooterMerchantTermsMargin: (small: 0, medium: 0);

$merchantOverlayAllOffersMargin: 0 0 .5rem;
$merchantOverlayAllOffersFontWeight: 700;
$merchantOverlayAllOffersColor: (small: $brandLightBlue4, medium: $brandLinkColor);

$merchantOverlayFooterTermsFontSize: (small: 1.2rem, medium: 1rem);
$merchantOverlayFooterTermsTextDecoration: none;

$merchantOverlayDecorationMargin: 0;
$merchantOverlayDecorationDisplay: (small: block, medium: none);
$merchantOverlayDecorationHeight: 14.2rem;
$merchantOverlayDecorationBackground: #114669;
$merchantOverlayDecorationPosition: relative;
$merchantOverlayDecorationAfterContent: '';
$merchantOverlayDecorationAfterDisplay: block;
$merchantOverlayDecorationAfterWidth: 100%;
$merchantOverlayDecorationAfterHeight: 4.7rem;
$merchantOverlayDecorationAfterBackground: $brandDarkBlue4;
$merchantOverlayDecorationAfterPosition: absolute;
$merchantOverlayDecorationAfterPositionBottom: 0;

$merchantOverlayDescriptionDisplay: (small: flex, medium: block);
$merchantOverlayDescriptionFlexDirection: (small: column, medium: initial);

$merchantOverlayDisclaimerFontSize: (small: 1.2rem, medium: 1.4rem);
$merchantOverlayDisclaimerLineHeight: 2.1rem;
$merchantOverlayDisclaimerColor: (small: #566f80, medium: $brandTextColor);
$merchantOverlayDisclaimerMargin: 1rem auto 0;
$merchantOverlayDisclaimerWidth: ();
$merchantOverlayDisclaimerOrder: (small: 3, medium: ());

$merchantOverlayExtraPillWidth: 12rem;

/* Button Overlay */
$buttonOverlayContentBackground: url('~org/assets/button/button_overlay_background.jpg') no-repeat center;
$buttonOverlayBlockPadding: 0 9.6rem;
$buttonOverlayFontFamily: $brandFontFamilyHelvetica;
$buttonOverlayHeaderFontSize: 3.2rem;
$buttonOverlayHeaderFontWeight: 400;
$buttonOverlayHeaderColor: $brandDarkBlue4;
$buttonOverlayHeaderMargin: .5rem 0 1rem;
$buttonOverlayHeaderTextTransform: none;
$buttonOverlayMottoColor: $brandDarkBlue4;
$buttonOverlayMottoFontSize: 1.4rem;
$buttonOverlayMottoLetterSpacing: 1px;
$buttonOverlayMottoTextTransform: uppercase;
$buttonOverlayTextFontSize: 1.6rem;
$buttonOverlayTextLineHeight: 2.8rem;
$buttonOverlayTextMarginBottom: 1.6rem;
$buttonOverlayRatingTextColor: $brandDarkGray3;
$buttonOverlayRatingTextFontSize: 1.2rem;
$buttonOverlayRatingTextTextTransform: capitalize;
$buttonOverlayButtonFontSize: 1.6rem;
$buttonOverlayButtonFontWeight: 700;
$buttonOverlayButtonTextTransform: none;
$buttonOverlayScrollbarTrackPieceBackground: #c6cfd2;
$buttonOverlayScrollbarThumBackground: #486a7b;
$buttonOverlayBlockTwoPositionTop: 67rem;
$buttonOverlayBlockThreeHeaderColor: $brandWhite;
$buttonOverlayBlockThreePadding: 0 8rem;
$buttonOverlayHiwHintsColor: $brandWhite;
$buttonOverlayHiwHintsMargin: 25rem 0 3rem 0;
$buttonOverlayHiwHintsSubheaderFontWeight: 400;
$buttonOverlayHiwHintsBorder: 1px solid rgba(255, 255, 255, .3);
$buttonOverlayHiwHintsSubheaderMargin: 1.5rem 0 1rem 0;
$buttonOverlayHiwHintsBlockOneTextMarginTop: 2rem;
$buttonOverlayBlockFourPositionTop: 217rem;
$buttonOverlayBlockFourTextMargin: 0 0 3rem;

/* Merchant Logos */
$merchantLogosSectionMargin: (small: 0);
$merchantLogosSectionPadding: (small: 2.5rem 0);
$merchantLogosListLayoutColumns: (small: 3, medium: 8, large: 8);
$merchantLogosListLayoutColumnGap: (small: 4rem, medium: 2.5rem, large: 6rem);
$merchantLogosLogoWrapperMaxWidth: (small: 9rem, medium: 9rem, large: 11rem);
$merchantLogosLogoWrapperHeight: 3.7rem;

/* HIW VHP */
$howItWorksVhpSectionPadding: (small: 5rem 0);
$howItWorksVhpSectionBackground: (small: $brandLightColor);
$howItWorksVhpSectionTitleH2FontWeight: (small: bold);
$howItWorksVhpSectionTitleH2LetterSpacing: (small: 1px);
$howItWorksVhpSectionTitleH2Color: (small: $brandDarkBlue2);
$howItWorksVhpSectionTitleH2LineHeight: (small: 3.6rem);
$howItWorksVhpImageWidth: 22.6rem;
$howItWorksVhpItemTitleMargin: -.9rem 0 1.1rem;
$howItWorksVhpItemTitleFontSize: 2.5rem;
$howItWorksVhpItemTitleLineHeight: 2.7rem;
$howItWorksVhpItemTitleLetterSpacing: .57px;
$howItWorksVhpItemDescriptionMaxWidth: 31.6rem;
$howItWorksVhpItemDescriptionFontWeight: 500;

/* HIW Video */
$hiwVideoModalPadding: (small: 0);

/* Home Button Benefits */
$homeButtonBenefitsHeaderMargin: (small: 2rem 0 1.5rem 0);
$homeButtonBenefitsHeaderFontSize: 3rem;
$homeButtonBenefitsHeaderLineHeight: 4rem;
$homeButtonBenefitsHeaderMinWidth: (small: unset);
$homeButtonBenefitsListFontSize: 1.6rem;
$homeButtonBenefitsListLineHeight: 3.2rem;
$homeButtonBenefitsListColor: $brandPrimaryGrey;
$homeButtonBenefitsUListMargin: 0;
$homeButtonBenefitsChromeMargin: (small: 1rem 0 1.5rem 0, medium: 0);
$homeButtonBenefitsRatingSpanFontWeight: 400;
$homeButtonBenefitsButtonWidth: 25rem;
$homeButtonBenefitsButtonPadding: .8rem 2rem;

/* Testimonials VHP */
// $testimonialVhpBackground: $brandHeaderColor;
// $testimonialVhpSectionTitleH2FontSize: 3rem;
// $testimonialVhpSectionTitleH2LineHeight: 4rem;
// $testimonialVhpIconBeforeBackground: url('../assets/icons/testimonials_icon_light.png') 50% 50% / 2.8rem 3.6rem no-repeat;
// $testimonialVhpIconBeforeHeight: 4rem;
// $testimonialVhpIconBeforeMargin: 0 auto;
// $testimonialVhpItemMargin: 2rem 0 0;
// $testimonialVhpTextFontSize: 2rem;
// $testimonialVhpTextLineHeight: 3.6rem;
// $testimonialVhpTextLetterSpacing: .45px;
// $testimonialTextMaxWidth: (small: (), medium: (), large: 93rem);

/* Featured Offers Logo */
$featuredOffersLogoSectionTitleTextAlign: center;
$featuredOffersLogoViewAllLinkTextTransform: unset;
$featuredOffersLogoItemTileWidth: 15.4rem;
$featuredOffersLogoItemTileHeight: 17.6rem;
$featuredOffersLogoItemTileBorder: 1px solid #d4d4d0;
$featuredOffersLogoItemTileBoxShadow: 0 2px 4px 0 rgba(255, 255, 255, .5);
$featuredOffersLogoTop: 0;
$featuredOffersLogoBottom: 0;
$featuredOffersLogoRight: 0;
$featuredOffersLogoLeft: 0;
$featuredOffersLogoRebateHeight: 7.4rem;
$featuredOffersLogoRebateOldValue: 1.1rem;
$featuredOffersLogoRebateNewValueFontSize: 1.3rem;
$featuredOffersLogoRebateNewValueLineHeight: 1.6rem;

/* Onboarding */
$onboardingPadding: 0 3rem 2rem;
$onboardingBorderRadius: (small: 0);
$onboardingBoxShadowLarge: 0 2px 24px 0 rgba(0, 0, 0, .5);

$onboardingPageCounterPosition: absolute;
$onboardingPageCounterPositionTop: 1rem;
$onboardingPageCounterPositionRight: 1rem;
$onboardingPageCounterFontSize: 1.4rem;

$onboardingH2LineHeight: (small: 4rem);
$onboardingH2FontSize: (small: 3.2rem, large: 3.2rem);
$onboardingH2FontWeight: 400;
$onboardingH2Color: $brandDarkBlue2;

$onboardingPFontWeight: 400;
$onboardingPColor: $brandDarkBlue2;
$onboardingPFontSize: (small: 1.8rem, large: 2rem);
$onboardingPLineHeight: (small: 2.4rem, medium: 2.4rem, large: 3.1rem);

$onboardingBottomContentPadding: (small: 2rem);
$onboardingBottomContentPositionTop: (small: 46.5rem, large: 46.8rem);

$onboardingStepButtonMargin: (small: 1.5rem 0 0, large: 0 auto 1.5rem);
$onboardingStepButtonFontSize: 1.4rem;
$onboardingStepButtonBackground: #468423;
$onboardingStepButtonWidth: (small: 100%, large: 24rem);

$onboardingBrandLogoBackground: url('../assets/logo.svg') center/50rem 9rem no-repeat;
$onboardingBrandLogoBackgroundSize: (small: contain);
$onboardingBrandLogoWidth: 80%;
$onboardingBrandLogoHeight: 9rem;
$onboardingBrandLogoMargin: (small: 0 auto 9.9rem);

$onboardingWelcomeMarginTop: (small: 9.2rem);
$onboardingWelcomeStepHeaderMargin: (small: 0 0 1.9rem);
$onboardingWelcomeStepPWidth: 45.5rem;

$onboardingOptInMargin: (small: 3.5rem 0 0);
$onboardingOptInImageBackground: url('../assets/onboarding/Email.png') center/20.2rem 20.1rem no-repeat;
$onboardingOptInImageHeight: 20.2rem;
$onboardingOptInImageMargin: (small: 0 auto 4.4rem);
$onboardingOptInHeaderMargin: (small: 3.3rem auto 2.8rem, medium: 6.5rem auto 2.8rem, large: 4.4rem 1.8rem 1.8rem);
$onboardingOptInHeaderPadding: 0 1.5rem;
$onboardingOptInTextWidth: auto;
$onboardingOptInTextMargin: 0 3.4rem 4.9rem;
$onboardingOptinLabelColor: $brandDarkBlue2;
$onboardingOptinLabelFontSize: 1.6rem;
$onboardingOptinLabelFontWeight: 700;
$onboardingOptinLabelMargin: 0 0 0 .4rem;

$onboardingFavoritesMargin: (small: 1.6rem 0 0, medium: 1.6rem 0 0, large: 5.4rem 0 0);
$onboardingFavoritesHeadingMargin: (small: 0 5rem .9rem, large: 0 0 1rem);
$onboardingFavoritesHeadingFontSize: (small: 3rem);
$onboardingFavoritesHeadingFontWeight: 700;
$onboardingFavoritesPickFavoritesMargin: (small: 1rem 0 0, large: auto);
$onboardingFavoritesPickFavoritesPadding: (small: 1rem 0 0, large: 2.3rem 0 0);
$onboardingFavoritesPickFavoritesBackground: (small: $brandLightGray5, large: $brandWhite);
$onboardingFavoritesPickFavoritesHeight: (small: 26.5rem, medium: 22rem);
$onboardingFavoritesPotentialFavoriteMargin: (small: none, medium: 0);
$onboardingFavoritesPotentialFavoriteWidth: 13.5rem;
$onboardingFavoritesPotentialFavoriteHeight: 5.6rem;
$onboardingFavoritesMerchantLogoWidth: (small: 16rem, large: 9rem);
$onboardingFavoritesMerchantLogoPadding: (small: 0, large: .1rem);
$onboardingFavoritesMerchantLogoMargin: (small: 2.8rem 0 1rem, large: 2.7rem .7rem);
$onboardingFavoriteIconFontSize: 1.7rem;
$onboardingFavoriteIconPositionTop: (small: .5rem, large: .8rem);
$onboardingFavoriteIconPositionRight: (small: .5rem, large: 2.8rem);
$onboardingFavoriteIconWidth: 2rem;
$onboardingFavoriteIconHeight: (small: 2rem);
$onboardingFavoritesTextFontSize: 2rem;
$onboardingFavoritesTextMargin: 0 0 1rem;
$onboardingFavoriteStepBottomContentPositionTop: 43.4rem;
$onboardingFavoriteStepButtonMargin: (small: (), medium: 1.5rem 0 0, large: 0 auto 1.5rem);
$onboardingTurnOffFontSize: (small: 1.6rem, medium: 1.5rem);
$onboardingTurnOffLineHeight: (small: 2.2rem, medium: 1.8rem);
$onboardingTurnOffAlertsColor: $brandTextColor;

$onboardingFavoritesPickFavoritesBoxShadow: none;
$onboardingFavoritesCarouselArrowColor: #9a9ea7;
$onboardingFavoritesCarouselArrowPrevBeforeContent: '\f104';
$onboardingFavoritesCarouselArrowNextBeforeContent: '\f105';
$onboardingFavoritesCarouselArrowNextBeforeBackground: inherit;
$onboardingFavoritesCarouselArrowPrevBeforeBackground: inherit;
$onboardingFavoritesCarouselArrowBeforeFontFamily: 'Fontello';
$onboardingFavoritesCarouselArrowBeforeFontSize: 3.5rem;
$onboardingFavoritesCarouselArrowDisplay: inline-block;
$onboardingFavoritesCarouselArrowHeight: 2.5rem;
$onboardingFavoritesCarouselArrowWidth: 1.4rem;
$onboardingFavoritesCarouselArrowNextPositionRight: 3.2rem;
$onboardingFavoritesCarouselArrowPrevPositionLeft: 1.2rem;
$onboardingFavoritesCarouselArrowNextTop: 57%;
$onboardingFavoritesCarouselArrowPrevTop: 57%;
$onboardingFavoritesCarouselArrowPrevZIndex: 1;
$onboardingFavoritesCarouselSlickListMargin: 0 2.7rem;

$onboardingStepLaterLinkColor: $brandLinkColor;
$onboardingStepLaterLinkTextDecoration: (small: none);
$onboardingStepLaterLinkMaxWidth: unset;
$onboardingStepLaterLinkLineHeight: 1.4rem;
$onboardingStepLaterLinkArrowSize: 0;

$onboardingButtonStepPadding: (small: 0 1.5rem);
$onboardingButtonStepHeadingMargin: (small: 3.5rem 0 2rem, large: 10.7rem auto 1.2rem);
$onboardingButtonStepHeadingFontSize: (small: 3.2rem, medium: 3.2rem, large: 3.2rem);
$onboardingButtonStepHeadingLineHeight: (small: 4rem);
$onboardingButtonStepHeadingWidth: (large: 60.5rem);
$onboardingButtonStepPMargin: (small: 0 0 2rem, large: 0 0 1rem);
$onboardingButtonStepDescriptionFlexDirection: (small: column, large: row);
$onboardingGetTheButtonBackground: url('../assets/onboarding/Button.png') center/20.1rem 20.1rem no-repeat;
$onboardingGetTheButtonWidth: (small: 20.1rem);
$onboardingGetTheButtonHeight: (small: 14rem, large: 20.1rem);
$onboardingGetTheButtonMargin: (small: 0);
$onboardingButtonStepListWidth: (small: 100%, large: 60.8%);
$onboardingButtonStepListPadding: (small: 2rem 0 0, large: 1rem 0 1rem 1.6rem);
$onboardingButtonStepBulletTextFontSize: (small: 1.3rem, large: 1.6rem);
$onboardingButtonStepBulletTextLineHeight: 1.8rem;
$onboardingButtonStepBulletTextColor: #333;
$onboardingButtonStepBulletTextWidth: auto;
$onboardingButtonStepBulletTextPadding: 1.1rem 0 .9rem;
$onboardingButtonStepBulletTextBorderBottom: 1px solid #898989;
$onboardingButtonStepButtonMargin: (small: 1.5rem 0 0, large: 0 auto 1.5rem);
$onboardingButtonStepBulletTextImgMarginTop: 1px;

$onboardingCongratsHeadingPaddingTop: (small: 0);
$onboardingCongratsHeadingFlexDirection: (small: column);
$onboardingCongratsImgMargin: 9.5rem auto 2.8rem;
$onboardingCongratsImgWidth: 8.1rem;
$onboardingCongratsPMargin: (small: 1.5rem 3.4rem 0);
$onboardingCongratsPLetterSpacing: .41px;

$headerOnboardingCongratsWrapPositionTop: -10rem;
$headerOnboardingCongratsMaxHeight: 11.8rem;
$headerOnboardingCongratsBoxShadow: none;
$headerOnboardingCongratsBackground: #3192aa;
$headerOnboardingCongratsTitleFontSize: 3rem;
$headerOnboardingCongratsTitleLineHeight: 3.4rem;
$headerOnboardingCongratsH2Color: $brandWhite;
$headerOnboardingCongratsIconDisplay: inline;
$headerOnboardingCongratsIconPosition: relative;
$headerOnboardingCongratsIconPositionTop: .4rem;
$headerOnboardingCongratsTextMarginTop: .5rem;
$headerOnboardingCongratsTextFontSize: 1.8rem;
$headerOnboardingCongratsTextColor: $brandWhite;
$headerOnboardingCongratsTextLetterSpacing: .41px;

/* Email Opt In */
$emailSubscribeModalMinHeight: unset;
$emailSubscribeModalPositionTop: 30%;
$emailSubscribeModalWidth: (small: 100%, medium: 70rem);
$emailSubscribePanelPadding: 5rem 4rem 5rem 2rem;
$emailSubscribePanelBackground: $brandDarkBlue;
$emailSubscribePanelTextAlign: left;
$emailSubscribeModalPadding: 10rem 9rem 11rem;
$emailSubscribeModalBackground: $brandDarkBlue;
$emailSubscribeHeadingTextAlign: left;
$emailSubscribeHeadingLetterSpacing: 0;
$emailSubscribeHeadingFont: (small: bold 3.6rem / 1.2 $brandFontFamily, medium: bold 3.6rem / 1.2 $brandFontFamily);
$emailSubscribeHeadingColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeHeadingMargin: (small: 0 0 1rem, medium: 0 0 .5rem);
$emailSubscribeContentFont: (small: normal 1.8rem / 1.4 $brandFontFamily, medium: normal 1.8rem / 1.4 $brandFontFamily);
$emailSubscribeContentColor: (small: $brandWhite, medium: $brandWhite);
$emailSubscribeControlsMargin: (small: 3rem 0 0, medium: 3rem 0 0);
$emailSubscribeSubmitMargin: (small: 0 0 1rem, medium: 0 0 1rem, large: 0 0 1rem);
$emailSubscribeSubmitFontSize: (small: 1.8rem, medium: 1.7rem);
$emailSubscribeSubmitPadding: (small: 1.2rem 1rem, medium: 1.2rem 4.5rem);
$emailSubscribeCancelLinkDisplay: (medium: initial);
$emailSubscribeCancelLinkColor: (small: $brandPrimaryBlue, medium: $brandPrimaryBlue);
$emailSubscribeCancelLinkFont: (small: normal 1.5rem / 1.4 $brandFontFamily, medium: bold 1.7rem / 1.4 $brandFontFamily);
$emailSubscribeCloseWidth: (small: 3rem, medium: 3rem);
$emailSubscribeCloseHeight: (small: 3rem, medium: 3rem);
$emailSubscribeCloseBackground: $brandLightBlue;
$emailSubscribeCloseBorderRadius: 30px;
$emailSubscribeCloseAfterColor: $brandDarkBlue;
$emailSubscribeCloseAfterMargin: .4rem .7rem 0 .4rem;
$emailSubscribeCloseAfterLineHeight: 2rem;
$emailSubscribeCloseAfterLetterSpacing: -.4rem;

/* Email OptIn Success Modal */
$emailSuccessMessageColor: $brandWhite;
$emailSuccessMessageBackground: $brandTertiaryColor;
$emailSuccessMessageCloseButtonBeforeColor: $brandWhite;
$emailSuccessMessageCloseButtonBeforeContent: '\2715';
$emailSuccessMessageCloseButtonBeforeFontSize: 2rem;
$emailSuccessMessageCloseButtonBeforeFontWeight: 700;
$emailSuccessMessageH2FontSize: 3rem;
$emailSuccessMessageH2Margin: 0 0 .5rem;
$emailSuccessMessageH2Color: $brandWhite;
$emailSuccessMessageH2FontWeight: 600;
$emailSuccessMessageOlayDescFontSize: 2rem;
$emailSuccessMessageOlayDescLineHeight: 1.4;
$emailSuccessModalBackground: $brandTertiaryColor;
$emailSuccessModalCloseButtonColor: $brandWhite;
$emailSuccessModalContentH2Color: $brandWhite;
$emailSuccessModalContentH2FontSize: 3rem;
$emailSuccessModalContentH2FontWeight: 600;
$emailSuccessModalContentH2Margin: 0 0 .5rem;
$emailSuccessModalContentTextColor: $brandWhite;
$emailSuccessModalContentTextFontSize: 2rem;
$emailSuccessModalContentTextLineHeight: 1.4;
$emailSuccessMessageCloseButtonBackground: $brandQuaternaryColor;
$emailSuccessMessageCloseButtonBorderRadius: 3rem;
$emailSuccessMessageCloseButtonWidth: 3rem;
$emailSuccessMessageCloseButtonHeight: 3rem;
$emailSuccessMessageCloseButtonBeforeColor: $brandDarkBlue;
$emailSuccessModalCloseButtonColor: $brandDarkBlue;
$emailSuccessModalCloseButtonWidth: 3rem;
$emailSuccessModalCloseButtonHeight: 3rem;
$emailSuccessModalCloseButtonBorderRadius: 3rem;
$emailSuccessModalCloseButtonBackground: $brandQuaternaryColor;

/* Hybrid Stores */
$hybridStoresTitleMargin: (small: 0 0 3.8rem, medium: 0 0 5.8rem);
$hybridStoresTitleTextAlign: left;
$hybridStoresTitleColor: $brandDarkBlue4;
$hybridStoresTitleFontFamily: $brandFontFamilyHelvetica;
$hybridStoresTitleLineHeight: 3.2rem;
$hybridStoresTitleFontSize: 2.5rem;
$hybridStoresTitleFontWeight: bold;
$hybridStoresTitleTextTransform: capitalize;
$hybridStoresViewAllLinkColor: $brandLightBlue4;
$hybridStoresViewAllLinkLineHeight: 2rem;
$hybridStoresViewAllLinkFontSize: 1.4rem;
$hybridStoresViewAllTextColor: $brandBlack;
$hybridStorePadding: (small: 0 0 3.4rem, medium: 0 0 6.4rem);
$hybridStoreMerchantTileDescriptionMargin: 0 2.1rem 0 1.5rem;
$hybridStoreMerchantTileDescriptionWidth: 19rem;
$hybridStoreMerchantTileNameFontSize: 1.6rem;
$hybridStoreMerchantTileNameFontWeight: bold;
$hybridStoreMerchantTileNameLineHeight: 2.8rem;
$hybridStoreMerchantTileNameColor: $brandTextColor;
$hybridStoreMerchantTileNamePadding: 0 0 .5rem;
$hybridStoreMerchantTileRebateValueDisplay: block;
$hybridStoreMerchantTilePremierMerchantRebateElevatedNewSpacing: 0;
$hybridStoreMerchantTilePremierMerchantRebateFontSize: 1.6rem;
$hybridStoreMerchantTilePremierMerchantRebateLineHeight: 1.9rem;
$hybridStoreMerchantTilePremierMerchantRebateElevatedNewValueFontWeight: bold;
$hybridStoreMerchantTilePremierMerchantRebateElevatedNewValueLineHeight: 1.9rem;
$hybridStoreMerchantTilePremierMerchantRebateFontWeight: bold;
$hybridStoreMerchantTileRebateValueTextWrap: nowrap;
$hybridStoreMerchantTileBorder: 2px solid #e6e6e6;
$hybridStoreMerchantTileBoxShadow: 1px 1px 2px 0 rgba(18, 57, 91, .2);
$hybridStoreMerchantTilePositionTop: 0;
$hybridStoreMerchantTilePositionBottom: 0;
$hybridStoreMerchantTilePositionLeft: 0;
$hybridStoreMerchantTilePositionRight: 0;
$hybridStoreMerchantTileImgWidth: 8.4rem;
$hybridStoreMerchantExtraRewardsTileInnerBackground:  linear-gradient(to top, rgba(212, 212, 208, .25) 0%, $brandWhite 2.8rem, $brandWhite 2.8rem);
$hybridStoreMerchantPillPositionBottom: 1rem;
$hybridStoreMerchantPillBorderRadius: 0;
$hybridStoreMerchantPillLabelPadding: 0;
$hybridStoreMerchantPillLabelAfterDisplay: none;
$hybridStoreMerchantPillLabelFontSize: 1rem;
$hybridStoreMerchantFavoriteIconPositionRight: 1.1rem;
$hybridStoreMerchantFavoriteIconPositionTop: 1.5rem;
$hybridStoreMerchantFavoriteIconWidth: 2.2rem;
$hybridStoreMerchantFavoriteIconHeight: 1.18rem;
$hybridStoreMerchantFavoriteIconLineHeight: 1;
$hybridStoreMerchantFavoriteFontIconBeforeFontSize: 1.4rem;
$hybridStoresSliderFontSize: ();
$hybridStoresSliderLineHeight: ();

/* Hybrid Stores Page */
$hybridStoresButtonBackground: $brandBlue2;
$hybridStoresButtonColor: $brandWhite;
$hybridStoresButtonPadding: 1.39rem 5rem 1rem;
$hybridStoresButtonFontWeight: 600;
$hybridStoresButtonContainerMargin: (small: (), medium: .4rem 0 0);
$hybridStoresButtonContainerPadding: (small: 0 0 3.4rem, medium: 0 0 10.8rem);

/* Hybrid Products */
$hybridProductsWrapMargin: -.2rem 0 0;
$hybridProductsWrapBackground: $brandDarkBlue4;
$hybridProductsWrapPadding: (small: 3rem 0 5rem, medium: 3rem 0 7rem);
$hybridProductsSectionTitleH2FontSize: 2.5rem;
$hybridProductsSectionTitleH2LineHeight: 3.2rem;
$hybridProductsSectionTitleH2FontFamily: $brandFontFamilyHelvetica;
$hybridProductsSectionTitleH2TextAlign: left;
$hybridProductsSectionTitleColor: $brandWhite;
$hybridProductsSectionTitleLinkLineHeight: 2rem;
$hybridProductsSectionTitleLinkFontSize: 1.4rem;
$hybridProductsSectionTitleLinkAfterDisplay: none;
$productItemWrapPadding: .5rem;
$productItemWrapHeight: 47.5rem;
$productItemWrapBorder: 1px solid $brandLightGray9;
$productItemWrapBorderRadius: 0;
$productItemProductInfoColor: $brandTextColor;
$productItemProductInfoNameFont: normal 1.2rem/1.8rem $brandFontFamilyHelvetica;
$productItemProductInfoPadding: 1rem 1.5rem;
$productItemProductInfoPriceFont: bold 1.4rem/2rem $brandFontFamilyHelvetica;
$productItemProductInfoPriceMargin: 0 0 .7rem;
$productItemProductInfoRebateFont: bold 1.6rem/1.9rem $brandFontFamilyHelvetica;
$productItemProductInfoRebateColor: $brandPrimaryBlue2;
$productItemProductInfoRebateValueFontSize: 1.6rem;
$productItemMerchantBackgroundColor: $brandLightGray7;
$productItemMerchantBottom: .5rem;
$productItemMerchantRight: .5rem;
$productItemMerchantLeft: .5rem;
$productItemMerchantPadding: 1.2rem 1.5rem;
$productItemMerchantPFont: normal 1.2rem/1.8rem $brandFontFamilyHelvetica;
$productItemExtraFlagWidth: 7.9rem;
$productItemExtraFlagBackgroundColor: #024;
$productItemExtraFlagAfterBorderColor: transparent #006080;
$productItemFlagFont: bold 1.2rem/1.7rem $brandFontFamilyHelvetica;
$productItemFlagColor: $brandWhite;
$productItemFlagLeft: -1.1rem;
$productItemFlagTextTransform: uppercase;
$productItemFlagWidth: 6.8rem;
$productItemFlagBackgroundColor: $brandBlue2;
$productItemFlagAfterBorderColor: transparent #486a7b;

/* Hybrid Coupons */
$hybridCouponsBackground: ();
$hybridCouponsPadding: (small: 3.5rem 0 7.5rem 0, medium: 3rem 0 9rem 0);
$hybridCouponsTitleH2FontTextAlign: left;
$hybridCouponsTitleH2Color: $brandDarkBlue4;
$hybridCouponsTitleH2FontFamily: $brandFontFamilyHelvetica;
$hybridCouponsViewAllLinkFontSize: 1.4rem;
$hybridCouponsViewAllLinkLineHeight: 2rem;
$hybridCouponsViewAllLinkColor: $brandLightBlue4;
$hybridCouponsTitleMargin: (small: 0 0 5.5rem, medium: 0 0 5.8rem);
$hybridCouponsTilesColumnGap: 4.8rem;
$hybridCouponTileLogoWrapMaxWidth: (small: 8.4rem, medium: 25%);
$hybridCouponTileMargin: (small: 0 0 2rem, medium: 0 0 2.4rem);
$hybridCouponTileDetailsWrapPadding: 0 0 0 2.5rem;
$hybridCouponTileOfferDetailsColor: $brandTextColor;
$hybridCouponTileOfferDetailsLineHeight: 2rem;
$hybridCouponTileOfferDetailsMargin: 0 0 .8rem 0;
$hybridCouponTileCouponCodeLabelColor: $brandDarkGray3;
$hybridCouponTileCouponCodeLabelFontSize: 1.2rem;
$hybridCouponTileCouponCodeLabelLineHeight: 1.5rem;
$hybridCouponTileCouponCodeColor: $brandDarkBlue4;
$hybridCouponTileCouponCodeLineHeight: 2rem;
$hybridCouponTileRebateFontSize: 1.6rem;
$hybridCouponTileSimilarCouponsLinkColor: $brandTextColor;
$hybridCouponTileSimilarCouponsLinkBackground: #dfdddb;
$hybridCouponTileSimilarCouponsLinkPadding: 1.2rem 2rem;
$hybridCouponTileOfferExpiresFontStyle: ();

/* Product Details Modal */
$productDetailsModalOfferInfoMargin: (small: 0, medium: 0 0 0 3rem);
$productDetailsModalInfoMargin: (small: 0 0 1.9rem, medium: 0 0 0 4rem);
$productDetailsModalProductNameFontSize: (small: 1.6rem, medium: 1.6rem);
$productDetailsModalProductNameColor: $brandTextColor;
$productDetailsModalProductNameLineHeight: 2.8rem;
$productDetailsModalProductNameMargin: (small: 2.1rem 0 .6rem, medium: 0 0 .6rem);
$productDetailsModalCompareTextFontSize: (small: 1.4rem, medium: 1.4rem);
$productDetailsModalListFontSize: (small: 1.4rem, medium: 1.4rem);
$productDetailsModalListPadding: 0 0 0 2rem;
$productDetailsModalDescriptionColor: ();
$productDetailsModalDescriptionLineHeight: 1.8rem;
$productDetailsModalDescriptionFontSize: 1.2rem;
$productDetailsModalDetailsButtonDisplay: block;
$productDetailsModalDetailsButtonColor: #006080;
$productDetailsModalDetailsButtonPadding: .2rem 0 0;
$productDetailsModalDetailsButtonLineHeight: 1.8rem;
$productDetailsModalTableHeadRowMargin: 0 0 0 1.6rem;
$productDetailsModalTableHeadFontSize: 1.4rem;
$productDetailsModalTableHeadLineHeight: 2rem;
$productDetailsModalTableHeadBackground: $brandLightGray7;
$productDetailsModalTableHeadCellPadding: 1.9rem 0;
$productDetailsModalTableHeadColor: $brandDarkBlue4;
$productDetailsModalExtraRewardsPillBackground: #024;
$productDetailsModalPillOfferInfoMargin: 0 0 0 3rem;
$productDetailsModalPillBorderRadius: 0;
$productDetailsModalPillLabelPadding: .5rem 0;
$productDetailsModalPillLabelFontSize: 1.2rem;
$productDetailsModalPillLabelFontWeight: bold;
$productDetailsModalSalePillBackground: #bad34e;
$productDetailsModalSalePillLabelColor: $brandBlack;
$productDetailsModalPillTextTransform: uppercase;
$productDetailsModalOfferCellPadding: 0;
$productDetailsModalNameFontSize: 1.4rem;
$productDetailsModalNameColor: $brandLightBlue4;
$productDetailsModalNameAndRebateLineHeight: 2rem;
$productDetailsModalPriceFontSize: (small: 1.4rem, medium: 1.4rem);
$productDetailsModalPriceFontWeight: (small: 400, medium: 400);
$productDetailsModalPriceMargin: (small: .4rem 0, medium: 0);
$productDetailsModalRebatePrefixFontWeight: bold;
$productDetailsModalRebateFontSize: (small: 1.6rem, medium: 1.6rem);
$productDetailsModalClickLinkMinWidth: 10.4rem;
$productDetailsModalClickLinkFontSize: 1.6rem;
$productDetailsModalClickLinkPadding: .5rem 0;
$productDetailsModalClickLinkBorderRadius: 0;
$productDetailsModalClickLinkLineHeight: 2.4rem;
$productDetailsModalClickLinkBackground: $brandBlue2;
$productDetailsModalClickLinkTextTransform: none;
$productDetailsModalTableHeadRebTxtWidth: 26%;
$productDetailsModalTableBodyBuyPriceWidth: 17%;
$productDetailsModalTableBodyRebateWrapWidth: 40%;
$productDetailsModalTableBodyAlignContent: center;

/* Hybrid Search Modal */
$hybridSearchModalFormWrapPadding: 0 0 1.3rem 0;
$hybridSearchModalFormWrapBackground: $brandWhite;
$hybridSearchModalSearchInputMargin: 1rem auto 0;
$hybridSearchModalSearchInputBackground: $brandWhite;
$hybridSearchModalSearchInputBorder: 1px solid #ccc;
$hybridSearchModalSearchInputPadding: .4rem 3.5rem .4rem 2rem;
$hybridSearchModalSearchInputColor: $brandHeaderColor;
$hybridSearchModalSearchSubmitIconBeforeHeight: 2.8rem;
$hybridSearchModalSearchSubmitIconBeforeWidth: 2.8rem;
$hybridSearchModalRecentOrSimilarStoresTileFavoriteIconWrapOrder: 0;
$hybridSearchModalRecentOrSimilarStoresTileFavoriteIconWrapMargin: 0 1.3rem 0 0;
$hybridSearchModalRecentOrSimilarStoresTilePremierMerchantRebateTextAlign: right;

/* Hybrid Quick Search */
$hybridQuickSearchBackground: (small: $brandWhite, medium: ());
$hybridQuickSearchRebateValueColor: $brandPrimaryBlue2;
$hybridQuickSearchRebateFontSize: 1.2rem;
$hybridQuickSearchRebateLineHeight: 2.8rem;
$hybridQuickSearchColor: (small: (), medium: ());
$hybridQuickSearchPadding: (small: 0 2.2rem 1.1rem, medium: 0 2.2rem 2.2rem 2.9rem);
$hybridQuickSearchNoResultsMargin: (small: 1rem 0 0, medium: 0 0 1.2rem);
$hybridQuickSearchNoResultsFontSize: (small: 2.5rem);
$hybridQuickSearchNoResultsLineHeight: (small: 3.2rem);
$hybridQuickSearchNoResultsFontWeight: (small: 600);
$hybridQuickSearchNoResultsColor: $brandDarkBlue4;
$hybridQuickSearchItemMatchColor: $brandTextColor;
$hybridQuickSearchItemMatchProductColor: $brandTextColor;
$hybridQuickSearchItemMatchHoverBackground: #e7ecef;
$hybridQuickSearchItemMatchMerchantPadding: (small: .5rem 2rem .5rem 5rem, medium: 0 2.2rem 0 5rem);
$hybridQuickSearchAnonymousItemMatchMerchantPadding: (small: .5rem 2rem .5rem 2rem, medium: 0 2.2rem 0 2rem);
$hybridQuickSearchItemMatchProductPadding: (small: .5rem 2rem, medium: 0 2rem);
$hybridQuickSearchItemLineHeight: 2.8rem;
$hybridQuickSearchItemMargin: -.1rem -2rem;
$hybridQuickSearchWrapLinkColor: $brandBlue7;
$hybridQuickSearchWrapLinkTextDecoration: none;
$hybridQuickSearchWrapLinkHoverTextDecoration: underline;
$hybridQuickSearchWrapLinkTextTransform: capitalize;
$hybridQuickSearchWrapLinkLineHeight: 2rem;
$hybridQuickSearchWrapDividerMargin: 2.2rem 0 2rem;
$hybridQuickSearchResultsMargin: (small: 2.6rem 0 1.2rem, medium: 1.6rem 0 0);
$hybridQuickSearchRecentStoresFavoriteIconWidth: 1.6rem;
$hybridQuickSearchRecentStoresFavoriteIconHeight: 1.6rem;

/* Hybrid Search Form */
$hybridSearchFormFocusPlaceholderColor: $brandHeaderColor;
$hybridSearchFormPlaceholderColor: $brandHeaderColor;
$hybridSearchFormPlaceholderFontWeight: bold;
$hybridSearchFormFocusBackground: $brandLightColor;
$hybridSearchFormInputColor: $brandHeaderColor;
$hybridSearchFormInputFontWeight: bold;
$hybridSearchFormDesktopInputHeight: 3.5rem;
$hybridSearchFormDesktopInputFontSize: 1.6rem;
$hybridSearchFormDesktopInputPadding: .4rem 3.5rem .4rem .8rem;
$hybridSearchFormDesktopInputBorderBottom: 1px solid #e9e9e9;
$hybridSearchFormDesktopDropDownWidth: 42rem;
$hybridSearchFormWidth: 42rem;
$hybridSearchFormPositionLeft: (small: 0, medium: unset, large: 0);
$hybridSearchFormPositionRight: (small: 0, medium: 0, large: unset);
$hybridSearchFormMargin: 0;
$hybridSearchFormFocusPosition: relative;
$hybridSearchFormDesktopDropDownBoxShadow: .5px .5px 7px 0 rgba(0, 0, 0, .2);
$hybridSearchFormDesktopDropDownPositionLeft: -5rem;
$hybridSearchFormDesktopDropDownPositionRight: -11rem;
$hybridSearchFormDesktopDropDownPositionTop: ();
$hybridSearchFormSubmitButtonIconBeforeFontSize: 2.4rem;
$hybridSearchFormSubmitButtonIconBeforePosition: relative;
$hybridSearchFormBoxShadow: (small: (), medium:  .5px .5px 7px 0 rgba(0, 0, 0, .2));

/* Coupon Search Results */
$couponSearchResultsTitleMargin: 0 0 5.4rem;
$couponSearchResultsTitleDisplay: block;
$couponSearchResultsTitleH2FontSize: (small: 2.8rem , medium: 2.5rem);
$couponSearchResultsTitleH2TextAlign: left;
$couponSearchResultsTitleH2FontWeight: bold;
$couponSearchResultsTitleH2Color: $brandDarkBlue4;
$couponSearchResultsTitleH2LineHeight: 3.2rem;
$couponSearchResultsTitleH2Margin: 0 0 .5rem;
$couponSearchResultsTitleViewAllColor: $brandBlack;
$couponSearchResultsTitleViewAllFontSize: 1.4rem;
$couponSearchResultsTitleViewAllLineHeight: 2rem;
$couponSearchResultsTitleViewAllTextTransform: capitalize;
$couponSearchResultsRebatePrefixColor: $brandPrimaryBlue2;
$couponSearchResultsViewButtonFontWeight: 600;
$couponSearchResultsViewButtonLineHeight: 2.4rem;
$couponSearchResultsViewButtonMargin: 6rem 0 3rem;
$couponSearchResultsViewButtonColor: $brandWhite;
$couponSearchResultsViewButtonBackground: $brandBlue2;
$couponSearchResultsViewButtonBorder: none;
$couponSearchResultsContentPadding: (small: 2rem 0 10rem 0, medium: 1rem 0 10rem 0);

$scrFilterDesktopFilterByColor: #585c62;
$scrFilterDesktopFilterByFontSize: 1.6rem;
$scrFilterDesktopFilterByLineHeight: 1.9rem;
$scrFilterDesktopButtonColor: $brandLightBlue4;
$scrFilterDesktopButtonPadding: .6rem 2rem .6rem 1.5rem;
$scrFilterDesktopButtonAfterColor: $brandDarkBlue4;

$scrFilterDesktopDropdownListItemInputAccentColor: $brandDarkBlue4;
$scrFilterDesktopActiveFiltersGap: 1.2rem;
$scrFilterDesktopClearFiltersColor: #585c62;
$scrFilterDesktopClearFiltersFontSize: 1.6rem;
$scrFilterDesktopClearFiltersLineHeight: 1.9rem;
$scrFilterDesktopFilteredMerchantFontSize: 1.4rem;
$scrFilterDesktopFilteredMerchantColor: $brandLightBlue4;
$scrFilterDesktopFilteredMerchantButtonColor: $brandDarkBlue4;
$scrFilterDesktopFilteredMerchantBackground: $brandLightGray7;

$scrFilterMobileTitleFontSize: 1.6rem;
$scrFilterMobileTitleColor: $brandTextColor;
$scrFilterMobileTitleMargin: 0 0 1.5rem;
$scrFilterMobileTitleAfterColor: #486a7b;
$scrFilterMobileDropdownListItemInputAccentColor: $brandBlue2;
$scrFilterMobileMoreOrLessButtonColor: $brandLightBlue4;
$scrFilterMobileMoreOrLessButtonFontSize: 1.4rem;
$scrFilterMobileMoreOrLessButtonFontWeight: 600;
$scrFilterMobileClearFilterButtonColor: $brandLightBlue4;
$scrFilterMobileClearFilterButtonFontSize: 1.4rem;
$scrFilterMobileDropdownFontSize: 1.4rem;
$scrFilterMobileDropdownListItemInputAccentColor: $brandDarkBlue4;
$scrFilterMobileViewResultsButtonFontSize: 1.6rem;
$scrFilterMobileViewResultsButtonFontWeight: 600;
$scrFilterMobileViewResultsButtonBackground: #468606;
$scrFilterMobileViewResultsButtonMinWidth: 18.3rem;
$scrFilterMobileViewResultsButtonColor: $brandWhite;
$scrFilterMobileViewResultsButtonBorder: none;
$scrFilterMobileViewResultsButtonTextTransform: none;

$merchantWithCouponsMargin: (small: 0 0 7rem 0, medium: 0 0 7rem 0);
$merchantWithCouponsCountBackground: $brandLightGray7;
$merchantWithCouponsCountColor: $brandTextColor;
$merchantWithCouponsRebateFontSize: (small: 1.6rem);
$merchantWithCouponsRebateValueFontSize: (small: 1.6rem);
$merchantWithCouponsCouponButtonBorder: none;
$merchantWithCouponsCouponButtonFontSize: 1.6rem;
$merchantWithCouponsCouponButtonBackground: $brandBlue2;
$merchantWithCouponsCouponButtonBorderRadius: 0;
$merchantWithCouponsCouponButtonTextTransform: none;
$merchantWithCouponsCouponButtonPadding: (small: .8rem 1.4rem, medium: .8rem 1.8rem);
$merchantWithCouponsOfferExpiresFontStyle: normal;
$merchantWithCouponsOfferDescriptionFontSize: (small: 1.4rem, medium: 1.4rem);
$merchantWithCouponsOfferDescriptionLineHeight: (small: 2.2rem, medium: 2.2rem);
$merchantWithCouponsOfferDetailsPadding: (small: .4rem 2rem 0 1rem, medium: .4rem 3rem 0 0);
$merchantWithCouponsCouponCodeWrapFontSize: 1.2rem;
$merchantWithCouponsCouponCodeWrapLineHeight: 1.5rem;
$merchantWithCouponsCouponCodeWrapMargin: 1.5rem 0 0;
$merchantWithCouponsCouponCodeLabelColor: $brandDarkGray3;
$merchantWithCouponsCouponCodeFontSize: 1.4rem;
$merchantWithCouponsCouponCodeColor: $brandDarkBlue4;

/* Slide Menu */
$slideMenuTriggerIconHeight: 1.7rem;
$slideMenuTriggerIconWidth: 1.7rem;
$slideMenuTriggerIconMargin: 0 .3rem .2rem 0;
$slideMenuCloseButtonColor: #486a7b;
$slideMenuTriggerIconBackground: ();
$slideMenuTriggerIconDisplay: none;
$slideMenuTriggerIconAppliedDisplay: inline-block;
$slideMenuTriggerIconAppliedAfterBackground: url('~org/assets/icons/circle-check-filter.svg') center no-repeat;
$slideMenuTriggerIconAppliedAfterWidth: 100%;
$slideMenuTriggerIconAppliedAfterHeight: 100%;
$slideMenuTriggerIconAppliedAfterPositionTop: ();

/* Sticky Content */
$stickyContentStuckTopPositionTop: 9rem;

/* Product Search Results */
$productSearchResultsPadding: (small: 0 0 6rem, medium: 0 0 5rem);
$productSearchResultsSectionTitleH2TextAlign: left;
$productSearchResultsSectionTitleH2FontSize: (small: 2.5rem, medium: 2.5rem);
$productSearchResultsSectionTitleH2Color: (small: $brandDarkBlue4);
$productSearchResultsSectionTitleMargin: (small: 0 0 -.4rem, medium: 0 0 -1rem);

$productSearchResultsMobileMenuTriggerPositionTop: 2.5rem;
$productSearchResultsMobileMenuTriggerStickedFontSize: 1.4rem;
$productSearchResultsMobileMenuTriggerStickedColor: $brandTextColor;

$productSearchResultsWrapPadding: (small: 0 0 5rem, medium: 0 0 3.2rem);
$productSearchResultsDetailsBoxShadow: none;

$productSearchResultsSeeMoreButtonWidth: (small: 100%, medium: 67%);
$productSearchResultsSeeMoreButtonColor: $brandWhite;
$productSearchResultsSeeMoreButtonBackground: $brandBlue2;
$productSearchResultsSeeMoreButtonMargin: (small: 0 auto, medium: ());
$productSearchResultsSeeMoreButtonFontSize: 1.6rem;
$productSearchResultsSeeMoreButtonLineHeight: 2.4rem;
$productSearchResultsSeeMoreButtonFontWeight: bold;

$psrMenuMobileTitleColor: $brandTextColor;
$psrMenuMobileTitleLineHeight: 2.8rem;
$psrMenuMobileTitleFontSize: 1.6rem;
$psrMenuMobileTitlePadding: 0 0 1.8rem;
$psrMenuMobileTitleBorderBottom: 1px solid #dfdddb;
$psrMenuMobileFilterBorderBottom: 1px solid #dfdddb;
$psrMenuMobileFilterTitleLineHeight: 2.8rem;
$psrMenuMobileFilterTitleFontSize: 1.6rem;
$psrMenuMobileFilterTitleAfterColor: #486a7b;
$psrMenuMobileFilterPadding: 1rem 0;
$psrMenuMobileFilterDropdownMargin: 1.2rem 0;
$psrMenuMobileFilterDropdownFontSize: 1.4rem;
$psrMenuMobileFilterDropdownListItemInputAccentColor: $brandDarkBlue4;
$psrMenuMobileFilterMoreOrLessButtonPadding: 0 0 1.8rem;
$psrMenuMobileFilterMoreOrLessButtonFontSize: 1.4rem;
$psrMenuMobileFilterMoreOrLessButtonColor: $brandLightBlue4;
$psrMenuMobileClearFilterButtonFontSize: 1.4rem;
$psrMenuMobileClearFilterButtonColor: $brandLightBlue4;
$psrMenuMobileViewResultsButtonBackground: #468606;
$psrMenuMobileViewResultsButtonPadding: 1.4rem 4.5rem 1rem;
$psrMenuMobileViewResultsButtonFontSize: 1.6rem;
$psrMenuMobileViewResultsButtonTextTransform: none;
$psrMenuMobileButtonsWrapMargin: 4.5rem 0 0 0;

$psrMenuDesktopMargin: 4rem 0 .5rem;
$psrMenuDesktopSortLabelColor: $brandDarkGray3;
$psrMenuDesktopSortLabelFontSize: 1.6rem;
$psrMenuDesktopSortLabelLineHeight: 1.9rem;
$psrMenuDesktopSortSelectMinWidth: 15rem;
$psrMenuDesktopSortSelectBorderRadius: 4px;
$psrMenuDesktopSortSelectPadding: .6rem 0 .6rem 1.5rem;
$psrMenuDesktopSortSelectColor: $brandLightBlue4;
$psrMenuDesktopSortSelectBorder: 1px solid #666;
$psrMenuDesktopSortSelectBackground: $brandWhite url('~org/assets/icons/chevron_down.svg') no-repeat 95% 55%;
$psrMenuDesktopSortSelectAppearance: none;
$psrMenuDesktopFiltersFilterByColor: $brandDarkGray3;
$psrMenuDesktopFiltersFilterByFontSize: 1.6rem;
$psrMenuDesktopFiltersFilterByLineHeight: 1.9rem;
$psrMenuDesktopFiltersButtonColor: $brandLightBlue4;
$psrMenuDesktopFiltersButtonBorder: 1px solid #666;
$psrMenuDesktopFiltersButtonBorderRadius: .4rem;
$psrMenuDesktopFiltersButtonAfterColor: $brandDarkBlue4;
$psrMenuDesktopFiltersButtonPadding: .6rem 2rem .6rem 1.5rem;
$psrMenuDesktopFiltersDropdownTitleColor: #666;
$psrMenuDesktopFiltersDropdownListItemInputAccentColor: $brandDarkBlue4;
$psrMenuDesktopFiltersDropdownPadding: 0 1rem 2rem 1.6rem;
$psrMenuDesktopActiveFiltersGap: 1rem 1.5rem;
$psrMenuDesktopActiveFiltersClearFiltersFontSize: 1.4rem;
$psrMenuDesktopActiveFiltersClearFiltersColor: $brandLightBlue4;
$psrMenuDesktopActiveFiltersClearFiltersPadding: 0 .7rem 0 0;
$psrMenuDesktopActiveFilterBackground: $brandLightGray7;
$psrMenuDesktopActiveFilterColor: $brandLightBlue4;
$psrMenuDesktopActiveFilterFontSize: 1.4rem;
$psrMenuDesktopActiveFilterButtonColor: $brandDarkBlue4;
$psrMenuDesktopActiveFilterPadding: .6rem 1rem;
$psrMenuDesktopFiltersFilterWrapPromotionsWidth: 17rem;
$psrMenuDesktopSortOptionColor: $brandTextColor;

/* DNT Mobile Warning */
$dntMobileWarningWrapPadding: .9rem 1.1rem .9rem .9rem;
$dntMobileWarningWrapBorderColor: #bad34e;
$dntMobileWarningIconColor: #bad34e;
$dntMobileWarningIconPositionLeft: -.1rem;
$dntMobileWarningIconWidth: 1.9rem;
$dntMobileWarningIconHeight: 1.9rem;
$dntMobileWarningIconFontSize: 1.9rem;
$dntMobileWarningTextLineHeight: 1.8rem;
$dntMobileWarningIconColor: $brandWhite;
$dntMobileWarningIconBackground: #bad34e;
$dntMobileWarningIconAfterDisplay: block;
$dntMobileWarningIconAfterContent: '?';
$dntMobileWarningIconAfterFontSize: 1.5rem;
$dntMobileWarningIconAfterWidth: 1.9rem;
$dntMobileWarningIconAfterHeight: 1.9rem;
$dntMobileWarningIconAfterFontStyle: normal;
$dntMobileWarningIconAfterPosition: relative;
$dntMobileWarningIconAfterPositionLeft: .5rem;
$dntMobileWarningIconAfterPositionTop: -.2rem;
$dntMobileWarningIconMargin: .7rem 0 0;
$infoBarAccordionOfferRebateFontSize: (xxlarge: 1.4rem);

/* Home page */
$homePageMhpTopWrapBackground: (small: $brandLightGray7, medium: $brandDarkBlue2);
$homePageMhpTopWrapPadding: (small: 2rem 0 0 0, medium: 3rem 0 0 0);

/* Carousel */
$carouselDotBackground: rgba(153, 153, 153, .5);
$carouselDotActiveBackground: rgba(54, 73, 90, .5);
$carouselInvertedDotActiveBackground: #fff;
$carouselDotWidth: 1.2rem;
$carouselDotHeight: 1.2rem;
$carouselInvertedDotBackground: rgba(233, 233, 233, .2);
$carouselCounterNumberFontSize: 1.4rem;
$carouselCounterNumberFontWeight: bold;
$carouselCounterColor: $brandDarkBlue4;
$carouselArrowCollor: #999;
$carouselCounterArrowColor: #486a7b;
$carouselArrowFontSize: 3.5rem;
$carouselInvertedViewportArrowColor: #9a9ea7;
