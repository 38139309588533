@import '~org/styles/variables';

$instoreLogoWrapWidth: 12rem !default;
$instoreLogoWrapHeight: 6rem !default;

.mn_instoreLogoWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $instoreLogoWrapWidth;
  height: $instoreLogoWrapHeight;
}
