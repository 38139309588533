@import '~org/styles/variables';

$navigationListItemFontSize: 1.4rem !default;
$navigationListItemLineHeight: 2.2rem !default;
$navigationListItemLetterSpacing: () !default;
$navigationListItemFontWeight: () !default;
$navigationListItemPosition: relative !default;
$navigationListItemBorderBottom: () !default;
$navigationListItemHoverTextDecoration: () !default;

$navigationListItemBeforeContent: () !default;
$navigationListItemBeforeBackground: () !default;
$navigationListItemBeforePosition: () !default;
$navigationListItemBeforeHeight: () !default;
$navigationListItemBeforeWidth: () !default;
$navigationListItemBeforeTop: () !default;
$navigationListItemBeforeLeft: () !default;

$navigationListItemLinkDisplay: block !default;
$navigationListItemLinkPadding: 2.2rem 2rem !default;
$navigationListItemLinkMargin: () !default;
$navigationListItemLinkColor: () !default;
$navigationListItemLinkTextTransform: () !default;

$navigationListItemFocusedBackground: () !default;

$navigationListDropdownItemPosition: relative !default;
$navigationListDropdownItemPaddingLeft: () !default;

$navigationListDropdownItemTriggerOutline: none !default;
$navigationListDropdownItemTriggerDisplay: block !default;
$navigationListDropdownItemTriggerPadding: 2.2rem 3.5rem 2.2rem 2rem !default;
$navigationListDropdownItemTriggerMargin: () !default;
$navigationListDropdownItemTriggerColor: () !default;
$navigationListDropdownItemTriggerPosition: relative !default;
$navigationListDropdownItemTriggerTextTransform: () !default;

$navigationListDropdownItemTriggerBeforeContent: () !default;
$navigationListDropdownItemTriggerBeforeBackground: () !default;
$navigationListDropdownItemTriggerBeforeWidth: () !default;
$navigationListDropdownItemTriggerBeforeHeight: () !default;
$navigationListDropdownItemTriggerBeforeFontFamily: () !default;
$navigationListDropdownItemTriggerBeforeFontSize: 1.5rem !default;
$navigationListDropdownItemTriggerBeforeFontWeight: () !default;
$navigationListDropdownItemTriggerBeforeColor: () !default;
$navigationListDropdownItemTriggerBeforeLineHeight: 1.8rem !default;
$navigationListDropdownItemTriggerBeforePosition: absolute !default;
$navigationListDropdownItemTriggerBeforePositionLeft: 0 !default;
$navigationListDropdownItemTriggerBeforePositionRight: () !default;
$navigationListDropdownItemTriggerBeforePositionTop: 50% !default;
$navigationListDropdownItemTriggerBeforeMarginTop: -9px !default;
$navigationListDropdownItemTriggerBeforePointerEvents: none !default;
$navigationListDropdownItemTriggerBeforeTransition: transform .3s !default;

$navigationListDropdownItemTriggerFocusedBeforeTransform: rotate(90deg) !default;

$navigationListDropdownItemMenuMargin: 0 -2.4rem 0 -6rem !default;
$navigationListDropdownItemMenuPadding: 1rem 0 !default;
$navigationListDropdownItemMenuListStyle: none !default;
$navigationListDropdownItemMenuTextAlign: left !default;
$navigationListDropdownItemMenuTextTransform: () !default;
$navigationListDropdownItemMenuFontWeight: () !default;
$navigationListDropdownItemMenuLetterSpacing: () !default;
$navigationListDropdownItemMenuBackground: () !default;
$navigationListDropdownItemMenuDisplay: none !default;

$navigationListDropdownItemMenuLinkPadding: 1.3rem 3.5rem 1.3rem 6rem !default;
$navigationListDropdownItemMenuLinkMargin: 0 !default;
$navigationListDropdownItemMenuLinkDisplay: block !default;
$navigationListDropdownItemMenuLinkTextDecoration: none !default;
$navigationListDropdownItemMenuLinkColor: () !default;
$navigationListDropdownItemMenuLinkFontSize: () !default;
$navigationListDropdownItemMenuLinkHoverTextDecoration: () !default;
$navigationListDropdownItemMenuLinkHoverColor: () !default;
$navigationListDropdownItemMenuLinkTextTransform: () !default;

.mn_navigationListItem {
  font-size: $navigationListItemFontSize;
  line-height: $navigationListItemLineHeight;
  letter-spacing: $navigationListItemLetterSpacing;
  font-weight: $navigationListItemFontWeight;
  position: $navigationListItemPosition;
  border-bottom: $navigationListItemBorderBottom;

  &:before {
    content: $navigationListItemBeforeContent;
    background: $navigationListItemBeforeBackground;
    position: $navigationListItemBeforePosition;
    height: $navigationListItemBeforeHeight;
    width: $navigationListItemBeforeWidth;
    top: $navigationListItemBeforeTop;
    left: $navigationListItemBeforeLeft;
  }

  .mn_navItemLabel {
    display: inline-block;
    position: relative;
  }

  &:hover .mn_navItemLabel {
    text-decoration: $navigationListItemHoverTextDecoration;
  }

  & > a {
    display: $navigationListItemLinkDisplay;
    padding: $navigationListItemLinkPadding;
    margin: $navigationListItemLinkMargin;
    color: $navigationListItemLinkColor;
    text-transform: $navigationListItemLinkTextTransform;
  }

  .mn_navDropdownMenu {
    display: none;
  }

  // Show nav dropdown on focus for mobile
  &.mn_focused {
    background: $navigationListItemFocusedBackground;

    .mn_navDropdownMenu {
      display: block;
    }
  }

  &.mn_moreNav {
    display: none;
  }
}

/* Navigation item with dropdown */
.mn_navigationListItem.mn_hasDropdown {
  position: $navigationListDropdownItemPosition;
  padding-left: $navigationListDropdownItemPaddingLeft;

  .mn_navItemDropdownTrigger {
    outline: $navigationListDropdownItemTriggerOutline;
    display: $navigationListDropdownItemTriggerDisplay;
    padding: $navigationListDropdownItemTriggerPadding;
    margin: $navigationListDropdownItemTriggerMargin;
    color: $navigationListDropdownItemTriggerColor;
    position: $navigationListDropdownItemTriggerPosition;
    text-transform: $navigationListDropdownItemTriggerTextTransform;

    &:before {
      content: $navigationListDropdownItemTriggerBeforeContent;
      background: $navigationListDropdownItemTriggerBeforeBackground;
      width: $navigationListDropdownItemTriggerBeforeWidth;
      height: $navigationListDropdownItemTriggerBeforeHeight;
      font-family: $navigationListDropdownItemTriggerBeforeFontFamily;
      font-size: $navigationListDropdownItemTriggerBeforeFontSize;
      font-weight: $navigationListDropdownItemTriggerBeforeFontWeight;
      color: $navigationListDropdownItemTriggerBeforeColor;
      line-height: $navigationListDropdownItemTriggerBeforeLineHeight;
      position: $navigationListDropdownItemTriggerBeforePosition;
      left: $navigationListDropdownItemTriggerBeforePositionLeft;
      right: $navigationListDropdownItemTriggerBeforePositionRight;
      top: $navigationListDropdownItemTriggerBeforePositionTop;
      margin-top: $navigationListDropdownItemTriggerBeforeMarginTop;
      pointer-events: $navigationListDropdownItemTriggerBeforePointerEvents;
      transition: $navigationListDropdownItemTriggerBeforeTransition;
    }
  }

  .mn_navDropdownMenu {
    margin: $navigationListDropdownItemMenuMargin;
    padding: $navigationListDropdownItemMenuPadding;
    list-style: $navigationListDropdownItemMenuListStyle;
    text-align: $navigationListDropdownItemMenuTextAlign;
    text-transform: $navigationListDropdownItemMenuTextTransform;
    font-weight: $navigationListDropdownItemMenuFontWeight;
    letter-spacing: $navigationListDropdownItemMenuLetterSpacing;
    background: $navigationListDropdownItemMenuBackground;
    display: $navigationListDropdownItemMenuDisplay;

    li {
      margin: 0;
      padding: 0;

      a {
        padding: $navigationListDropdownItemMenuLinkPadding;
        margin: $navigationListDropdownItemMenuLinkMargin;
        display: $navigationListDropdownItemMenuLinkDisplay;
        text-decoration: $navigationListDropdownItemMenuLinkTextDecoration;
        font-size: $navigationListDropdownItemMenuLinkFontSize;
        color: $navigationListDropdownItemMenuLinkColor;
        text-transform: $navigationListDropdownItemMenuLinkTextTransform;

        &:hover {
          text-decoration: $navigationListDropdownItemMenuLinkHoverTextDecoration;
          color: $navigationListDropdownItemMenuLinkHoverColor;
        }
      }
    }
  }

  &.mn_focused {
    .mn_navItemDropdownTrigger:before {
      transform: $navigationListDropdownItemTriggerFocusedBeforeTransform;
    }

    .mn_navDropdownMenu {
      display: block;
    }
  }
}
