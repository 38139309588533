@import '~org/styles/variables';
@import '../InstoreModal';

$instoreSignInModalSectionTitleAlignItems: center !default;
$instoreSignInModalSectionTitleMargin: 0 0 2rem 0 !default;

$instoreSignInModalSectionTitleH2FontSize: 2.4rem !default;
$instoreSignInModalSectionTitleH2LineHeight: 2.8rem !default;

$instoreSignInModalButtonPadding: .5rem 1.5rem !default;
$instoreSignInModalButtonMinHeight: 5.6rem !default;
$instoreSignInModalButtonMinWidth: 15.6rem !default;

.mn_instoreModal.mn_signInModal {
  .mn_sectionTitle {
    align-items: $instoreSignInModalSectionTitleAlignItems;
    margin: $instoreSignInModalSectionTitleMargin;

    h2 {
      font-size: $instoreSignInModalSectionTitleH2FontSize;
      line-height: $instoreSignInModalSectionTitleH2LineHeight;
    }
  }

  .mn_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $instoreSignInModalButtonPadding;
    min-height: $instoreSignInModalButtonMinHeight;
    min-width: $instoreSignInModalButtonMinWidth;
  }
}
