@import '~org/styles/style';

$quickSearchResultsBorder: 1px solid !default;
$quickSearchResultsBorderRadius: () !default;
$quickSearchResultsBackground: $brandWhite !default;
$quickSearchResultsBackgroundImage: () !default;
$quickSearchResultsOverflow: hidden !default;
$quickSearchResultsBorderWidth: () !default;
$quickSearchItemsWrapperPadding: () !default;
$quickSearchResultsMaxHeight: () !default;
$quickSearchResultsItemDisplay: flex !default;
$quickSearchFavoriteIconHeight: () !default;
$quickSearchFavoriteIconWidth: () !default;
$quickSearchMatchHighlightColor: $brandPrimaryColor !default;
$quickSearchMatchBorderTop: () !default;
$quickSearchMatchBorderBottom: () !default;
$quickSearchMatchMinWidth: () !default;
$quickSearchMatchHoverBackground: rgba(182, 184, 220, .25) !default;
$quickSearchFooterBackgroundColor: $brandGray !default;
$quickSearchInstoreDividerFontFamily: OpenSans, Helvetica, Arial, sans-serif !default;
$quickSearchInstoreDividerFontWeight: () !default;
$quickSearchInstoreDividerFontSize: () !default;
$quickSearchInstoreDividerColor: () !default;
$quickSearchInstoreDividerMargin: () !default;
$quickSearchInstoreIconUrl: url('./assets/images/instore-icon.svg') !default;
$quickSearchInstoreIconBackgroundSize: contain !default;
$quickSearchInstorePositionTop: calc(50% - 10px) !default;
$quickSearchInstorePositionLeft: () !default;
$quickSearchInstorePositionRight: 0 !default;
$quickSearchInstoreMatchJustifyContent: () !default;
$quickSearchInstoreRebateColor: () !default;
$quickSearchInstoreRebatePadding: () !default;

$quickSearchFooterDisplay: () !default;
$quickSearchFooterPadding: .5rem 5% !default;
$quickSearchFooterMargin: 0 !default;
$quickSearchFooterLineHeight: 1.4 !default;
$quickSearchFooterBackground: #999 !default;
$quickSearchFooterColor: #fff !default;
$quickSearchFooterBorderRadius: 0 0 4px 4px !default;
$quickSearchFooterFontSize: () !default;

$quickSearchMatchBackground: () !default;
$quickSearchMatchColor: #fff !default;
$quickSearchMatchFontSize: 1.5rem !default;
$quickSearchMatchLineHeight: 2.4rem !default;
$quickSearchMatchPadding: (small: .4rem 1rem .6rem 5rem, medium: .4rem 1rem .6rem 5rem) !default;
$quickSearchMatchJustifyContent: () !default;

$quickSearchMatchTextColor: () !default;
$quickSearchMatchTextFontSize: () !default;
$quickSearchMatchTextFontWeight: () !default;
$quickSearchMatchTextLineHeight: () !default;
$quickSearchMatchTextLetterSpacing: () !default;
$quickSearchMatchTextTextTransform: () !default;
$quickSearchMatchTextMargin: () !default;

$quickSearchMatchDashDisplay: () !default;

$quickSearchMerchantRebateDisplay: inline !default;
$quickSearchMerchantRebateColor: () !default;
$quickSearchMerchantRebateFontSize: () !default;
$quickSearchMerchantRebateFontWeight: () !default;
$quickSearchMerchantRebateLineHeight: () !default;
$quickSearchMerchantRebateMargin: () !default;
$quickSearchMerchantRebatePrefixFontWeight: () !default;
$quickSearchMerchantRebateTextTransform: () !default;
$quickSearchMerchantRebateLetterSpacing: () !default;

$quickSearchMerchantRebateValueColor: () !default;
$quickSearchMerchantRebateValueFontWeight: () !default;
$quickSearchMerchantRebateElevationOldValueFontWeight: () !default;
$quickSearchMerchantRebateElevationOldValueColor: () !default;
$quickSearchMerchantRebateElevationOldValueBeforeBorderTop: () !default;
$quickSearchMerchantRebateValueAndSuffixWrapFontWeight: () !default;
$quickSearchPremierRebatePrefixTieredFirstLetterTextTransform: () !default;

$quickSearchMerchantFavoriteIconWidth: 2.2rem !default;
$quickSearchMerchantFavoriteIconHeight: 2.4rem !default;
$quickSearchMerchantFavoriteIconPosition: (small: absolute, medium: absolute) !default;
$quickSearchMerchantFavoriteIconPositionLeft: (small: 1.5rem, medium: 1.5rem) !default;
$quickSearchMerchantFavoriteIconPositionRight: (small: (), medium: ()) !default;
$quickSearchMerchantFavoriteIconPositionTop: (small: .9rem, medium: .6rem) !default;

$quickSearchFooterProductLinkWrapFontSize: () !default;
$quickSearchFooterProductLinkWrapFontWeight: () !default;
$quickSearchFooterProductLinkWrapPadding: () !default;
$quickSearchFooterProductLinkWrapBorderTop: () !default;
$quickSearchFooterProductLinkWrapMargin: () !default;
$quickSearchFooterProductLinkWrapBackground: () !default;
$quickSearchFooterProductLinkColor: () !default;
$quickSearchFooterProductLinkHoverColor: () !default;
$quickSearchFooterProductLinkKeywordsFontWeight: () !default;

$quickSearchContentDisplay: flex !default;
$quickSearchContentJustifyContent: (small: space-between, medium: space-between) !default;
$quickSearchContentFlexDirection: () !default;
$quickSearchOnlineMatchFlexDirection: column !default;
$quickSearchDirectMerchantNamePadding: 0 1rem 0 !default;
$quickSearchDirectMerchantNameColor: (small: #fff, medium: #fff) !default;
$quickSearchDirectMerchantNameFontSize: (small: 1.2rem, medium: 1.4rem) !default;
$quickSearchDirectMerchantNameFontWeight: () !default;
$quickSearchDirectMerchantNameLineHeight: (small: 2.4rem, medium: 1.7rem) !default;

$quickSearchOnlineMatchDeactivatedRebatePrefixDisplay: () !default;

.mn_quickSearch {
  .mn_favoriteIcon {
    width: $quickSearchMerchantFavoriteIconWidth;
    height: $quickSearchMerchantFavoriteIconHeight;
    position: map-get($quickSearchMerchantFavoriteIconPosition, small);
    left: map-get($quickSearchMerchantFavoriteIconPositionLeft, small);
    right: map-get($quickSearchMerchantFavoriteIconPositionRight, small);
    top: map-get($quickSearchMerchantFavoriteIconPositionTop, small);
    z-index: 1;
  }

  .mn_quickSearchResults {
    border-radius: $quickSearchResultsBorderRadius;
    padding: 0;
    background: $quickSearchResultsBackground;
    background-image: $quickSearchResultsBackgroundImage;
    border: $quickSearchResultsBorder;
    overflow: $quickSearchResultsOverflow;
    max-height: map-get($quickSearchResultsMaxHeight, small);
    border-width: $quickSearchResultsBorderWidth;

    .mn_quickSearchItemsWrapper {
      padding: $quickSearchItemsWrapperPadding;
    }

    .mn_quickSearchItem {
      display: $quickSearchResultsItemDisplay;
      position: relative;

      .mn_favoriteIcon {
        z-index: 1;
        width: map-get($quickSearchFavoriteIconWidth, small);
        height: map-get($quickSearchFavoriteIconHeight, small);
      }
    }
  }

  h3 {
    font-family: $quickSearchInstoreDividerFontFamily;
    font-weight: $quickSearchInstoreDividerFontWeight;
    font-size: $quickSearchInstoreDividerFontSize;
    color: $quickSearchInstoreDividerColor;
    margin: $quickSearchInstoreDividerMargin;
  }

  .mn_merchantRebate {
    display: $quickSearchMerchantRebateDisplay;
    color: $quickSearchMerchantRebateColor;
    font-size: $quickSearchMerchantRebateFontSize;
    font-weight: $quickSearchMerchantRebateFontWeight;
    line-height: $quickSearchMerchantRebateLineHeight;
    margin: $quickSearchMerchantRebateMargin;
    text-transform: $quickSearchMerchantRebateTextTransform;
    letter-spacing: $quickSearchMerchantRebateLetterSpacing;

    .mn_rebatePrefix {
      font-weight: $quickSearchMerchantRebatePrefixFontWeight;
    }

    .mn_rebateValueAndSuffixWrap {
      font-weight: $quickSearchMerchantRebateValueAndSuffixWrapFontWeight;
    }

    .mn_rebateValueWithCurrency {
      .mn_rebateValue {
        display: inline-block;
        color: $quickSearchMerchantRebateValueColor;
        font-weight: $quickSearchMerchantRebateValueFontWeight;

        .mn_elevationOldValue {
          font-weight: $quickSearchMerchantRebateElevationOldValueFontWeight;
          color: $quickSearchMerchantRebateElevationOldValueColor;

          &:before {
            border-top: $quickSearchMerchantRebateElevationOldValueBeforeBorderTop;
          }
        }
      }
    }
  }

  .mn_premierMerchantRebate .mn_rebateValue:not(.mn_rebateElevated) {
    .mn_tieredPrefix:first-letter {
      text-transform: $quickSearchPremierRebatePrefixTieredFirstLetterTextTransform;
    }
  }

  .mn_quickSearchMatch {
    position: relative;
    display: flex;
    text-decoration: none;
    background: $quickSearchMatchBackground;
    color: $quickSearchMatchColor;
    font-size: $quickSearchMatchFontSize;
    line-height: $quickSearchMatchLineHeight;
    padding: map-get($quickSearchMatchPadding, small);
    border-top: $quickSearchMatchBorderTop;
    border-bottom: $quickSearchMatchBorderBottom;
    min-width: $quickSearchMatchMinWidth;
    justify-content: $quickSearchMatchJustifyContent;

    &:hover,
    &:focus {
      background: $quickSearchMatchHoverBackground;
    }

    &.mn_instoreMatch:before {
      content: '';
      position: absolute;
      top: $quickSearchInstorePositionTop;
      left: $quickSearchInstorePositionLeft;
      right: $quickSearchInstorePositionRight;
      width: 20px;
      height: 20px;
      background-image: $quickSearchInstoreIconUrl;
      background-size: $quickSearchInstoreIconBackgroundSize;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .mn_instoreMatch {
    justify-content: $quickSearchInstoreMatchJustifyContent;

    .mn_instoreMerchantRebate {
      color: $quickSearchInstoreRebateColor;
      padding: $quickSearchInstoreRebatePadding;
    }
  }

  .mn_matchText {
    color: $quickSearchMatchTextColor;
    font-size: $quickSearchMatchTextFontSize;
    font-weight: $quickSearchMatchTextFontWeight;
    line-height: $quickSearchMatchTextLineHeight;
    letter-spacing: $quickSearchMatchTextLetterSpacing;
    text-transform: $quickSearchMatchTextTextTransform;
    margin: $quickSearchMatchTextMargin;
  }

  .mn_dash {
    display: $quickSearchMatchDashDisplay;
  }

  .mn_matchTextHighlight {
    font-weight: bold;
    color: $quickSearchMatchHighlightColor;
  }

  .mn_quickSearchFooter {
    display: $quickSearchFooterDisplay;
    padding: $quickSearchFooterPadding;
    margin: $quickSearchFooterMargin;
    line-height: $quickSearchFooterLineHeight;
    background: $quickSearchFooterBackground;
    color: $quickSearchFooterColor;
    border-radius: $quickSearchFooterBorderRadius;
    font-size: $quickSearchFooterFontSize;
  }

  .mn_productLinkWrap {
    font-size: $quickSearchFooterProductLinkWrapFontSize;
    font-weight: $quickSearchFooterProductLinkWrapFontWeight;
    padding: $quickSearchFooterProductLinkWrapPadding;
    border-top: $quickSearchFooterProductLinkWrapBorderTop;
    margin: $quickSearchFooterProductLinkWrapMargin;
    background: $quickSearchFooterProductLinkWrapBackground;

    .mn_productLink {
      color: $quickSearchFooterProductLinkColor;

      &:hover {
        color: $quickSearchFooterProductLinkHoverColor;
      }

      .mn_keywords {
        font-weight: $quickSearchFooterProductLinkKeywordsFontWeight;
      }
    }
  }

  .mn_quickSearchContent {
    display: $quickSearchContentDisplay;
    justify-content: map-get($quickSearchContentJustifyContent, small);
    flex-direction: $quickSearchContentFlexDirection;
  }

  .mn_onlineMatch {
    flex-direction: $quickSearchOnlineMatchFlexDirection;

    &.mn_deactivatedMatch {
      .mn_rebatePrefix {
        display: $quickSearchOnlineMatchDeactivatedRebatePrefixDisplay;
      }
    }
  }

  .mn_directMerchantName {
    padding: $quickSearchDirectMerchantNamePadding;
    color: map-get($quickSearchDirectMerchantNameColor, small);
    font-size: map-get($quickSearchDirectMerchantNameFontSize, small);
    font-weight: $quickSearchDirectMerchantNameFontWeight;
    line-height: map-get($quickSearchDirectMerchantNameLineHeight, small);
  }

  @media #{$medium-screen} {
    .mn_quickSearchResults {
      max-height: map-get($quickSearchResultsMaxHeight, medium);

      .mn_quickSearchItem {
        .mn_favoriteIcon {
          position: map-get($quickSearchMerchantFavoriteIconPosition, medium);
          width: map-get($quickSearchFavoriteIconWidth, medium);
          height: map-get($quickSearchFavoriteIconHeight, medium);
          left: map-get($quickSearchMerchantFavoriteIconPositionLeft, medium);
          right: map-get($quickSearchMerchantFavoriteIconPositionRight, medium);
          top: map-get($quickSearchMerchantFavoriteIconPositionTop, medium);
        }
      }
    }

    .mn_quickSearchMatch {
      width: 100%;
      padding: map-get($quickSearchMatchPadding, medium);
    }

    .mn_quickSearchContent {
      justify-content: map-get($quickSearchContentJustifyContent, medium);
    }

    .mn_directMerchantName {
      color: map-get($quickSearchDirectMerchantNameColor, medium);
      font-size: map-get($quickSearchDirectMerchantNameFontSize, medium);
      line-height: map-get($quickSearchDirectMerchantNameLineHeight, medium);
    }
  }
}
