/* stylelint-disable block-closing-brace-newline-after, block-closing-brace-empty-line-before */
/* stylelint-disable at-rule-empty-line-before, at-rule-no-unknown */
@import '~org/styles/variables';
$adBlockWarningMargin: 0 !default;
$adBlockWarningPadding: 1rem 0 !default;
$adBlockWarningBoxShadow: () !default;
$adBlockWarningBorderBottom: () !default;
$adBlockWarningBackground: () !default;
$adBlockWarningBackgroundGradient: none !default;

$adBlockWarningMessageMargin: 0 auto !default;
$adBlockWarningMessageFontSize: 1.6rem !default;
$adBlockWarningMessageColor: $brandTextColor !default;
$adBlockWarningMessageTextAlign: center !default;
$adBlockWarningMessageFontWeight: bold !default;
$adBlockWarningMessageDisplay: flex !default;
$adBlockWarningMessageAlignItems: center !default;
$adBlockWarningMessageJustifyContent: space-between !default;
$adBlockWarningMessageHeight: () !default;
$adBlockWarningMessageWidth: () !default;
$adBlockWarningMessageBackground: #fff !default;
$adBlockWarningMessagePadding: .7rem 1.5rem !default;
$adBlockWarningMessageBorder: () !default;
$adBlockWarningMessageBorderRadius: () !default;
$adBlockWarningMessageBorderLeft: () !default;
$adBlockWarningMessageInnerPadding: 0 2rem !default;
$adBlockWarningMessageInnerFontFamily: () !default;
$adBlockWarningMessageLineHeight: 1.3 !default;
$adBlockWarningMessageInnerLetterSpacing: () !default;
$adBlockWarningMessageInnerMargin: () !default;
$adBlockWarningMessageInnerMaxWidth: () !default;

$adBlockWarningIconUrl: url('~core/assets/images/adblock-logo.png') no-repeat center center !default;
$adBlockWarningIconSize: 5.8rem !default;
$adBlockWarningIconHeight: 5.8rem !default;
$adBlockWarningIconWidth: 5.8rem !default;
$adBlockWarningIconContent: '' !default;
$adBlockWarningIconFontFamily: 'FontAwesome' !default;
$adBlockWarningIconFontWeight: normal !default;
$adBlockWarningIconFontSize: 4rem !default;
$adBlockWarningIconPosition: () !default;
$adBlockWarningIconPositionTop: () !default;
$adBlockWarningIconPositionLeft: () !default;
$adBlockWarningIconLineHeight: () !default;
$adBlockWarningIconBorderRadius: () !default;
$adBlockWarningIconBackgroundColor: () !default;
$adBlockWarningIconColor: () !default;
$adBlockWarningIconMinWidth: () !default;

.mn_adBlockWarning {
  position: relative;
  z-index: 102;
  padding: $adBlockWarningPadding;
  margin: $adBlockWarningMargin;
  box-shadow: $adBlockWarningBoxShadow;
  border-bottom: $adBlockWarningBorderBottom;

  @if ($adBlockWarningBackgroundGradient != 'none') {
    @include vert-gradient($adBlockWarningBackgroundGradient);
  } @else {
    background: $adBlockWarningBackground;
  }

  .mn_adBlockWarningMessage {
    display: $adBlockWarningMessageDisplay;
    align-items: $adBlockWarningMessageAlignItems;
    justify-content: $adBlockWarningMessageJustifyContent;
    margin: $adBlockWarningMessageMargin;
    height: $adBlockWarningMessageHeight;
    color: $adBlockWarningMessageColor;
    text-align: $adBlockWarningMessageTextAlign;
    font-size: $adBlockWarningMessageFontSize;
    line-height: $adBlockWarningMessageLineHeight;
    font-weight: $adBlockWarningMessageFontWeight;
    width: $adBlockWarningMessageWidth;
    background: $adBlockWarningMessageBackground;
    padding: $adBlockWarningMessagePadding;
    border: $adBlockWarningMessageBorder;
    border-radius: $adBlockWarningMessageBorderRadius;
    border-left: $adBlockWarningMessageBorderLeft;
    box-sizing: border-box;

    &:before {
      content: $adBlockWarningIconContent;
      position: $adBlockWarningIconPosition;
      top: $adBlockWarningIconPositionTop;
      left: $adBlockWarningIconPositionLeft;
      color: $adBlockWarningIconColor;

      @if ($adBlockWarningIconContent != '') {
        font-family: $adBlockWarningIconFontFamily;
        font-weight: $adBlockWarningIconFontWeight;
        font-size: $adBlockWarningIconFontSize;
        display: inline-block;
        min-width: $adBlockWarningIconMinWidth;
        line-height: $adBlockWarningIconLineHeight;
        border-radius: $adBlockWarningIconBorderRadius;
        background-color: $adBlockWarningIconBackgroundColor;
        height: $adBlockWarningIconHeight;
        width: $adBlockWarningIconWidth;
      } @else {
        height: $adBlockWarningIconHeight;
        width: $adBlockWarningIconWidth;
        background: $adBlockWarningIconUrl;
        background-size: $adBlockWarningIconSize;
      }
    }

    .mn_adBlockWarningMessageInner {
      padding: $adBlockWarningMessageInnerPadding;
      font-family: $adBlockWarningMessageInnerFontFamily;
      letter-spacing: $adBlockWarningMessageInnerLetterSpacing;
      margin: $adBlockWarningMessageInnerMargin;
      max-width: $adBlockWarningMessageInnerMaxWidth;
    }
  }
}
