@import '~org/styles/variables';

/* Merchant Overlay */
$merchantOverlayColor: (small: $brandTextColor, medium: $brandNeutralDarkColor2) !default;
$merchantOverlayFontSize: (small: 1.8rem , medium: 1.6rem) !default;
$merchantOverlayContentPadding: (small: (), medium: ()) !default;

/* Modal */
$merchantOverlayModalWidth: (small: 35rem, medium: 50rem) !default;
$merchantOverlayModalPadding: (small: 0, medium: ()) !default;
$merchantOverlayModalPositionTop: (small: 10%, medium: 20%) !default;
$merchantOverlayModalBorderTop: (small: (), medium: ()) !default;
$merchantOverlayModalBorderBottom: (small: (), medium: ()) !default;
$merchantOverlayModalMargin: (small: (), medium: ()) !default;
$merchantOverlayModalCloseButtonColor: (small: #fff, medium: initial) !default;
$merchantOverlayModalCloseButtonFontSize: (small: (), medium: ()) !default;
$merchantOverlayModalCloseButtonFontWeight: (small: (), medium: ()) !default;
$merchantOverlayModalCloseButtonTop: (small: (), medium: ()) !default;
$merchantOverlayModalCloseButtonRight: (small: (), medium: ()) !default;
$merchantOverlayModalCloseButtonAfterFontFamily: (small: (), medium: ()) !default;
$merchantOverlayModalCloseButtonAfterContent: (small: (), medium: ()) !default;

$merchantOverlayLightBoxContentPadding: (small: 1.5rem 1.5rem 3rem 1.5rem, medium: ()) !default;

/* Merchant Logo */
$merchantOverlayHeadLogoBackgroundColor: #fff !default;
$merchantOverlayHeadLogoPadding: (small: 2.4rem 1.9rem 0, medium: 2.4rem 1.9rem 0) !default;
$merchantOverlayHeadLogoOffsetTop: (small: -4rem, medium: -3.6rem) !default;
$merchantOverlayHeadLogoOffsetDisplay: (small: (), medium: ()) !default;
$merchantOverlayHeadLogoOffsetPosition: () !default;
$merchantOverlayHeadLogoOffsetRight: () !default;
$merchantOverlayHeadLogoOffsetAfterTop: () !default;
$merchantOverlayHeadLogoOffsetZindex: () !default;
$merchantOverlayHeadLogoOffsetHeight: () !default;
$merchantOverlayHeadLogoOffsetWidth: () !default;
$merchantOverlayHeadLogoOffsetBorderColor: () !default;
$merchantOverlayHeadLogoOffsetBorderStyle: () !default;
$merchantOverlayHeadLogoOffsetBorderWidth: () !default;
$merchantOverlayHeadLogoOffsetBorderRadius: () !default;
$merchantOverlayHeadLogoBorderRadius: (small: 3px, medium: 3px) !default;
$merchantOverlayHeadLogoMinWidth: 15.8rem !default;
$merchantOverlayHeadLogoMinHeight: 8.4rem !default;

/* Favorite Icon */
$merchantOverlayFavoriteIconPositionRight: (small: 0, medium: ()) !default;
$merchantOverlayFavoriteIconPositionTop: (small: .3rem, medium: ()) !default;
$merchantOverlayFavoriteIconWidth: (small: 2.3rem, medium: ()) !default;
$merchantOverlayFavoriteIconHeight: (small: 2.3rem, medium: ()) !default;
$merchantOverlayFavoriteFontIconFontSize: (small: (), medium: ()) !default;

/* Description */
$merchantOverlayDescriptionMargin: (small: 0, medium: 7rem 0 0) !default;
$merchantOverlaySubTitleFontWeight: 700 !default;
$merchantOverlayDescriptionDisplay: (small: (), medium: ()) !default;
$merchantOverlayDescriptionFlexDirection: (small: (), medium: ()) !default;

/* Title */
$merchantOverlayOfferTitleFont: (small: 500 2rem/1.5 $brandFontFamily, medium: 500 3rem/1.5 $brandFontFamily) !default;
$merchantOverlayOfferTitleMargin: (small: 6rem 0 0, medium: 2rem 0 0) !default;
$merchantOverlayOfferTitleColor: () !default;
$merchantOverlayOfferTitleOrder: (small: (), medium: ()) !default;
$merchantOverlayOfferTitleLetterSpacing: (small: (), medium: ()) !default;
$merchantOverlayOfferTitleTextTransform: () !default;

/* Expires */
$merchantOverlayHeadOfferExpiresColor: (small: #666, medium: #666) !default;
$merchantOverlayHeadOfferExpiresMargin: (small: 1.8rem 0 1rem, medium: 1.8rem 0 1rem) !default;
$merchantOverlayHeadOfferExpiresFontWeight: normal !default;
$merchantOverlayHeadOfferExpiresFontSize: (small: 1.2rem, medium: 1.2rem) !default;
$merchantOverlayHeadOfferExpiresOrder: (small: (), medium: ()) !default;
$merchantOverlayHeadOfferExpiresLineHeight: (small: (), medium: ()) !default;

$merchantOverlayHeadOfferSeparatorBorderTop: (small: 1px solid #ccc, medium: 1px solid #ccc) !default;
$merchantOverlayHeadOfferSeparatorMargin: (small: (), medium: ()) !default;
$merchantOverlayHeadOfferSeparatorDisplay: (small: (), medium: ()) !default;

/* Rebate/Elevation */
$merchantOverlayPremierMerchantRebateOrder: (small: (), medium: ()) !default;
$merchantOverlayPremierMerchantRebateMargin: (small: (), medium: ()) !default;
$merchantOverlayHeadRebatePadding: (small: (), medium: ()) !default;
$merchantOverlayHeadRebateBorderBottom: (small: (), medium: ()) !default;
$merchantOverlayHeadRebateBorderTop: (small: (), medium: ()) !default;
$merchantOverlayHeadRebateFontSize: (small: 1.6rem) !default;
$merchantOverlayMerchantRebateLineHeight: () !default;
$merchantOverlayHeadRebateMargin: (small: 2rem 0, medium: ()) !default;
$merchantOverlayHeadRebateFontWeight: (small: normal) !default;
$merchantOverlayRebateOldValueDisplay: (small: (), medium: ()) !default;
$merchantOverlayRebateOldValueColor: () !default;
$merchantOverlayRebateOldValueTextTransform: () !default;
$merchantOverlayRebateOldValueFontSize: (small: (), medium: ()) !default;
$merchantOverlayRebateNewValueFontSize: (small: (), medium: ()) !default;
$merchantOverlayRebateNewValueWhiteSpace: () !default;
$merchantOverlayRebateNewValueColor: () !default;
$merchantOverlayRebateNewValueFontWeight: () !default;
$merchantOverlayRebateNewValueTextTransform: () !default;
$merchantOverlayRebateValueWithCurrencyFontSize: (small: (), medium: ()) !default;
$merchantOverlayRebatePrefixFontSize: (small: (), medium: ()) !default;
$merchantOverlayRebatePrefixColor: (small: (), medium: ()) !default;
$merchantOverlayRebatePrefixFontWeight: () !default;
$merchantOverlayRebatePrefixTextTransform: () !default;
$merchantOverlayRebateValueWithCurrencyFontWeight: () !default;
$merchantOverlayRebateValueWithCurrencyTextTransform: () !default;
$merchantNoRebateColor: () !default;
$merchantOverlayMerchantRebateFontSize: () !default;
$merchantOverlayRebateOldValueAfterContent: () !default;
$merchantOverlayDisclaimerOrder: (small: (), medium: ()) !default;

/* Disclaimer(s) */
$merchantOverlayDisclaimerWidth: 80% !default;
$merchantOverlayDisclaimerMargin: 0 auto 2rem !default;
$merchantOverlayDisclaimerFontSize: (small: (), medium: ()) !default;
$merchantOverlayDisclaimerLineHeight: () !default;
$merchantOverlayDisclaimerColor: (small: (), medium: ()) !default;
$merchantOverlayDisclaimerOrder: (small: (), medium: ()) !default;

/* Shop Now CTA */
$merchantOverlayShopNowFont: (small: 500 1.6rem/1.2 $brandFontFamily, medium: 500 1.6rem/1.2 $brandFontFamily) !default;
$merchantOverlayShopNowBackgroundColor: #6244bb !default;
$merchantOverlayShopNowBorder: 2px solid #745ac4 !default;
$merchantOverlayShopNowPadding: (small: 1.25rem 2rem, medium: 1.25rem 2rem) !default;
$merchantOverlayShopNowMargin: (small: 3rem 0 1rem, medium: 4rem 0) !default;
$merchantOverlayShopNowColor: #fff !default;
$merchantOverlayShopNowBorderRadius: 4px !default;
$merchantOverlayShopNowMinWidth: (small: 100%, medium: 100%) !default;
$merchantOverlayShopNowLetterSpacing: (small: (), medium: ()) !default;
$merchantOverlayShopNowTextTransform: lowercase !default;
$merchantOverlayShopNowHoverBorderColor: () !default;
$merchantOverlayShopNowHoverBackground: () !default;
$merchantOverlayShopNowHoverBoxShadow: () !default;

/* Coupon Code & Copy Button */
$merchantOverlayCouponFontWeight: 500 !default;
$merchantOverlayCouponMargin: 0 0 2rem !default;
$merchantOverlayCouponIntroFontSize: (small: 1.2rem, medium: 1.5rem) !default;
$merchantOverlayCouponIntroFontWeight: normal !default;
$merchantOverlayCouponIntroMargin: (small: 2rem 0 3rem, medium: 2rem 0 3rem) !default;
$merchantOverlayCouponIntroColor: (small: #666, medium: #666) !default;

$merchantOverlayCouponCodeInputBackgroundColor: (small: #fff, medium: #fff) !default;
$merchantOverlayCouponCodeInputPadding: (small: .2rem 1rem, medium: .2rem 0 .2rem 1rem) !default;
$merchantOverlayCouponCodeInputBorder: (small: 1px solid #666, medium: 1px solid #666) !default;
$merchantOverlayCouponCodeInputBorderRadius: (small: 4px, medium: 4px) !default;
$merchantOverlayCouponCodeInputTextTransform: none !default;
$merchantOverlayCouponCodeInputMinWidht: (small: 0, medium: 0) !default;
$merchantOverlayCouponCodeInputMaxWidht: (small: 100%, medium: 100%) !default;
$merchantOverlayCouponCodeInputHeight: (small: 4.2rem, medium: 4.2rem) !default;
$merchantOverlayCouponCodeInputFont: (small: 600 1.2rem/1.9rem $brandFontFamily, medium: 600 1.2rem/1.9rem  $brandFontFamily) !default;
$merchantOverlayCouponCodeInputLetterSpacing: .9px !default;
$merchantOverlayCouponCodeInputColor: (small: #000, medium: #000) !default;
$merchantOverlayCouponCodeCopyBackgroundColor: #fff !default;
$merchantOverlayCouponCodeCopyBorderColor: #6244bb !default;
$merchantOverlayCouponCodeCopyBorder: (small: 2px solid $merchantOverlayCouponCodeCopyBorderColor, medium: 2px solid $merchantOverlayCouponCodeCopyBorderColor) !default;
$merchantOverlayCouponCodeCopyBorderRadius: (small: 4px, medium: 4px) !default;
$merchantOverlayCouponCodeCopyFont: (small: 500 1.6rem/2.4rem $brandFontFamily, medium: 500 1.6rem/2.4rem $brandFontFamily) !default;
$merchantOverlayCouponCodeCopyHeight: $merchantOverlayCouponCodeInputHeight !default;
$merchantOverlayCouponCodeCopyPadding: 0 1rem !default;
$merchantOverlayCouponCodeCopyColor: (small: #6244bb, medium: #6244bb) !default;
$merchantOverlayCouponCodeCopyWidth: (small: 9rem, medium: 9rem) !default;
$merchantOverlayCouponCodeIsCopiedBackground: #f2f2f2 !default;
$merchantOverlayCouponCodeIsCopiedColor: () !default;
$merchantOverlayCouponCodeIsCopiedBoxShadow: () !default;
$merchantOverlayCouponCodeCopyTransition: background .3s ease-in-out !default;
$merchantOverlayCouponCodeMarginLeft: -2px !default;
$merchantOverlayCouponCodeCopyHoverBackground: $merchantOverlayCouponCodeCopyBorderColor !default;
$merchantOverlayCouponCodeCopyHoverColor: #fff !default;
$merchantOverlayCouponControlsJustifyContent: space-between !default;
$merchantOverlayCouponControlsGap: (small: (), medium: ()) !default;
$merchantOverlayCouponControlsLabelWidth: () !default;
$merchantOverlayCouponCodeInputTextAlign: (small: left, medium: left) !default;
$merchantOverlayCouponCodeCopyTextTransform: (small: none, medium: none) !default;
$merchantOverlayCouponCodeCopyHoverBoxShadow: () !default;

/* Footer */
$merchantOverlayFooterLinkFontSize: (small: 1.4rem, medium: 1.6rem) !default;
$merchantOverlayFooterLinkFontWeight: () !default;
$merchantOverlayFooterLinkColor: () !default;
$merchantOverlayFooterLinkTextTransform: () !default;
$merchantOverlayFooterMerchantTermsColor: (small: #006080, medium: #006080) !default;
$merchantOverlayFooterTermsFontSize: (small: 1.2rem, medium: 1.2rem) !default;
$merchantOverlayFooterTermsFontWeight: (small: 500, medium: 500) !default;
$merchantOverlayFooterMerchantTermsMargin: (small: 1rem 0 0, medium: 1rem 0 0) !default;
$merchantOverlayFooterTermsTextDecoration: underline !default;
$merchantOverlayFooterTermsTextTransform: () !default;

/* Lightbox Decoration */
$merchantOverlayDecorationDisplay: (small: (), medium: ()) !default;
$merchantOverlayDecorationHeight: () !default;
$merchantOverlayDecorationBackground: () !default;
$merchantOverlayDecorationPosition: () !default;
$merchantOverlayDecorationAfterContent: () !default;
$merchantOverlayDecorationAfterDisplay: () !default;
$merchantOverlayDecorationAfterWidth: () !default;
$merchantOverlayDecorationAfterHeight: () !default;
$merchantOverlayDecorationAfterBackground: () !default;
$merchantOverlayDecorationAfterPosition: () !default;
$merchantOverlayDecorationAfterPositionBottom: () !default;
$merchantOverlayDecorationBoxShadow: () !default;
$merchantOverlayDecorationBorderWidth: () !default;
$merchantOverlayDecorationMargin: .3rem !default;

/* Extra Rewards Pill */
$merchantOverlayExtraPillWidth: calc(100% - 12px) !default;
$merchantOverlayExtraPillPositionTop: 13.5rem !default;
$merchantOverlayExtraPillLabelFontSize: () !default;
$merchantOverlayExtraPillLabelLetterSpacing: () !default;

/* All Offers */
$merchantOverlayAllOffersTextDecoration: () !default;
$merchantOverlayAllOffersFontWeight: () !default;
$merchantOverlayAllOffersMargin: () !default;
$merchantOverlayAllOffersColor: () !default;
$merchantOverlayArrowLinkDisplay: (small: (), medium: ()) !default;

.mn_merchantOfferModal {
  .mn_modal {
    width: map-get($merchantOverlayModalWidth, small);
    padding: map-get($merchantOverlayModalPadding, small);
    top: map-get($merchantOverlayModalPositionTop, small);
    border-top: map-get($merchantOverlayModalBorderTop, small);
    border-bottom: map-get($merchantOverlayModalBorderBottom, small);
    margin: map-get($merchantOverlayModalMargin, small);

    .mn_closeModal {
      color: map-get($merchantOverlayModalCloseButtonColor, small);
      font-size: map-get($merchantOverlayModalCloseButtonFontSize, small);
      font-weight: map-get($merchantOverlayModalCloseButtonFontWeight, small);
      top: map-get($merchantOverlayModalCloseButtonTop, small);
      right: map-get($merchantOverlayModalCloseButtonRight, small);

      &:after {
        font-family: map-get($merchantOverlayModalCloseButtonAfterFontFamily, small);
        content: map-get($merchantOverlayModalCloseButtonAfterContent, small);
      }
    }
  }

  .mn_lightBoxContent {
    padding: map-get($merchantOverlayLightBoxContentPadding, small);
  }

  .mn_lightboxDecoration {
    display: map_get($merchantOverlayDecorationDisplay, small);
    height: $merchantOverlayDecorationHeight;
    background: $merchantOverlayDecorationBackground;
    position: $merchantOverlayDecorationPosition;
    box-shadow: $merchantOverlayDecorationBoxShadow;
    border-width: $merchantOverlayDecorationBorderWidth;
    margin: $merchantOverlayDecorationMargin;

    &:after {
      content: $merchantOverlayDecorationAfterContent;
      display: $merchantOverlayDecorationAfterDisplay;
      width: $merchantOverlayDecorationAfterWidth;
      height: $merchantOverlayDecorationAfterHeight;
      background: $merchantOverlayDecorationAfterBackground;
      position: $merchantOverlayDecorationAfterPosition;
      bottom: $merchantOverlayDecorationAfterPositionBottom;
    }
  }

  .mn_merchantOfferOverlayContent {
    text-align: center;
    color: map_get($merchantOverlayColor, small);
    font-size: map_get($merchantOverlayFontSize, small);
    padding: map_get($merchantOverlayContentPadding, small);

    .mn_favoriteIcon {
      position: absolute;
      right: map_get($merchantOverlayFavoriteIconPositionRight, small);
      top: map_get($merchantOverlayFavoriteIconPositionTop, small);
      width: map_get($merchantOverlayFavoriteIconWidth, small);
      height: map_get($merchantOverlayFavoriteIconHeight, small);

      .mn_favoriteFontIcon {
        &:before {
          font-size: map_get($merchantOverlayFavoriteFontIconFontSize, small);
        }
      }
    }

    .mn_olayMerchantLogo {
      position: absolute;
      z-index: 1;
      top: map_get($merchantOverlayHeadLogoOffsetTop, small);
      left: 50%;
      transform: translateX(-50%);
      padding: map_get($merchantOverlayHeadLogoPadding, small);
      border-radius: map_get($merchantOverlayHeadLogoBorderRadius, small);
      background-color: $merchantOverlayHeadLogoBackgroundColor;
      min-width: $merchantOverlayHeadLogoMinWidth;
      min-height: $merchantOverlayHeadLogoMinHeight;

      &:after {
        display: map_get($merchantOverlayHeadLogoOffsetDisplay, small);
      }
    }

    .mn_extraRewardsPill {
      width: $merchantOverlayExtraPillWidth;
      top: $merchantOverlayExtraPillPositionTop;

      .mn_pillLabel {
        font-size: $merchantOverlayExtraPillLabelFontSize;
        letter-spacing: $merchantOverlayExtraPillLabelLetterSpacing;
      }
    }

    .mn_olayDisclaimer {
      width: $merchantOverlayDisclaimerWidth;
      margin: $merchantOverlayDisclaimerMargin;
      line-height: $merchantOverlayDisclaimerLineHeight;
      font-size: map_get($merchantOverlayDisclaimerFontSize, small);
      color: map_get($merchantOverlayDisclaimerColor, small);
    }

    .mn_olayDescription {
      margin: map_get($merchantOverlayDescriptionMargin, small);
      display: map_get($merchantOverlayDescriptionDisplay, small);
      flex-direction: map_get($merchantOverlayDescriptionFlexDirection, small);

      .mn_olaySubTitle {
        font-weight: $merchantOverlaySubTitleFontWeight;
      }

      .mn_offerTitle {
        font: map_get($merchantOverlayOfferTitleFont, small);
        margin: map_get($merchantOverlayOfferTitleMargin, small);
        color: map_get($merchantOverlayOfferTitleColor, small);
        order: map_get($merchantOverlayOfferTitleOrder, small);
        letter-spacing: map_get($merchantOverlayOfferTitleLetterSpacing, small);
        text-transform: $merchantOverlayOfferTitleTextTransform;
      }

      .mn_olayDisclaimer {
        order: map_get($merchantOverlayDisclaimerOrder, small);
      }

      .mn_expires {
        display: block;
        margin: map_get($merchantOverlayHeadOfferExpiresMargin, small);
        color: map_get($merchantOverlayHeadOfferExpiresColor, small);
        font-weight: $merchantOverlayHeadOfferExpiresFontWeight;
        font-size: map_get($merchantOverlayHeadOfferExpiresFontSize, small);
        order: map_get($merchantOverlayHeadOfferExpiresOrder, small);
        line-height: map_get($merchantOverlayHeadOfferExpiresLineHeight, small);
      }

      .mn_premierMerchantRebate,
      .mn_merchantNoRebate {
        order: map_get($merchantOverlayPremierMerchantRebateOrder, small);
        font-size: $merchantOverlayMerchantRebateFontSize;
        margin: map_get($merchantOverlayPremierMerchantRebateMargin, small);
      }

      hr {
        border: 0;
        border-top: map_get($merchantOverlayHeadOfferSeparatorBorderTop, small);
        margin: map_get($merchantOverlayHeadOfferSeparatorMargin, small);
        display: map_get($merchantOverlayHeadOfferSeparatorDisplay, small);
      }
    }

    &.mn_offerOverlayContent {
      .mn_blockRebate {
        margin: map_get($merchantOverlayHeadRebateMargin, small);
        padding: map_get($merchantOverlayHeadRebatePadding, small);
        border-bottom: map_get($merchantOverlayHeadRebateBorderBottom, small);
        border-top: map_get($merchantOverlayHeadRebateBorderTop, small);

        .mn_rebatePrefix {
          font-size: map_get($merchantOverlayRebatePrefixFontSize, small);
          color: map_get($merchantOverlayRebatePrefixColor, small);
          font-weight: map_get($merchantOverlayRebatePrefixFontWeight, small);
        }

        .mn_elevationOldValue {
          font-size: map_get($merchantOverlayRebateOldValueFontSize, small);
          display: map_get($merchantOverlayRebateOldValueDisplay, small);
        }
      }

      .mn_inlineRebate,
      .mn_merchantNoRebate {
        margin: map_get($merchantOverlayHeadRebateMargin, small);
        font-weight: map_get($merchantOverlayHeadRebateFontWeight, small);
        font-size: map_get($merchantOverlayHeadRebateFontSize, small);
        padding: map_get($merchantOverlayHeadRebatePadding, small);
        border-bottom: map_get($merchantOverlayHeadRebateBorderBottom, small);
        border-top: map_get($merchantOverlayHeadRebateBorderTop, small);

        .mn_rebatePrefix {
          font-size: map_get($merchantOverlayRebatePrefixFontSize, small);
          color: map_get($merchantOverlayRebatePrefixColor, small);
          font-weight: map_get($merchantOverlayRebatePrefixFontWeight, small);
          text-transform: $merchantOverlayRebatePrefixTextTransform;
        }

        .mn_rebateValueWithCurrency {
          font-size: map_get($merchantOverlayRebateValueWithCurrencyFontSize, small);
          font-weight: $merchantOverlayRebateValueWithCurrencyFontWeight;
          text-transform: $merchantOverlayRebateValueWithCurrencyTextTransform;
          line-height: map_get($merchantOverlayMerchantRebateLineHeight, small);

          .mn_rebateValue {
            .mn_elevationOldValue {
              font-size: map_get($merchantOverlayRebateOldValueFontSize, small);
              display: map_get($merchantOverlayRebateOldValueDisplay, small);
              color: $merchantOverlayRebateOldValueColor;
              text-transform: $merchantOverlayRebateOldValueTextTransform;

              &:after {
                content: $merchantOverlayRebateOldValueAfterContent;
              }
            }

            .mn_elevationNewValue {
              font-size: map_get($merchantOverlayRebateNewValueFontSize, small);
              color: $merchantOverlayRebateNewValueColor;
              font-weight: $merchantOverlayRebateNewValueFontWeight;
              text-transform: $merchantOverlayRebateNewValueTextTransform;
            }
          }
        }
      }

      .mn_merchantNoRebate {
        color: $merchantNoRebateColor;
      }
    }

    .mn_olayCoupon {
      font-weight: $merchantOverlayCouponFontWeight;
      margin: $merchantOverlayCouponMargin;

      p {
        font-size: map_get($merchantOverlayCouponIntroFontSize, small);
        font-weight: $merchantOverlayCouponIntroFontWeight;
        margin: map_get($merchantOverlayCouponIntroMargin, small);
        color: map_get($merchantOverlayCouponIntroColor, small);
      }

      .mn_olayCouponControls {
        display: flex;
        justify-content: $merchantOverlayCouponControlsJustifyContent;
        gap: map_get($merchantOverlayCouponControlsGap, small);

        label {
          font-size: 0;
          display: flex;
          justify-content: space-between;
          width: $merchantOverlayCouponControlsLabelWidth;
        }
      }

      .mn_couponCode {
        flex: 1 1 70%;
        min-width: map_get($merchantOverlayCouponCodeInputMinWidht, small);
        max-width: map_get($merchantOverlayCouponCodeInputMaxWidht, small);
        height: map_get($merchantOverlayCouponCodeInputHeight, small);
        vertical-align: top;
        padding: map_get($merchantOverlayCouponCodeInputPadding, small);
        border: map_get($merchantOverlayCouponCodeInputBorder, small);
        border-radius: map_get($merchantOverlayCouponCodeInputBorderRadius, small);
        background-color: map_get($merchantOverlayCouponCodeInputBackgroundColor, small);
        text-transform: $merchantOverlayCouponCodeInputTextTransform;
        font: map_get($merchantOverlayCouponCodeInputFont, small);
        letter-spacing: $merchantOverlayCouponCodeInputLetterSpacing;
        -webkit-appearance: none;
        color: map_get($merchantOverlayCouponCodeInputColor, small);
        text-align: map_get($merchantOverlayCouponCodeInputTextAlign, small);
      }

      .mn_copyCouponCode {
        flex: 0 0 auto;
        height: map_get($merchantOverlayCouponCodeCopyHeight, small);
        border: map_get($merchantOverlayCouponCodeCopyBorder, small);
        border-radius: map_get($merchantOverlayCouponCodeCopyBorderRadius, small);
        text-decoration: none;
        background: $merchantOverlayCouponCodeCopyBackgroundColor;
        font: map_get($merchantOverlayCouponCodeCopyFont, small);
        padding: $merchantOverlayCouponCodeCopyPadding;
        color: map_get($merchantOverlayCouponCodeCopyColor, small);
        transition: $merchantOverlayCouponCodeCopyTransition;
        width: map_get($merchantOverlayCouponCodeCopyWidth, small);
        margin-left: $merchantOverlayCouponCodeMarginLeft;
        text-transform: map_get($merchantOverlayCouponCodeCopyTextTransform, small);
        cursor: pointer;

        &:hover {
          background: $merchantOverlayCouponCodeCopyHoverBackground;
          color: $merchantOverlayCouponCodeCopyHoverColor;
          box-shadow: $merchantOverlayCouponCodeCopyHoverBoxShadow;
        }
      }

      .mn_codeIsCopied {
        background: $merchantOverlayCouponCodeIsCopiedBackground;
        color: $merchantOverlayCouponCodeIsCopiedColor;
        box-shadow: $merchantOverlayCouponCodeIsCopiedBoxShadow;
      }
    }

    .mn_olayCta {
      margin: map_get($merchantOverlayShopNowMargin, small);

      .mn_olayShopNow {
        display: inline-block;
        font: map_get($merchantOverlayShopNowFont, small);
        color: $merchantOverlayShopNowColor;
        background: $merchantOverlayShopNowBackgroundColor;
        padding: map_get($merchantOverlayShopNowPadding, small);
        border: $merchantOverlayShopNowBorder;
        border-radius: $merchantOverlayShopNowBorderRadius;
        text-decoration: none;
        min-width: map_get($merchantOverlayShopNowMinWidth, small);
        text-transform: $merchantOverlayShopNowTextTransform;
        letter-spacing: map_get($merchantOverlayShopNowLetterSpacing, small);

        &::first-letter {
          text-transform: capitalize;
        }

        &:hover {
          border-color: $merchantOverlayShopNowHoverBorderColor;
          background: $merchantOverlayShopNowHoverBackground;
          box-shadow: $merchantOverlayShopNowHoverBoxShadow;
        }
      }
    }

    .mn_olayFooter {
      margin: 1rem 0 0;

      a {
        font-size: map_get($merchantOverlayFooterLinkFontSize, small);
        font-weight: map_get($merchantOverlayFooterLinkFontWeight, small);
        color: $merchantOverlayFooterLinkColor;
        text-transform: $merchantOverlayFooterLinkTextTransform;
      }

      .mn_olayMerchantTerms {
        margin: map_get($merchantOverlayFooterMerchantTermsMargin, small);
        display: inline-block;
        color: map_get($merchantOverlayFooterMerchantTermsColor, small);
        font-size: map_get($merchantOverlayFooterTermsFontSize, small);
        font-weight: map_get($merchantOverlayFooterTermsFontWeight, small);
        text-decoration: $merchantOverlayFooterTermsTextDecoration;
        text-transform: $merchantOverlayFooterTermsTextTransform;

        &:hover {
          text-decoration: underline;
        }
      }

      .mn_olayAllOffers {
        margin: 0;

        a {
          color: map_get($merchantOverlayAllOffersColor, small);
        }

        .mn_arrowLink:after {
          display: map_get($merchantOverlayArrowLinkDisplay, small);
        }
      }
    }
  }

  @media #{$medium-screen} {
    .mn_modal {
      width: map-get($merchantOverlayModalWidth, medium);
      padding: map-get($merchantOverlayModalPadding, medium);
      top: map-get($merchantOverlayModalPositionTop, medium);
      border-top: map-get($merchantOverlayModalBorderTop, medium);
      border-bottom: map-get($merchantOverlayModalBorderBottom, medium);
      margin: map-get($merchantOverlayModalMargin, medium);

      .mn_closeModal {
        color: map-get($merchantOverlayModalCloseButtonColor, medium);
        font-size: map-get($merchantOverlayModalCloseButtonFontSize, medium);
        font-weight: map-get($merchantOverlayModalCloseButtonFontWeight, medium);
        top: map-get($merchantOverlayModalCloseButtonTop, medium);
        right: map-get($merchantOverlayModalCloseButtonRight, medium);

        &:after {
          font-family: map-get($merchantOverlayModalCloseButtonAfterFontFamily, medium);
          content: map-get($merchantOverlayModalCloseButtonAfterContent, medium);
        }
      }
    }

    .mn_lightBoxContent {
      padding: map-get($merchantOverlayLightBoxContentPadding, medium);
    }

    .mn_lightboxDecoration {
      display: map_get($merchantOverlayDecorationDisplay, medium);
    }

    .mn_merchantOfferOverlayContent {
      padding: map_get($merchantOverlayContentPadding, medium);

      .mn_olayMerchantLogo {
        top: map_get($merchantOverlayHeadLogoOffsetTop, medium);
        padding: map_get($merchantOverlayHeadLogoPadding, medium);
        border-radius: map_get($merchantOverlayHeadLogoBorderRadius, medium);

        &:after {
          content: '';
          display: map_get($merchantOverlayHeadLogoOffsetDisplay, medium);
          position: $merchantOverlayHeadLogoOffsetPosition;
          right: $merchantOverlayHeadLogoOffsetRight;
          top: $merchantOverlayHeadLogoOffsetAfterTop;
          z-index: $merchantOverlayHeadLogoOffsetZindex;
          height: $merchantOverlayHeadLogoOffsetHeight;
          width: $merchantOverlayHeadLogoOffsetWidth;
          border-color: $merchantOverlayHeadLogoOffsetBorderColor;
          border-style: $merchantOverlayHeadLogoOffsetBorderStyle;
          border-width: $merchantOverlayHeadLogoOffsetBorderWidth;
          border-radius: $merchantOverlayHeadLogoOffsetBorderRadius;
        }
      }

      .mn_favoriteIcon {
        z-index: 2;
        right: map_get($merchantOverlayFavoriteIconPositionRight, medium);
        top: map_get($merchantOverlayFavoriteIconPositionTop, medium);
        width: map_get($merchantOverlayFavoriteIconWidth, medium);
        height: map_get($merchantOverlayFavoriteIconHeight, medium);

        .mn_favoriteFontIcon {
          &:before {
            font-size: map_get($merchantOverlayFavoriteFontIconFontSize, medium);
          }
        }
      }

      .mn_olayDisclaimer {
        font-size: map_get($merchantOverlayDisclaimerFontSize, medium);
        color: map_get($merchantOverlayDisclaimerColor, medium);
        order: map_get($merchantOverlayDisclaimerOrder, medium);
      }

      .mn_olayDescription {
        margin: map_get($merchantOverlayDescriptionMargin, medium);
        display: map_get($merchantOverlayDescriptionDisplay, medium);
        flex-direction: map_get($merchantOverlayDescriptionFlexDirection, medium);

        .mn_offerTitle {
          font: map_get($merchantOverlayOfferTitleFont, medium);
          margin: map_get($merchantOverlayOfferTitleMargin, medium);
          letter-spacing: map_get($merchantOverlayOfferTitleLetterSpacing, medium);
          color: map_get($merchantOverlayOfferTitleColor, medium);
          order: map_get($merchantOverlayOfferTitleOrder, medium);
        }

        .mn_expires {
          color: map_get($merchantOverlayHeadOfferExpiresColor, medium);
          font-size: map_get($merchantOverlayHeadOfferExpiresFontSize, medium);
          margin: map_get($merchantOverlayHeadOfferExpiresMargin, medium);
          order: map_get($merchantOverlayHeadOfferExpiresOrder, medium);
          line-height: map_get($merchantOverlayHeadOfferExpiresLineHeight, medium);
        }

        .mn_premierMerchantRebate,
        .mn_merchantNoRebate {
          order: map_get($merchantOverlayPremierMerchantRebateOrder, medium);
          margin: map_get($merchantOverlayPremierMerchantRebateMargin, medium);
        }

        hr {
          border-top: map_get($merchantOverlayHeadOfferSeparatorBorderTop, medium);
          margin: map_get($merchantOverlayHeadOfferSeparatorMargin, medium);
          display: map_get($merchantOverlayHeadOfferSeparatorDisplay, medium);
        }
      }

      &.mn_offerOverlayContent {
        .mn_inlineRebate,
        .mn_merchantNoRebate {
          padding: map_get($merchantOverlayHeadRebatePadding, medium);
          border-bottom: map_get($merchantOverlayHeadRebateBorderBottom, medium);
          border-top: map_get($merchantOverlayHeadRebateBorderTop, medium);
          margin: map-get($merchantOverlayHeadRebateMargin, medium);
          font-size: map_get($merchantOverlayHeadRebateFontSize, medium);
          font-weight: map_get($merchantOverlayHeadRebateFontWeight, medium);

          .mn_rebatePrefix {
            font-size: map_get($merchantOverlayRebatePrefixFontSize, medium);
            color: map_get($merchantOverlayRebatePrefixColor, medium);
            font-weight: map_get($merchantOverlayRebatePrefixFontWeight, medium);
          }

          .mn_rebateValueWithCurrency {
            font-size: map_get($merchantOverlayRebateValueWithCurrencyFontSize, medium);
            font-weight: $merchantOverlayRebateValueWithCurrencyFontWeight;
            line-height: map_get($merchantOverlayMerchantRebateLineHeight, medium);

            .mn_rebateValue {
              .mn_elevationOldValue {
                font-size: map_get($merchantOverlayRebateOldValueFontSize, medium);
                display: map_get($merchantOverlayRebateOldValueDisplay, medium);
              }

              .mn_elevationNewValue {
                font-size: map_get($merchantOverlayRebateNewValueFontSize, medium);
                white-space: $merchantOverlayRebateNewValueWhiteSpace;
              }
            }
          }
        }
      }

      .mn_olayCoupon {
        p {
          font-size: map_get($merchantOverlayCouponIntroFontSize, medium);
          margin: map_get($merchantOverlayCouponIntroMargin, medium);
          color: map_get($merchantOverlayCouponIntroColor, medium);
        }

        .mn_couponCode {
          height: map_get($merchantOverlayCouponCodeInputHeight, medium);
          border-radius: map_get($merchantOverlayCouponCodeInputBorderRadius, medium);
          background-color: map_get($merchantOverlayCouponCodeInputBackgroundColor, medium);
          font: map_get($merchantOverlayCouponCodeInputFont, medium);
          text-align: map_get($merchantOverlayCouponCodeInputTextAlign, medium);
          min-width: map_get($merchantOverlayCouponCodeInputMinWidht, medium);
          max-width: map_get($merchantOverlayCouponCodeInputMaxWidht, medium);
          border: map_get($merchantOverlayCouponCodeInputBorder, medium);
          color: map_get($merchantOverlayCouponCodeInputColor, medium);
          padding: map_get($merchantOverlayCouponCodeInputPadding, medium);
        }

        .mn_copyCouponCode {
          height: map_get($merchantOverlayCouponCodeCopyHeight, medium);
          border: map_get($merchantOverlayCouponCodeCopyBorder, medium);
          border-radius: map_get($merchantOverlayCouponCodeCopyBorderRadius, medium);
          color: map_get($merchantOverlayCouponCodeCopyColor, medium);
          font: map_get($merchantOverlayCouponCodeCopyFont, medium);
          text-transform: map_get($merchantOverlayCouponCodeCopyTextTransform, medium);
          width: map_get($merchantOverlayCouponCodeCopyWidth, medium);
        }

        .mn_codeIsCopied {
          background: $merchantOverlayCouponCodeIsCopiedBackground;
          color: $merchantOverlayCouponCodeIsCopiedColor;
        }
      }

      .mn_olayCta {
        margin: map_get($merchantOverlayShopNowMargin, medium);

        .mn_olayShopNow {
          font: map_get($merchantOverlayShopNowFont, medium);
          min-width: map_get($merchantOverlayShopNowMinWidth, medium);
          letter-spacing: map_get($merchantOverlayShopNowLetterSpacing, medium);
          padding: map_get($merchantOverlayShopNowPadding, medium);
        }
      }

      .mn_olayAllOffers {
        text-decoration: $merchantOverlayAllOffersTextDecoration;
      }

      .mn_olayFooter {
        a {
          font-weight: map_get($merchantOverlayFooterLinkFontWeight, medium);
        }

        .mn_olayMerchantTerms {
          color: map_get($merchantOverlayFooterMerchantTermsColor, medium);
          font-size: map_get($merchantOverlayFooterTermsFontSize, medium);
          margin: map_get($merchantOverlayFooterMerchantTermsMargin, medium);
          font-weight: map_get($merchantOverlayFooterTermsFontWeight, medium);
        }

        .mn_olayAllOffers {
          font-weight: $merchantOverlayAllOffersFontWeight;
          margin: $merchantOverlayAllOffersMargin;

          a {
            color: map_get($merchantOverlayAllOffersColor, medium);
          }

          .mn_arrowLink:after {
            display: map_get($merchantOverlayArrowLinkDisplay, medium);
          }
        }
      }
    }
  }
}
