@import '~org/styles/variables';

$instoreModalModalPositionTop: (small: 0, medium: 12%) !default;
$instoreModalModalMinHeight: (small: 100%, medium: auto) !default;

$instoreModalBasicButtonColor: $brandLinkColor () !default;
$instoreModalBasicButtonFontSize: 1.4rem () !default;
$instoreModalBasicButtonHoverTextDecoration: underline () !default;

.mn_instoreModal {
  .mn_modal {
    top: map-get($instoreModalModalPositionTop, small);
    min-height: map-get($instoreModalModalMinHeight, small);
    animation-name: none;
  }

  .mn_modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media #{$medium-screen} {
    .mn_modal {
      top: map-get($instoreModalModalPositionTop, medium);
      min-height: map-get($instoreModalModalMinHeight, medium);
      animation-name: showLightbox; // default Modal animation name
    }
  }
}
