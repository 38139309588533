@import '~org/styles/variables';

$sectionTitleDisplay: flex !default;
$sectionTitleFlexDirection: (small: column, medium: (), large: (), xlarge: (), xxLarge: ()) !default;
$sectionTitleAlignItems: (small: baseline, medium: (), large: (), xlarge: (), xxLarge: ()) !default;
$sectionTitleJustifyContent: (small: space-between, medium: (), large: (), xlarge: (), xxLarge: ()) !default;
$sectionTitleRowGap: (small: 1rem, medium: (), large: (), xlarge: (), xxLarge: ()) !default;
$sectionTitleMargin: (small: 0 0 3rem 0, medium: (), large: (), xlarge: (), xxLarge: ()) !default;

$sectionTitleH2FontFamily: () !default;
$sectionTitleH2FontSize: (small: (), medium: ()) !default;
$sectionTitleH2Color: (small: (), medium: ()) !default;
$sectionTitleH2FontWeight: (small: (), medium: ()) !default;
$sectionTitleH2LineHeight: (small: (), medium: ()) !default;
$sectionTitleH2LetterSpacing: () !default;
$sectionTitleH2TextAlign: (small: center, medium: (), xxlarge: ()) !default;
$sectionTitleH2MinorTextTransform: () !default;

$sectionTitleLinkDisplay: () !default;
$sectionTitleLinkFontSize: () !default;
$sectionTitleLinkFontWeight: () !default;
$sectionTitleLinkMargin: () !default;
$sectionTitleLinkLetterSpacing: () !default;
$sectionTitleLinkColor: () !default;
$sectionTitleLinkTextTransform: () !default;

$sectionTitleLinkAfterMargin: 0 0 0 .4rem !default;
$sectionTitleLinkAfterContent: () !default;
$sectionTitleLinkAfterFontFamily: () !default;
$sectionTitleLinkAfterFontSize: () !default;
$sectionTitleLinkAfterPositionTop: () !default;
$sectionTitleLinkAfterPositionLeft: () !default;
$sectionTitleLinkAfterColor: () !default;
$sectionTitleLinkAfterFontWeight: () !default;
$sectionTitleLinkHoverTextDecoration: () !default;

.mn_sectionTitle {
  display: $sectionTitleDisplay;
  flex-direction: map-get($sectionTitleFlexDirection, small);
  align-items: map-get($sectionTitleAlignItems, small);
  justify-content: map-get($sectionTitleJustifyContent, small);
  row-gap: map-get($sectionTitleRowGap, small);
  margin: map-get($sectionTitleMargin, small);

  h2 {
    margin: 0;
    padding: 0;
    font-family: $sectionTitleH2FontFamily;
    font-weight: map-get($sectionTitleH2FontWeight, small);
    line-height: map-get($sectionTitleH2LineHeight, small);
    font-size: map-get($sectionTitleH2FontSize, small);
    color: map-get($sectionTitleH2Color, small);
    letter-spacing: map-get($sectionTitleH2LetterSpacing, small);
    text-align: map-get($sectionTitleH2TextAlign, small);

    .mn_minor {
      text-transform: map-get($sectionTitleH2MinorTextTransform, small);
    }
  }

  p {
    margin: 0;
  }

  .mn_viewAllLink {
    display: $sectionTitleLinkDisplay;
    font-size: $sectionTitleLinkFontSize;
    font-weight: $sectionTitleLinkFontWeight;
    margin: $sectionTitleLinkMargin;
    letter-spacing: $sectionTitleLinkLetterSpacing;
    color: $sectionTitleLinkColor;
    text-transform: $sectionTitleLinkTextTransform;

    &:after {
      content: $sectionTitleLinkAfterContent;
      font-family: $sectionTitleLinkAfterFontFamily;
      font-size: $sectionTitleLinkAfterFontSize;
      color: $sectionTitleLinkAfterColor;
      font-weight: $sectionTitleLinkAfterFontWeight;
      display: inline-block;
      position: relative;
      top: $sectionTitleLinkAfterPositionTop;
      left: $sectionTitleLinkAfterPositionLeft;
      margin: $sectionTitleLinkAfterMargin;
    }

    &:hover {
      text-decoration: $sectionTitleLinkHoverTextDecoration;
    }
  }

  @media #{$medium-screen} {
    display: $sectionTitleDisplay;
    flex-direction: map-get($sectionTitleFlexDirection, medium);
    align-items: map-get($sectionTitleAlignItems, medium);
    justify-content: map-get($sectionTitleJustifyContent, medium);
    row-gap: map-get($sectionTitleRowGap, medium);
    margin: map-get($sectionTitleMargin, medium);

    h2 {
      line-height: map-get($sectionTitleH2LineHeight, medium);
      font-size: map-get($sectionTitleH2FontSize, medium);
      color: map-get($sectionTitleH2Color, medium);
      text-align: map-get($sectionTitleH2TextAlign, medium);
      letter-spacing: map-get($sectionTitleH2LetterSpacing, medium);
      font-weight: map-get($sectionTitleH2FontWeight, medium);

      .mn_minor {
        text-transform: map-get($sectionTitleH2MinorTextTransform, medium);
      }
    }
  }

  @media #{$large-screen} {
    display: $sectionTitleDisplay;
    flex-direction: map-get($sectionTitleFlexDirection, large);
    align-items: map-get($sectionTitleAlignItems, large);
    justify-content: map-get($sectionTitleJustifyContent, large);
    row-gap: map-get($sectionTitleRowGap, large);
    margin: map-get($sectionTitleMargin, large);

    h2 {
      text-align: map-get($sectionTitleH2TextAlign, large);
    }
  }

  @media #{$xlarge-screen} {
    display: $sectionTitleDisplay;
    flex-direction: map-get($sectionTitleFlexDirection, xlarge);
    align-items: map-get($sectionTitleAlignItems, xlarge);
    justify-content: map-get($sectionTitleJustifyContent, xlarge);
    row-gap: map-get($sectionTitleRowGap, xlarge);
    margin: map-get($sectionTitleMargin, xlarge);

    h2 {
      text-align: map-get($sectionTitleH2TextAlign, xlarge);
    }
  }

  @media #{$xxlarge-screen} {
    display: $sectionTitleDisplay;
    flex-direction: map-get($sectionTitleFlexDirection, xxlarge);
    align-items: map-get($sectionTitleAlignItems, xxlarge);
    justify-content: map-get($sectionTitleJustifyContent, xxlarge);
    row-gap: map-get($sectionTitleRowGap, xxlarge);
    margin: map-get($sectionTitleMargin, xxlarge);

    h2 {
      text-align: map-get($sectionTitleH2TextAlign, xxlarge);
    }
  }
}
