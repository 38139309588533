@import '~org/styles/variables';

$instoreRebateColor: () !default;
$instoreRebateFontSize: 1.6rem !default;
$instoreRebateLineHeight: 2rem !default;
$instoreRebateMargin: 0 !default;

.mn_instoreRebate {
  color: $instoreRebateColor;
  font-size: $instoreRebateFontSize;
  line-height: $instoreRebateLineHeight;
  margin: $instoreRebateMargin;
}
