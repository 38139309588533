@import '~org/styles/variables';
@import '../InstoreModal';

$instoreManageCardsModalSectionTitleAlignItems: center !default;
$instoreManageCardsModalSectionTitleMargin: 0 0 1rem 0 !default;

$instoreManageCardsModalSectionTitleH2FontSize: 2.4rem !default;
$instoreManageCardsModalSectionTitleH2LineHeight: 2.8rem !default;

$instoreManageCardsModalOfferInfoFontSize: 1.4rem !default;
$instoreManageCardsModalOfferInfoLineHeight: 2rem !default;
$instoreManageCardsModalOfferInfoMargin: 0 0 3rem 0 !default;

$instoreManageCardsModalWarningMargin: 0 0 2rem 0 !default;

$instoreManageCardsModalButtonPadding: .5rem 1.5rem !default;
$instoreManageCardsModalButtonMinHeight: 5.6rem !default;
$instoreManageCardsModalButtonMinWidth: 15.6rem !default;

$instoreManageCardsModalBasicButtonFontSize: 1.4rem !default;
$instoreManageCardsModalBasicButtonLineHeight: 2rem !default;

$instoreManageCardsModalCardsMargin: 0 0 3rem 0 !default;
$instoreManageCardsModalCardsMinHeight: 1rem !default;

$instoreManageCardsModalCardPadding: 2rem 0 !default;
$instoreManageCardsModalCardMinHeight: 7.5rem !default;
$instoreManageCardsModalCardBorderTop: 1px solid rgba(0, 0, 0, .15) !default;

$instoreManageCardsModalCardLastChildBorderBottom: 1px solid rgba(0, 0, 0, .15) !default;

$instoreManageCardsModalCardInfoGap: 2rem !default;
$instoreManageCardsModalCardImageMinWidth: 5rem !default;
$instoreManageCardsModalCardNumberFontWeight: bold !default;

$instoreManageCardsModalConfirmSectionTextAlign: center !default;
$instoreManageCardsModalConfirmSectionMargin: 2rem 0 0 0 !default;

$instoreManageCardsModalConfirmFontSize: 1.4rem !default;
$instoreManageCardsModalConfirmMessageMargin: 0 0 1rem 0 !default;

$instoreManageCardsModalConfirmButtonsMinHeight: 2.5rem !default;
$instoreManageCardsModalConfirmButtonsGap: 3rem !default;

.mn_instoreModal.mn_manageCardsModal {
  .mn_sectionTitle {
    align-items: $instoreManageCardsModalSectionTitleAlignItems;
    margin: $instoreManageCardsModalSectionTitleMargin;

    h2 {
      font-size: $instoreManageCardsModalSectionTitleH2FontSize;
      line-height: $instoreManageCardsModalSectionTitleH2LineHeight;
    }
  }

  .mn_info {
    text-align: center;
    font-size: $instoreManageCardsModalOfferInfoFontSize;
    line-height: $instoreManageCardsModalOfferInfoLineHeight;
    margin: $instoreManageCardsModalOfferInfoMargin;
  }

  .mn_instoreWarning {
    margin: $instoreManageCardsModalWarningMargin;
  }

  .mn_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $instoreManageCardsModalButtonPadding;
    min-height: $instoreManageCardsModalButtonMinHeight;
    min-width: $instoreManageCardsModalButtonMinWidth;
  }

  .mn_basicButton {
    font-size: $instoreManageCardsModalBasicButtonFontSize;
    line-height: $instoreManageCardsModalBasicButtonLineHeight;
  }

  .mn_cards {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    margin: $instoreManageCardsModalCardsMargin;
    min-height: $instoreManageCardsModalCardsMinHeight;
  }

  .mn_manageCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $instoreManageCardsModalCardPadding;
    min-height: $instoreManageCardsModalCardMinHeight;
    border-top: $instoreManageCardsModalCardBorderTop;

    &.mn_removedCardMessage {
      align-items: center;
      font-weight: bold;
    }

    &:last-child {
      border-bottom: $instoreManageCardsModalCardLastChildBorderBottom;
    }
  }

  .mn_cardInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $instoreManageCardsModalCardInfoGap;

    .mn_cardImage {
      min-width: $instoreManageCardsModalCardImageMinWidth;
    }

    .mn_cardNumber {
      font-weight: $instoreManageCardsModalCardNumberFontWeight;
    }
  }

  .mn_confirmSection {
    text-align: $instoreManageCardsModalConfirmSectionTextAlign;
    margin: $instoreManageCardsModalConfirmSectionMargin;

    .mn_confirmMessage {
      font-size: $instoreManageCardsModalConfirmFontSize;
      margin: $instoreManageCardsModalConfirmMessageMargin;
    }

    .mn_confirmButtons {
      min-height: $instoreManageCardsModalConfirmButtonsMinHeight;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $instoreManageCardsModalConfirmButtonsGap;
    }
  }
}
