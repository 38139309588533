@import '~org/styles/variables';

/* Shared styles */
$instoreOfferDetailsSectionTitleAlignItems: center !default;
$instoreOfferDetailsSectionTitleMargin: 0 0 2rem 0 !default;

$instoreOfferDetailsSectionTitleH2FontSize: 2.4rem !default;
$instoreOfferDetailsSectionTitleH2LineHeight: 2.8rem !default;

$instoreOfferDetailsOfferInfoColor: $brandLinkColor !default;
$instoreOfferDetailsOfferInfoFontSize: 1.6rem !default;
$instoreOfferDetailsOfferInfoLineHeight: 2.8rem !default;
$instoreOfferDetailsOfferInfoMargin: 0 0 1.5rem 0 !default;

$instoreOfferDetailsBasicButtonFontSize: 1.4rem !default;
$instoreOfferDetailsBasicButtonLineHeight: 2rem !default;

$instoreOfferDetailsButtonPadding: .5rem 1.5rem !default;
$instoreOfferDetailsButtonMinHeight: 5.6rem !default;
$instoreOfferDetailsButtonMinWidth: 15.6rem !default;

$instoreOfferDetailsInstoreCards: 0 0 2rem 0 !default;

$instoreOfferDetailsLogoWrapWidth: 14rem !default;
$instoreOfferDetailsLogoWrapHeight: 8rem !default;
$instoreOfferDetailsLogoWrapPadding: 1rem !default;
$instoreOfferDetailsLogoWrapMargin: 0 0 2rem 0 !default;
$instoreOfferDetailsLogoWrapBorderRadius: 6px !default;
$instoreOfferDetailsLogoWrapBackground: #fff !default;

$instoreOfferDetailsWarningMargin: 0 0 2rem 0 !default;

$instoreOfferDetailsOnlyPillPosition: absolute !default;
$instoreOfferDetailsOnlyPillPositionTop: (small: -3rem, medium: 0) !default;
$instoreOfferDetailsOnlyPillPositionLeft: -1rem !default;
$instoreOfferDetailsOnlyPillBackground: #00cff0 !default;
$instoreOfferDetailsOnlyPillFontWeight: bold !default;
$instoreOfferDetailsOnlyPillFontSize: 1.2rem !default;
$instoreOfferDetailsOnlyPillLineHeight: 1.6rem !default;
$instoreOfferDetailsOnlyPillMinWidth: 14rem !default;
$instoreOfferDetailsOnlyPillMinHeight: 2rem !default;

$instoreOfferDetailsOnlyPillBeforeContent: '' !default;
$instoreOfferDetailsOnlyPillBeforePosition: absolute !default;
$instoreOfferDetailsOnlyPillBeforePositionLeft: 0 !default;
$instoreOfferDetailsOnlyPillBeforePositionBottom: -1rem !default;
$instoreOfferDetailsOnlyPillBeforeTriangleFill: '%23222' !default;
$instoreOfferDetailsOnlyPillBeforeBackgroundImage: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><path fill="#{$instoreOfferDetailsOnlyPillBeforeTriangleFill}" d="M0 0 L10 0 L10 10 L0 0 Z" /></svg>') !default;
$instoreOfferDetailsOnlyPillBeforeBackgroundSize: cover !default;
$instoreOfferDetailsOnlyPillBeforeWidth: 1rem !default;
$instoreOfferDetailsOnlyPillBeforeHeight: 1rem !default;

/* Views */
// With linked cards view
$instoreOfferDetailsWithLinkedCardsButtonsMargin: 0 !default;

$instoreOfferDetailsWithLinkedCardsButtonsDividerPadding: 0 1rem !default;

// Without linked cards view
$instoreOfferDetailsWithoutLinkedCardsLinkOfferButtonMargin: 0 0 3rem 0 !default;

$instoreOfferDetailsWithoutLinkedCardsLoaderWrapMinHeight: 4.8rem !default;
$instoreOfferDetailsWithoutLinkedCardsLoaderWrapMargin: 0 0 3rem 0 !default;

$instoreOfferDetailsWithoutLinkedCardsUnlinkSuccessMessageMargin: 2rem 0 0 0 !default;

// Anonymous view
$instoreOfferDetailsAnonymousLoginLinkMargin: 3rem 0 !default;

// Without any cards view
$instoreOfferDetailsWithoutAnyCardsAddCardButtonMargin: 3rem 0 1rem 0 !default;

// Easy link view
$instoreOfferDetailsEasyLinkSuccessIconBackground: #00cff0 !default;
$instoreOfferDetailsEasyLinkSuccessIconColor: $brandWhite !default;
$instoreOfferDetailsEasyLinkSuccessIconFontSize: 3rem !default;
$instoreOfferDetailsEasyLinkSuccessIconMargin: 0 0 2rem 0 !default;
$instoreOfferDetailsEasyLinkSuccessIconBorderRadius: 50% !default;
$instoreOfferDetailsEasyLinkSuccessIconWidth: 6.5rem !default;
$instoreOfferDetailsEasyLinkSuccessIconHeight: 6.5rem !default;
$instoreOfferDetailsEasyLinkSuccessIconBeforeFontFamily: 'fontello' !default;
$instoreOfferDetailsEasyLinkSuccessIconBeforeContent: '\e801' !default;

$instoreOfferDetailsEasyLinkOfferAndCardsInfoBackground: #f7f7f7 !default;
$instoreOfferDetailsEasyLinkOfferAndCardsInfoPadding: 2.5rem 2rem !default;
$instoreOfferDetailsEasyLinkOfferAndCardsInfoMargin: 0 0 5rem 0 !default;

$instoreOfferDetailsEasyLinkDaysLeftFontSize: 1.4rem !default;
$instoreOfferDetailsEasyLinkDaysLeftLineHeight: 2rem !default;

$instoreOfferDetailsEasyLinkFaqFontSize: 1.4rem !default;
$instoreOfferDetailsEasyLinkFaqLineHeight: 2rem !default;

// Easy link without any cards view
$instoreOfferDetailsEasyLinkWithoutAnyCardsAddCardIconWidth: 8rem !default;
$instoreOfferDetailsEasyLinkWithoutAnyCardsAddCardIconHeight: 6rem !default;
$instoreOfferDetailsEasyLinkWithoutAnyCardsAddCardIconMargin: 0 0 2rem 0 !default;
$instoreOfferDetailsEasyLinkWithoutAnyCardsAddCardIconBackground: () !default;

$instoreOfferDetailsEasyLinkWithoutAnyCardsFaqMargin: 0 0 5rem 0 !default;
$instoreOfferDetailsEasyLinkWithoutAnyCardsFaqFontSize: 1.4rem !default;
$instoreOfferDetailsEasyLinkWithoutAnyCardsFaqLineHeight: 2rem !default;

.mn_instoreOfferDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  /* Shared styles */
  .mn_sectionTitle {
    align-items: $instoreOfferDetailsSectionTitleAlignItems;
    margin: $instoreOfferDetailsSectionTitleMargin;

    h2 {
      font-size: $instoreOfferDetailsSectionTitleH2FontSize;
      line-height: $instoreOfferDetailsSectionTitleH2LineHeight;
    }
  }

  .mn_offerInfo {
    text-align: center;
    color: $instoreOfferDetailsOfferInfoColor;
    font-size: $instoreOfferDetailsOfferInfoFontSize;
    line-height: $instoreOfferDetailsOfferInfoLineHeight;
    margin: $instoreOfferDetailsOfferInfoMargin;
  }

  .mn_basicButton {
    font-size: $instoreOfferDetailsBasicButtonFontSize;
    line-height: $instoreOfferDetailsBasicButtonLineHeight;
  }

  .mn_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $instoreOfferDetailsButtonPadding;
    min-height: $instoreOfferDetailsButtonMinHeight;
    min-width: $instoreOfferDetailsButtonMinWidth;
  }

  .mn_instoreCards {
    margin: $instoreOfferDetailsInstoreCards;
  }

  .mn_instoreLogoWrap {
    width: $instoreOfferDetailsLogoWrapWidth;
    height: $instoreOfferDetailsLogoWrapHeight;
    padding: $instoreOfferDetailsLogoWrapPadding;
    margin: $instoreOfferDetailsLogoWrapMargin;
    border-radius: $instoreOfferDetailsLogoWrapBorderRadius;
    background: $instoreOfferDetailsLogoWrapBackground;
  }

  .mn_instoreWarning {
    margin: $instoreOfferDetailsWarningMargin;
  }

  .mn_instoreOnlyPill {
    display: flex;
    justify-content: center;
    align-items: center;
    position: $instoreOfferDetailsOnlyPillPosition;
    top: map-get($instoreOfferDetailsOnlyPillPositionTop, small);
    left: $instoreOfferDetailsOnlyPillPositionLeft;
    background: $instoreOfferDetailsOnlyPillBackground;
    font-weight: $instoreOfferDetailsOnlyPillFontWeight;
    font-size: $instoreOfferDetailsOnlyPillFontSize;
    line-height: $instoreOfferDetailsOnlyPillLineHeight;
    min-width: $instoreOfferDetailsOnlyPillMinWidth;
    min-height: $instoreOfferDetailsOnlyPillMinHeight;

    &:before {
      content: $instoreOfferDetailsOnlyPillBeforeContent;
      position: $instoreOfferDetailsOnlyPillBeforePosition;
      left: $instoreOfferDetailsOnlyPillBeforePositionLeft;
      bottom: $instoreOfferDetailsOnlyPillBeforePositionBottom;
      background-image: $instoreOfferDetailsOnlyPillBeforeBackgroundImage;
      background-size: $instoreOfferDetailsOnlyPillBeforeBackgroundSize;
      width: $instoreOfferDetailsOnlyPillBeforeWidth;
      height: $instoreOfferDetailsOnlyPillBeforeHeight;
    }
  }

  /* Views */
  &.mn_withLinkedCardsView {
    .mn_buttons {
      text-align: center;
      margin: $instoreOfferDetailsWithLinkedCardsButtonsMargin;

      .mn_divider {
        padding: $instoreOfferDetailsWithLinkedCardsButtonsDividerPadding;
      }
    }
  }

  &.mn_withoutLinkedCardsView {
    .mn_linkOfferButton {
      margin: $instoreOfferDetailsWithoutLinkedCardsLinkOfferButtonMargin;
    }

    .mn_loaderWrap {
      min-height: $instoreOfferDetailsWithoutLinkedCardsLoaderWrapMinHeight;
      margin: $instoreOfferDetailsWithoutLinkedCardsLoaderWrapMargin;
    }

    .mn_instoreUnlinkSuccessMessage {
      margin: $instoreOfferDetailsWithoutLinkedCardsUnlinkSuccessMessageMargin;
    }
  }

  &.mn_anonymousModal {
    .mn_loginLink {
      margin: $instoreOfferDetailsAnonymousLoginLinkMargin;
    }
  }

  &.mn_withoutAnyCardsView {
    .mn_addCardButton {
      margin: $instoreOfferDetailsWithoutAnyCardsAddCardButtonMargin;
    }
  }

  &.mn_easyLinkView {
    .mn_successIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $instoreOfferDetailsEasyLinkSuccessIconWidth;
      height: $instoreOfferDetailsEasyLinkSuccessIconHeight;
      background: $instoreOfferDetailsEasyLinkSuccessIconBackground;
      border-radius: $instoreOfferDetailsEasyLinkSuccessIconBorderRadius;
      margin: $instoreOfferDetailsEasyLinkSuccessIconMargin;
      color: $instoreOfferDetailsEasyLinkSuccessIconColor;
      font-size: $instoreOfferDetailsEasyLinkSuccessIconFontSize;

      &:before {
        content: $instoreOfferDetailsEasyLinkSuccessIconBeforeContent;
        font-family: $instoreOfferDetailsEasyLinkSuccessIconBeforeFontFamily;
      }
    }

    .mn_offerAndCardsInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 100%;
      background: $instoreOfferDetailsEasyLinkOfferAndCardsInfoBackground;
      padding: $instoreOfferDetailsEasyLinkOfferAndCardsInfoPadding;
      margin: $instoreOfferDetailsEasyLinkOfferAndCardsInfoMargin;
    }

    .mn_daysLeft {
      font-size: $instoreOfferDetailsEasyLinkDaysLeftFontSize;
      line-height: $instoreOfferDetailsEasyLinkDaysLeftLineHeight;
    }

    .mn_easyLinkFaq {
      text-align: center;
      font-size: $instoreOfferDetailsEasyLinkFaqFontSize;
      line-height: $instoreOfferDetailsEasyLinkFaqLineHeight;
    }
  }

  &.mn_easyLinkWithoutAnyCardsView {
    .mn_addCardIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $instoreOfferDetailsEasyLinkWithoutAnyCardsAddCardIconWidth;
      height: $instoreOfferDetailsEasyLinkWithoutAnyCardsAddCardIconHeight;
      margin: $instoreOfferDetailsEasyLinkWithoutAnyCardsAddCardIconMargin;
      background: $instoreOfferDetailsEasyLinkWithoutAnyCardsAddCardIconBackground;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .mn_easyLinkFaq {
      text-align: center;
      margin: $instoreOfferDetailsEasyLinkWithoutAnyCardsFaqMargin;
      font-size: $instoreOfferDetailsEasyLinkWithoutAnyCardsFaqFontSize;
      line-height: $instoreOfferDetailsEasyLinkWithoutAnyCardsFaqLineHeight;
    }
  }

  @media #{$medium-screen} {
    .mn_instoreOnlyPill {
      top: map-get($instoreOfferDetailsOnlyPillPositionTop, medium);
    }
  }
}
