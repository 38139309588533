@import '~org/styles/variables';

$dntMobileWarningWrapBorderColor: () !default;
$dntMobileWarningWrapBorderWidth: .1rem .1rem .1rem .4rem !default;
$dntMobileWarningWrapBorderRadius: () !default;
$dntMobileWarningWrapPadding: .9rem !default;
$dntMobileWarningWrapMargin: 0 -1.2rem .5rem !default;
$dntMobileWarningContentFlexDirection: () !default;
$dntMobileWarningIconFontSize: 1.6rem !default;
$dntMobileWarningIconColor: () !default;
$dntMobileWarningIconBorder: () !default;
$dntMobileWarningIconPositionLeft: () !default;
$dntMobileWarningIconWidth: 2.1rem !default;
$dntMobileWarningIconHeight: 2.1rem !default;
$dntMobileWarningIconBackground: none !default;
$dntMobileWarningIconTransform: () !default;
$dntMobileWarningIconMargin: .5rem 0 0 !default;
$dntMobileWarningIconExclamationFontWeight: bold !default;
$dntMobileWarningTextFontSize: 1.2rem !default;
$dntMobileWarningTextLineHeight: 1.6rem !default;
$dntMobileWarningTextColor: () !default;
$dntMobileWarningTextMargin: 0 0 0 1rem !default;
$dntMobileWarningTextTextAlign: () !default;
$dntMobileWarningWrapOldSearchModalMargin: () !default;
$dntMobileWarningWrapOldSearchModalPadding: 0 !default;
$dntMobileWarningOldSearchModalFavoriteIconPositionTop: () !default;
$dntMobileWarningWrapOldSearchDropdownBackground: () !default;
$dntMobileWarningWrapOldSearchDropdownMargin: () !default;
$dntMobileWarningWrapOldSearchDropdownMatchPadding: () !default;
$dntMobileWarningWrapOldSearchDropdownMatchBorderBottom: () !default;
$dntMobileWarningIconOldSearchDropdownPositionLeft: () !default;
$dntMobileWarningTextOldSearchDropdownMargin: () !default;
$dntMobileWarningContentOldSearchDropdownFlexDirection: () !default;
$dntMobileWarningTextOldSearchDropdownColor: () !default;
$dntMobileWarningUlOldSearchDropdownFirstChildWrapPaddingTop: () !default;
$dntMobileWarningIconAfterDisplay: none !default;
$dntMobileWarningIconAfterContent: '' !default;
$dntMobileWarningIconAfterFontSize: () !default;
$dntMobileWarningIconAfterWidth: () !default;
$dntMobileWarningIconAfterHeight: () !default;
$dntMobileWarningIconAfterFontStyle: () !default;
$dntMobileWarningIconAfterPosition: () !default;
$dntMobileWarningIconAfterPositionLeft: () !default;
$dntMobileWarningIconAfterPositionTop: () !default;

.mn_dntMobileWarningWrap {
  border-style: solid;
  border-color: $dntMobileWarningWrapBorderColor;
  border-width: $dntMobileWarningWrapBorderWidth;
  border-radius: $dntMobileWarningWrapBorderRadius;
  padding: $dntMobileWarningWrapPadding;
  margin: $dntMobileWarningWrapMargin;

  .mn_dntMobileWarningContent {
    display: flex;
    align-items: flex-start;
    flex-direction: $dntMobileWarningContentFlexDirection;

    .mn_dntMobileWarningIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 50%;
      font-size: $dntMobileWarningIconFontSize;
      color: $dntMobileWarningIconColor;
      border: $dntMobileWarningIconBorder;
      width: $dntMobileWarningIconWidth;
      height: $dntMobileWarningIconHeight;
      background: $dntMobileWarningIconBackground;
      transform: $dntMobileWarningIconTransform;
      left: $dntMobileWarningIconPositionLeft;
      margin: $dntMobileWarningIconMargin;

      &:after {
        display: $dntMobileWarningIconAfterDisplay;
        content: $dntMobileWarningIconAfterContent;
        font-size: $dntMobileWarningIconAfterFontSize;
        width: $dntMobileWarningIconAfterWidth;
        height: $dntMobileWarningIconAfterHeight;
        font-style: $dntMobileWarningIconAfterFontStyle;
        position: $dntMobileWarningIconAfterPosition;
        left: $dntMobileWarningIconAfterPositionLeft;
        top: $dntMobileWarningIconAfterPositionTop;
      }

      &.fa-exclamation:before {
        content: '!';
        font-weight: $dntMobileWarningIconExclamationFontWeight;
      }
    }

    .mn_dntMobileWarningTriangleIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 1rem 1.6rem 1rem;
      border-color: transparent transparent #fff transparent;
      position: relative;
      left: -.3rem;
      border-radius: .2rem;
      margin: 1rem 0 0;

      &:before {
        content: '!';
        position: absolute;
        font-size: 1.4rem;
        top: -.2rem;
        font-style: normal;
        color: #0c2340;
      }
    }

    .mn_dntMobileWarningText {
      font-size: $dntMobileWarningTextFontSize;
      line-height: $dntMobileWarningTextLineHeight;
      color: $dntMobileWarningTextColor;
      margin: $dntMobileWarningTextMargin;
      text-align: $dntMobileWarningTextTextAlign;
    }
  }
}

// me page overwritings
.mn_merchantExperience {
  .mn_dntMobileWarningWrap {
    margin: 0 0 1.5rem;

    .mn_dntMobileWarningContent {
      .mn_dntMobileWarningTriangleIcon {
        border-color: transparent transparent #0c2340 transparent;

        &:before {
          color: #fff;
        }
      }
    }
  }
}

// old search modal overwritings
.mn_searchModal .mn_searchForm .mn_quickSearch .mn_quickSearchItemsWrapper {
  .mn_dntMobileWarningWrap {
    padding: $dntMobileWarningWrapOldSearchModalPadding;
    margin: $dntMobileWarningWrapOldSearchModalMargin;

    .mn_quickSearchItem {
      display: block;
      text-align: left;
      margin-left: -.9rem;

      .mn_favoriteIcon {
        top: $dntMobileWarningOldSearchModalFavoriteIconPositionTop;
      }
    }
  }
}

// old search modal anonymous overWritings
.mn_isAnonymous .mn_searchForm {
  .mn_dropDownWrap .mn_quickSearchResults {
    .mn_dntMobileWarningWrap {
      margin: $dntMobileWarningWrapOldSearchDropdownMargin;
      background: $dntMobileWarningWrapOldSearchDropdownBackground;

      .mn_quickSearchMatch {
        padding: $dntMobileWarningWrapOldSearchDropdownMatchPadding;
        border-bottom: $dntMobileWarningWrapOldSearchDropdownMatchBorderBottom;
      }

      .mn_dntMobileWarningContent {
        flex-direction: $dntMobileWarningContentOldSearchDropdownFlexDirection;

        .mn_dntMobileWarningIcon {
          left: $dntMobileWarningIconOldSearchDropdownPositionLeft;
        }

        .mn_dntMobileWarningText {
          margin: $dntMobileWarningTextOldSearchDropdownMargin;
          color: $dntMobileWarningTextOldSearchDropdownColor;
        }
      }
    }
  }

  .mn_quickSearchItemsWrapper:has(> .mn_dntMobileWarningWrap:first-child) {
    padding-top: $dntMobileWarningUlOldSearchDropdownFirstChildWrapPaddingTop;
  }
}
